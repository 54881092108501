import $ from 'jquery';

import Config from './config';

import Lib from 'lib/lib';
import Svg_Icons from 'lib/svg_icons';
import Lang from 'lib/lang';
import Features from 'lib/features';
import Scripts from 'lib/scripts';

import Modal from 'component/modal';
import User_Identification from 'component/user_identification';
import Session_Recorder from 'component/session_recorder';
import Video_Capture from 'component/video_capture';
import Screenshot_Capture from 'component/screenshot_capture';
import Console_Recorder from 'component/console_recorder';
import Event_Recorder from 'component/event_recorder';
import rage from 'component/rage';

import Survey from './survey/index.js';

import styles from '../../scss/feedback.scss';

(function() {
    if (typeof window.Userback === 'undefined') {
        window.Userback = {};
    }

    // feedback widget
    var Feedback = {
        http_header: [],

        controls_step: 0,

        hcaptcha_token: false,

        extension_domain_match : true,

        is_loaded: false,
        is_live_checked: false,
        is_live_checked_pending: false,
        // Set to true when Intercom binding has been setup
        is_intercom_loaded: false,
        // Set to true when we can confirm the intercom sheet has been loaded
        is_intercom_active: false,
        is_auto_screenshot: false,

        // If true, forces the listening of intercom even if we can't find it
        use_intercom: false,
        form_type: 'general',

        session_data: {},

        configs: {
            widget_version: 1, // 1: old 2: next gen
            widget_id: 0,
            categories: [],
            assignee: [],
            is_live: true,
            native_screenshot: false,
            ai_feedback_type: false,
            portal_url:                    '',
            language:                      'en',
            display_feedback:              false,
            display_powered_by:            true,
            trigger_type:                  'page_load', // page_load, api, widget_url, url_match
            page_load_delay:               0,
            device_type:                   'desktop,tablet,phone',
            url_match_type:                '', // starts, ends, contains, matches, not, regex
            url_match_val:                 '',
            user_segment:                  [],
            style:                         'text', // text, circle
            icon:                          0, // 0, 1, 2, 3
            position:                      'se',
            portal_announcement_date:      0,
            display_tutorial:              0, // 0, 1, 2
            use_modal:                     false,
            feedback:                      [],
            main_button_text:              'FEEDBACK',
            main_button_text_colour:       '#FFFFFF',
            main_button_background_colour: '#2878F0',
            launcher_offset_x:             20,
            launcher_offset_y:             20,
            trigger_code:                  '',
            workflow_type:                 'bug,feature_request,general', // capture, video, general, bug, feature_request
            feedback_type:                 'bug,feature_request,general',
            logo:                          '',
            header_text:                   '',
            routing_item_order:            'bug,feature_request,general',
            text_screenshot:               '',
            text_screenshot_help:          '',
            text_video:                    '',
            text_video_help:               '',
            text_bug:                      '',
            text_bug_help:                 '',
            text_feature_request:          '',
            text_feature_request_help:     '',
            text_general:                  '',
            text_general_help:             '',
            text_view_other:               '',
            text_view_other_help:          '',
            text_feedback:                 '',
            text_portal:                   '',
            text_roadmap:                  '',
            text_announcement:             '',
            help_link:                     '',
            help_title:                    '',
            help_message:                  '',
            direct_to_bug:                 'form', // form, screenshot, video
            direct_to_feature_request:     'form', // form, screenshot, video
            direct_to_general:             'form', // form, screenshot, video
            portal_target:                 'redirect', // redirect, window, widget
            roadmap_target:                'redirect', // redirect, window, widget
            announcement_target:           'redirect', // redirect, window, widget
            help_target:                   'redirect', // redirect, window
            video_time:                    180,
            attachment_size:               5,
            auto_screenshot:               false,
            capture_event:                 false,
            capture_console_log:           false,
            capture_console_error:         false,
            capture_console_warn:          false,
            capture_console_info:          false,
            capture_console_debug:         false,
            session_recording:             false,
            hcaptcha_sitekey:              '',
            form_settings: {
            },
            recording_rules: {
                block      : ['.userback-block'],
                ignore     : ['.userback-ignore'],
                mask       : ['input[type="email"]', 'input[type="password"]'],
                console    : ['log', 'error', 'warn', 'info', 'debug'],
                screenshot : []
            },
            recording_auto_start: false
        },

        form_settings_default: {
            bug: {
                field_order                   : 'email,name,title,comment,category,priority,assignee,custom_field_1',
                allow_session_recording       : false,
                allow_screenshot              : true,
                allow_video                   : false,
                allow_attachment              : false,
                rating_type                   : '',
                rating_help_message           : '',
                rating_mandatory              : false,
                send_button_text              : '',
                name_field                    : false,
                name_field_mandatory          : false,
                name_field_placeholder        : '',
                email_field                   : true,
                email_field_mandatory         : true,
                email_field_placeholder       : '',
                title_field                   : false,
                title_field_mandatory         : false,
                title_field_placeholder       : '',
                comment_field                 : true,
                comment_field_mandatory       : false,
                comment_field_placeholder     : '',
                display_category              : false,
                display_priority              : false,
                display_assignee              : false,
                category_field_mandatory      : false,
                priority_field_mandatory      : false,
                assignee_field_mandatory      : false,
                category_field_placeholder    : '',
                priority_field_placeholder    : '',
                assignee_field_placeholder    : '',
                custom_field_1_type           : '',
                custom_field_1_label          : '',
                custom_field_1_mandatory      : false,
                outro_icon                    : 1,
                outro_headline                : 'Thank you!',
                outro_paragraph               : 'We really appreciate your feedback.'
            },
            feature_request: {
                field_order                   : 'email,name,title,comment,category,priority,assignee,custom_field_1',
                allow_session_recording       : false,
                allow_screenshot              : true,
                allow_video                   : false,
                allow_attachment              : false,
                rating_type                   : '',
                rating_help_message           : '',
                rating_mandatory              : false,
                send_button_text              : '',
                name_field                    : false,
                name_field_mandatory          : false,
                name_field_placeholder        : '',
                email_field                   : true,
                email_field_mandatory         : true,
                email_field_placeholder       : '',
                title_field                   : false,
                title_field_mandatory         : false,
                title_field_placeholder       : '',
                comment_field                 : true,
                comment_field_mandatory       : false,
                comment_field_placeholder     : '',
                display_category              : false,
                display_priority              : false,
                display_assignee              : false,
                category_field_mandatory      : false,
                priority_field_mandatory      : false,
                assignee_field_mandatory      : false,
                category_field_placeholder    : '',
                priority_field_placeholder    : '',
                assignee_field_placeholder    : '',
                custom_field_1_type           : '',
                custom_field_1_label          : '',
                custom_field_1_mandatory      : false,
                outro_icon                    : 1,
                outro_headline                : 'Thank you!',
                outro_paragraph               : 'We really appreciate your feedback.'
            },
            general: {
                field_order                   : 'email,name,title,comment,category,priority,assignee,custom_field_1',
                allow_session_recording       : false,
                allow_screenshot              : true,
                allow_video                   : false,
                allow_attachment              : false,
                rating_type                   : '',
                rating_help_message           : '',
                rating_mandatory              : false,
                send_button_text              : '',
                name_field                    : false,
                name_field_mandatory          : false,
                name_field_placeholder        : '',
                email_field                   : true,
                email_field_mandatory         : true,
                email_field_placeholder       : '',
                title_field                   : false,
                title_field_mandatory         : false,
                title_field_placeholder       : '',
                comment_field                 : true,
                comment_field_mandatory       : false,
                comment_field_placeholder     : '',
                display_category              : false,
                display_priority              : false,
                display_assignee              : false,
                category_field_mandatory      : false,
                priority_field_mandatory      : false,
                assignee_field_mandatory      : false,
                category_field_placeholder    : '',
                priority_field_placeholder    : '',
                assignee_field_placeholder    : '',
                custom_field_1_type           : '',
                custom_field_1_label          : '',
                custom_field_1_mandatory      : false,
                outro_icon                    : 1,
                outro_headline                : 'Thank you!',
                outro_paragraph               : 'We really appreciate your feedback.'
            }
        },

        // overwrite configs
        widget_settings: {
        },

        ui: {
            element:       null,
            button:        null,
            button_arrow:  null,
            overlay:       null,
            controls:      null,
            feedback_view: null
        },

        overlay_hint_tooltip_added: false,

        mime_type: 'image/jpeg',
        compression_rate: 1,

        has_touch: (function() {
            return 'ontouchstart' in window;
        })(),

        email: '', // default email
        name: '',
        categories: '',
        priority: null,
        assignee: 0,
        custom_data: {}, // additional data sent along with feedback form

        // api callbacks
        before_send: null,
        after_send: null,
        on_open: null,
        on_close: null,
        on_load: null,
        on_load_error: null,
        // @NOTE: Replaces showing form controls with callback function call
        on_toolbar_submit: null,
        on_toolbar_close: null,

        screenshot_data_uri: [],
        screenshot_ratio: 'landscape',
        screenshot_limit: 5,

        attachment_data_uri: '',
        attachment_file_name: '',

        video_blob: null,
        video_url: '',
        video_annotation: [],

        local_styles: [],

        form_data: {
            rating        : '',
            name          : '',
            email         : '',
            title         : '',
            description   : '',
            category      : '',
            priority      : '',
            assignee      : '',
            custom_field_1: ''
        },

        page_scroll_top: undefined,

        comments: [],

        init: function(widget_configs, extra_options) {
            if (Userback.is_live === true || Userback.is_live === false) {
                this.configs.is_live = Userback.is_live;
                this.configs.is_live_checked = true;
            } else if (extra_options.is_live === true || extra_options.is_live === false) {
                this.configs.is_live = extra_options.is_live;
                this.configs.is_live_checked = true;
            }

            const is_native_screenshot_supported = this.isNativeScreenshotSupported();

            if (Userback.native_screenshot === true && is_native_screenshot_supported) {
                this.configs.native_screenshot = true;
            }

            // TODO: is this still used?
            if (Userback.recording_auto_start !== undefined) {
                this.configs.recording_auto_start = !!Userback.recording_auto_start;
            }

            if (Userback.session_data !== undefined) {
                this.session_data = Userback.session_data;
            }

            // on load error doesn't need API feature
            if (Userback.on_load_error !== undefined) {
                this.on_load_error = Userback.on_load_error;
            }

            this.configs.form_settings = this.form_settings_default;

            this.loadConfigSuccess(widget_configs);
        },

        destroy: function() {
            clearTimeout(Feedback.init_timeout);

            Feedback.reset();

            // remove the widget button
            if (Feedback.ui && Feedback.ui.element) {
                Feedback.ui.element.remove();
            }

            // Ensure intercom observer is removed
            if (typeof Feedback.IntercomObserver !== 'undefined') {
                Feedback.IntercomObserver.disconnect();
                delete Feedback.IntercomObserver;
            }
        },

        isLauncherOpen: function() {
            if (this.ui && this.ui.element && this.ui.element.is(':visible')) {
                return true;
            } else {
                return false;
            }
        },

        checkLive: function() {
            if (this.configs.is_live_checked) {
                return;
            }

            if (this.configs.is_live_checked_pending) {
                return;
            }

            if (Config.load_type !== 'web' || this.configs.native_screenshot) {
                this.configs.is_live = true;
                this.configs.is_live_checked = true;
                return;
            }

            this.configs.is_live_checked_pending = true;

            $.ajax(Config.request_url + '/?checkLive', {
                data: {
                    action: 'widget/checkLive',
                    widget_id: this.configs.widget_id,
                    access_token: Config.access_token,
                    page: window.location.href
                },
                crossDomain: true,
                dataType: 'json',
                type: 'POST',
                context: this,
                success: function(response) {
                    if (typeof response.is_live !== 'undefined') {
                        this.configs.is_live = !!response.is_live;
                        this.configs.is_live_checked = true;

                        if (this.check_live_callback) {
                            this.check_live_callback();
                            this.check_live_callback = null;
                        }

                        if (!this.configs.is_live) {
                            Scripts.lazyLoad([Scripts.html2canvas]);
                        }
                    }
                },
                complete: function() {
                    this.configs.is_live_checked_pending = false;
                }
            });
        },

        loadConfigSuccess: function(widgets) {
            if ($('#userback_button_container[loadtype="' + Config.load_type  + '"]').length) {
                return;
            }

            var matched_widget;
            var matched_widgets = [];

            // find the best matching widget
            if (Array.isArray(widgets)) {
                widgets.forEach((widget_configs) => {
                    if (this.isTargetingMatched(widget_configs)) {
                        var matching_weight = 0;

                        if (widget_configs.user_segment && widget_configs.user_segment.segment_condition) {
                            matching_weight += 10;
                        }

                        if (widget_configs.trigger_type === 'url_match') {
                            matching_weight += 20;
                        }

                        matched_widgets.push({
                            widget: widget_configs,
                            matching_weight: matching_weight
                        });
                    }
                });

                if (matched_widgets.length === 1) {
                    matched_widget = matched_widgets[0].widget;
                } else if (matched_widgets.length > 1) {
                    // find the most specific widget
                    matched_widgets.sort((a, b) => {
                        if (a.matching_weight !== b.matching_weight) {
                            return b.matching_weight - a.matching_weight;
                        }

                        return b.id - a.id;
                    });

                    matched_widget = matched_widgets[0].widget;
                }
            } else {
                // Old backend: to remove after code launch
                matched_widget = widgets;
            }

            if (!matched_widget && this.on_load_error) {
                this.on_load_error();
                return;
            }

            if (!matched_widget && (Config.load_type === 'chrome_extension' || Config.load_type === 'firefox_extension' || Config.load_type === 'edge_extension')) {
                this.extension_domain_match = false;
                this.addCSS(function() {
                });

                return;
            }

            if (!matched_widget) {
                return;
            }

            if (matched_widget.is_expired === true) {
                console.warn('Your Userback account is expired.');
                return;
            }

            if (Features.javascript_api === true) {
                this.setAPIHooks();

                this.configs = Lib.deepExtend(this.configs, matched_widget);
                this.configs = Lib.deepExtend(this.configs, this.widget_settings);
            } else {
                this.configs = Lib.deepExtend(this.configs, matched_widget);
            }

            if (this.configs.workflow_type === 'bug' ||
                this.configs.workflow_type === 'feature_request' ||
                this.configs.workflow_type === 'general') {

                this.form_type = this.configs.workflow_type;
            }

            if (this.configs.trigger_code) {
                var ubwc = Lib.getQueryString('ubwc');

                if (!ubwc) {
                    ubwc = Lib.storage.get('ubwc');
                }

                if (ubwc && ubwc === this.configs.trigger_code) {
                    this.configs.trigger_type = 'page_load';
                    Lib.storage.set('ubwc', ubwc);
                }
            }

            if (!this.configs.capture_event) {
                Event_Recorder.stop();
            }

            if (!this.configs.capture_console_log) {
                Console_Recorder.stop('log');
            }

            if (!this.configs.capture_console_error) {
                Console_Recorder.stop('error');
            }

            if (!this.configs.capture_console_warn) {
                Console_Recorder.stop('warn');
            }

            if (!this.configs.capture_console_info) {
                Console_Recorder.stop('info');
            }

            if (!this.configs.capture_console_debug) {
                Console_Recorder.stop('debug');
            }

            Lang.setLang(this.configs.language);

            if (this.hasSessionRecording() && this.isTargetingMatched()) {
                // old session replay is only needed for the feedback forms
                // so only start it when the feedback button is added
                this.startSessionReplay();
            }

            // add feedback button
            if (this.isTargetingMatched()) {
                this.addCSS(() => {
                    this.init_timeout = setTimeout(() => {
                        this.addButton();
                        this.is_loaded = true;

                        if (this.on_load) {
                            this.on_load.call(Userback_Api);
                        }
                    }, this.configs.page_load_delay * 1000);
                });
            } else {
                // console and event recorders start on page load
                // so we should stop them after config load
                Event_Recorder.stop();
                Console_Recorder.stop();
            }

            if (this.configs.hcaptcha_sitekey) {
                import('@userback/vanilla-hcaptcha/dist/index.esm.js').then((hcap) => {
                    const { VanillaHCaptchaWebComponent } = hcap;
                    if (typeof customElements.get('ub-captcha') === 'undefined'){
                        customElements.define('ub-captcha', VanillaHCaptchaWebComponent);
                    }
                });
            }

            // Attempt to setup the Intercom integration immediately
            this.setupIntercom();

            // If intercom is loaded or the Obserber is already active we don't need to bind/rebind the observer
            if (Feedback.is_intercom_loaded === true || typeof Feedback.IntercomObserver !== 'undefined') {
                return;
            }

            // Intercom isn't loaded so we watch for the Intercom iframe loading after Userback
            Feedback.IntercomObserver = new MutationObserver((muts) => {
                const intercom_muts = muts.filter(mut => [...mut.addedNodes]
                    .filter(node => node.id === 'intercom-frame').length > 0);
                if (intercom_muts.length > 0) {
                    Feedback.setupIntercom();
                }
            });

            Feedback.IntercomObserver.observe(document.body, {childList: true});
        },

        setupIntercom: function() {
            // We don't want to load twice, otherwise we should if we can find intercom or use_intercom is true
            if (
                Feedback.is_intercom_loaded ||
                Feedback.use_intercom === false &&
                ( typeof window?.intercomSettings === 'undefined' && typeof window?.Intercom === 'undefined' )
            ) {
                return;
            }
            Feedback.is_intercom_loaded = true;

            Feedback.on_toolbar_close = function IntercomCloseHandler(){
                if (!Feedback.is_intercom_active) {
                    return true;
                }
                Feedback.hideControls();
                Intercom('show');
                return false;
            }

            // Setup on submit handling
            Feedback.on_toolbar_submit = function IntercomSubmitHandler(data) {
                if (!Feedback.is_intercom_active) {
                    return true;
                }
                Intercom('show');
                setTimeout(() => {
                    get_sheet_window().postMessage({type: 'userback', action: 'attachment', payload: data}, '*');
                }, 200);
                return false;
            };

            // Handle Intercom Sheet Events
            const UserbackIntercomListener = (msg) => {
                if (typeof msg.data !== 'object' || msg.data.type !== 'userback') {
                    return;
                }

                const {action, payload} = msg.data;

                switch (action) {
                    case 'request_init':
                        Feedback.is_intercom_active = true;
                        return post_init();
                    case 'screenshot':
                        Intercom('hide');

                        Feedback.launched_from_intercom = true;

                        Feedback.reset();
                        Feedback.openControls();
                        Feedback.hideControls();

                        return Feedback.attachScreenshot.call(Feedback);
                    case 'video':
                        Intercom('hide');

                        Feedback.launched_from_intercom = true;

                        Feedback.reset();
                        Feedback.openControls();
                        Feedback.hideControls();

                        return Feedback.startVideo.call(Feedback);
                    case 'get_data':
                        const payload = Feedback.getData();
                        const video_blob = Feedback.video_blob || undefined;
                        return get_sheet_window().postMessage({
                            type: 'userback',
                            action: 'send_data',
                            request_url: Config.request_url,
                            payload,
                            video_blob,
                        }, '*');
                    default:
                        return;
                }
            }
            window.addEventListener('message', UserbackIntercomListener);

            // Get the inner intercom sheet frame or undefined
            const get_sheet_window = () => {
                const messenger = document.querySelector('iframe[name="intercom-messenger-frame"]');
                if (!messenger) {
                    return console.debug('No Messeneger iframe found!');
                }
                const iframe = messenger.contentDocument.querySelector('iframe');
                if (!iframe) {
                    return console.debug('No Sheet iframe found!');
                }
                return iframe.contentWindow[0];
            }
            const post_init = () => {
                const form_settings = Feedback.getFormSettings(Feedback.form_type);
                return get_sheet_window()?.postMessage({
                    type: 'userback',
                    action: 'init',
                    payload: {
                        powered_by: Feedback.configs.display_powered_by,
                        display_video: Feedback.configs.has_video && !Lib.isMobile() && !Lib.isTablet()
                    }
                }, '*');
            }
        },

        startSessionReplay: function() {
            // full session recording has started on page load
            if (Session_Recorder.isRecording()) {
                return;
            }

            var log_level;

            // when full session replay is on, the console logs are already set
            if (!Features.full_session_replay) {
                log_level = [];

                if (this.configs.capture_console_log) {
                    log_level.push('log');
                }
                if (this.configs.capture_console_info) {
                    log_level.push('info');
                }
                if (this.configs.capture_console_warn) {
                    log_level.push('warn');
                }
                if (this.configs.capture_console_debug) {
                    log_level.push('debug');
                }
                if (this.configs.capture_console_error) {
                    log_level.push('error');
                }
            }

            Session_Recorder.init({
                full_session_replay: false,
                recording_auto_start: true,
                recording_rules: {
                    block  : this.configs.recording_rules.block,
                    ignore : this.configs.recording_rules.ignore,
                    mask   : this.configs.recording_rules.mask,
                    console: log_level
                }
            });
        },

        // has old session recording?
        hasSessionRecording: function() {
            var has_session_recording = false;

            if (Features.session_recording) {
                $.each(this.configs.form_settings, function(key, settings) {
                    if (settings.allow_session_recording) {
                        has_session_recording = true;
                    }
                });
            }

            return has_session_recording;
        },

        getFormSettings: function(form_type) {
            if (typeof this.configs.form_settings[form_type] === 'undefined') {
                return this.form_settings_default[form_type];
            } else {
                return this.configs.form_settings[form_type];
            }
        },

        validateAPIHook: function(name, obj) {
            switch (name) {
                case 'before_send':
                case 'after_send':
                case 'on_open':
                case 'on_close':
                case 'on_load':
                case 'on_toolbar_close':
                    return typeof obj[name] === 'function';
                case 'email':
                case 'name':
                    return typeof obj[name] === 'string';
                case 'categories':
                    return typeof obj[name] === 'string';
                case 'priority':
                    return typeof obj[name] === 'string';
                case 'widget_settings':
                    return typeof obj[name] === 'object';
                case 'user_data':
                    return typeof obj[name] === 'object' && obj[name] && obj[name].id !== undefined && obj[name].info !== undefined;
                case 'custom_data':
                    return typeof obj[name] === 'object';

                // options
                case 'is_live':
                case 'native_screenshot':
                    return typeof obj[name] === 'boolean';
            }
        },

        removeAPIHooks: function() {
            var api_hooks = {
                before_send: null,
                after_send: null,
                on_open: null,
                on_close: null,
                on_load: null,
                on_toolbar_close: null,
                email: '',
                name: '',
                categories: '',
                priority: null,
                widget_settings: {},
                user_data: {},
                custom_data: {}
            };

            Object.keys(api_hooks).forEach((key) => {
                this[key] = api_hooks[key];
                delete window.Userback[key];
            });
        },

        setAPIHooks: function() {
            if (this.validateAPIHook('custom_data', Userback)) {
                Userback.setData(Userback.custom_data);
            }

            if (this.validateAPIHook('before_send', Userback)) {
                this.before_send = Userback.before_send;
            }

            if (this.validateAPIHook('after_send', Userback)) {
                this.after_send = Userback.after_send;
            }

            if (this.validateAPIHook('on_open', Userback)) {
                this.on_open = Userback.on_open;
            }

            if (this.validateAPIHook('on_close', Userback)) {
                this.on_close = Userback.on_close;
            }

            if (this.validateAPIHook('on_load', Userback)) {
                this.on_load = Userback.on_load;
            }

            if (this.validateAPIHook('on_toolbar_close', Userback)) {
                this.on_toolbar_close = Userback.on_toolbar_close;
            }

            if (this.validateAPIHook('email', Userback)) {
                Userback.setEmail(Userback.email);
            }

            if (this.validateAPIHook('name', Userback)) {
                Userback.setName(Userback.name);
            }

            if (this.validateAPIHook('categories', Userback)) {
                Userback.setCategories(Userback.categories);
            }

            if (this.validateAPIHook('priority', Userback)) {
                Userback.setPriority(Userback.priority);
           }

            if (Userback?.use_intercom === true) {
                this.use_intercom = true;
            }

            if (this.validateAPIHook('widget_settings', Userback)) {
                this.widget_settings = Userback.widget_settings;

                // migrate "display_attachment" to "allow_attachment
                if (typeof this.widget_settings.display_attachment !== 'undefined') {
                    this.widget_settings.allow_attachment = this.widget_settings.display_attachment;
                    delete this.widget_settings.display_attachment;
                }

                // migrate "autohide" to "trigger_type"
                if (typeof this.widget_settings.autohide !== 'undefined') {
                    this.widget_settings.trigger_type = this.widget_settings.autohide ? 'api' : 'page_load';
                    delete this.widget_settings.autohide;
                }

                // migrate "text_icon" to "text"
                if (typeof this.widget_settings.style === 'text_icon') {
                    this.widget_settings.style = 'text';
                }

                if (typeof this.widget_settings.form_settings === 'undefined') {
                    this.widget_settings.form_settings = {};
                }

                if (typeof this.widget_settings.form_settings.bug === 'undefined') {
                    this.widget_settings.form_settings.bug = {};
                }
                if (typeof this.widget_settings.form_settings.feature_request === 'undefined') {
                    this.widget_settings.form_settings.feature_request = {};
                }
                if (typeof this.widget_settings.form_settings.general === 'undefined') {
                    this.widget_settings.form_settings.general = {};
                }

                $.each(this.widget_settings, (key, value) => {
                    if (typeof this.configs.form_settings.general[key] !== 'undefined') {
                        // it is a form setting
                        if (typeof this.widget_settings.form_settings.bug[key] === 'undefined') {
                            this.widget_settings.form_settings.bug[key] = value;
                        }
                        if (typeof this.widget_settings.form_settings.feature_request[key] === 'undefined') {
                            this.widget_settings.form_settings.feature_request[key] = value;
                        }
                        if (typeof this.widget_settings.form_settings.general[key] === 'undefined') {
                            this.widget_settings.form_settings.general[key] = value;
                        }

                        delete this.widget_settings[key];
                    }
                });

                if (!Features.widget_branding) {
                    delete this.widget_settings.logo;
                }

                if (!Features.powered_by) {
                    delete this.widget_settings.display_powered_by;
                }
            }
        },

        isTargetingMatched: function(configs) {
            if (Config.load_type === 'chrome_extension' || Config.load_type === 'firefox_extension' || Config.load_type === 'edge_extension') {
                return true;
            }

            if (typeof configs === 'undefined') {
               configs = this.configs;
            }

            var device_match  = false;
            var url_match     = true;
            var segment_match = true;

            // Device
            if ((configs.device_type.indexOf('desktop') !== -1 && Lib.isDesktop()) ||
                (configs.device_type.indexOf('table')   !== -1 && Lib.isTablet()) ||
                (configs.device_type.indexOf('phone')   !== -1 && Lib.isMobile())) {
                device_match = true;
            }

            // Page URL
            if (!Config.demo_mode && configs.trigger_type === 'url_match' && configs.url_match_val) {
                var window_location = window.location.origin + window.location.pathname;

                switch (configs.url_match_type) {
                    case 'starts':
                        url_match = window_location.indexOf(configs.url_match_val) === 0;
                        break;
                    case 'ends':
                        var regexp = new RegExp(Lib.escapeRegExp(configs.url_match_val) + '$');
                        url_match = regexp.test(window_location) ? true : false;
                        break;
                    case 'contains':
                        url_match = window_location.indexOf(configs.url_match_val) !== -1;
                        break;
                    case 'contains_not':
                        url_match = window_location.indexOf(configs.url_match_val) === -1;
                        break;
                    case 'matches':
                        url_match = window_location === configs.url_match_val;
                        break;
                    case 'not':
                        url_match = window_location !== configs.url_match_val;
                        break;
                    case 'regex':
                        var regexp = new RegExp(configs.url_match_val);
                        url_match = regexp.test(window_location) ? true : false;
                        break;
                }
            }

            // User Segment
            if (!Config.demo_mode && configs.user_segment.segment_condition) {
                // feedback target only allows one segment to be used in target settings
                segment_match = User_Identification.matchSegments([configs.user_segment], 'all');
            }

            return device_match && url_match && segment_match ? true : false;
        },

        isMobile: function() {
            var temp_element = $('<div>').addClass('userback-mobile-only').appendTo($(document.body));
            var is_mobile    = temp_element.is(':visible') ? true : false;

            temp_element.remove();

            return is_mobile;
        },

        isAutoHide: function() {
            if (Config.load_type === 'chrome_extension' ||
                Config.load_type === 'firefox_extension' ||
                Config.load_type === 'edge_extension' ||
                this.configs.trigger_type === 'api' ||
                this.configs.trigger_type === 'widget_url') {
                return true;
            }

            return false;
        },

        // add css file
        addCSS: function(callback) {
            // Branding CSS
            var branding_css =
                ':root {' +
                    '--widget-button-bg: ' + this.configs.main_button_background_colour + ' !important;' +
                    '--widget-button-text: ' + this.configs.main_button_text_colour + ' !important;' +
                    '--widget-launcher-offset-x: ' + this.configs.launcher_offset_x + 'px !important;' +
                    '--widget-launcher-offset-y: ' + this.configs.launcher_offset_y + 'px !important;' +
                '}';
            $('<style>').html(branding_css).appendTo($(document.body));

            if ($('link.userback-css').length) {
                callback.call(self);
            } else {
                // CSS file
                var parent_node = document.head || document.body;

                $('<link>').attr({
                    'rel': 'stylesheet',
                    'class': 'userback-css',
                    'crossorigin': '',
                    'href': Config.widget_css
                }).appendTo($(parent_node));

                var self = this;
                var _interval_id = setInterval(function() {
                    var detector = $('<div>').addClass('userback-load-detector').appendTo($(document.body));

                    if (detector.css('z-index') == 1999) {
                        callback.call(self);
                        clearInterval(_interval_id);
                    }

                    detector.remove();
                }, 10);
            }
        },

        getButtonIcon: function(no_custom_icon) {
            switch (this.configs.icon) {
                case 1:
                    return Svg_Icons.widget_chat;
                case 2:
                    return Svg_Icons.widget_comment_smile;
                case 3: // deprecated
                    return Svg_Icons.widget_video;
                case 4:
                    return Svg_Icons.widget_question;
                case 5:
                    return Svg_Icons.widget_stars;
                case 6:
                    return Svg_Icons.widget_bug;
                case 7:
                    return Svg_Icons.widget_pointer;
                case 99:
                    if (no_custom_icon) {
                        return '';
                    } else {
                        return '<img src="' + Lib.htmlEntities(this.configs.icon_url) + '">';
                    }
                default:
                    return '';
            }
        },

        // add the feedback button
        addButton: function() {
            if (this.ui.button) {
                this.ui.button.remove();
            }

            // container
            this.ui.element = $('<ubdiv>')
            .addClass('userback-button-container')
            .attr({
                'loadtype':  Config.load_type,
                'id': 'userback_button_container',
                'data-html2canvas-ignore': 'true',
                'nextgen': this.isNextGen() ? 1 : 0
            })
            .appendTo($(document.body));

            if (Lib.isIE()) {
                this.ui.element.attr('msie', 'true');
            }

            this.ui.button = $('<ubdiv>').addClass('userback-button userback-button-' + this.configs.position);

            if (Config.demo_tutorial) {
                this.ui.button_arrow = $('<ubdiv>')
                    .addClass('userback-button-arrow userback-button-arrow-' + this.configs.position)
                    .attr('wstyle', this.configs.style)
                    .appendTo(this.ui.element);
            }

            if ((!this.isNextGen() && this.configs.trigger_type === 'api') || this.configs.use_modal) {
                this.ui.element.attr('modal', 'true');
            }

            var icon_svg = this.getButtonIcon();

            // something is wrong
            if (!icon_svg && this.configs.style === 'circle') {
                icon_svg = Svg_Icons.widget_chat;
            }

            this.ui.button
                .attr('wstyle', this.configs.style)
                .attr('wicon',  this.configs.icon);

            if (this.isAutoHide()) {
                this.ui.button.hide();
            }

            var content = '';

            if (this.configs.style === 'circle') {
                this.ui.button.attr('title', this.configs.main_button_text);
                content = icon_svg + Svg_Icons.times_l;
            } else {
                content = icon_svg + (Lib.htmlEntities(this.configs.main_button_text) || 'Feedback');
            }

            this.ui.button.html('<ubdiv class="userback-button-content">' + content + '</ubdiv>');

            var local_announcement_date = Lib.storage.get('portal_announcement_date') || 0;
            var badge = !!this.configs.portal_announcement_date && this.configs.portal_announcement_date > local_announcement_date;
            var has_announcement = this.configs.workflow_type.indexOf('announcement') !== -1;
            var show_badge = badge && has_announcement && this.isNextGen();

            if (show_badge) {
                this.ui.button.attr('badge', 'true');
                this.ui.dot = $('<ubdiv>').addClass('userback-announcement-dot');
                this.ui.dot.appendTo(this.ui.button);
            }

            this.ui.button.appendTo(this.ui.element);
            this.ui.button.on('click', () => {
                if (this.ui.controls) {
                    this.closeWidget();
                } else {
                    this.openControls();

                    if (Lib.isIos()) {
                        this.page_scroll_top = document.documentElement.scrollTop;
                    }

                    if (this.ui.button_arrow) {
                        this.ui.button_arrow.remove();
                        this.ui.button_arrow = null;
                    }
                }
            });

            // Ensure the widget when positioned on the left/right has a button width that is divisible by 2.
            // This prevents bluring when applying css transform()
            if (!this.isAutoHide() && this.configs.style === 'text' && (this.configs.position === 'e' || this.configs.position === 'w')) {
                if (navigator.userAgent.indexOf('Chrome') || navigator.userAgent.indexOf('chrome')) {
                    var button_width = this.ui.button[0].getBoundingClientRect().height;
                    var remainder = button_width % 2;
                    if (remainder) {
                        button_width += 2 - remainder
                    }
                    this.ui.button.css('min-width', button_width);
                }
            }

            this.ui.button.on('click mouseup mousedown', function(e) {
                e.stopPropagation();
            });

            if (Lib.getQueryString('ubwo') == 1) {
                Userback_Api.open();
            }

            $(window).on('beforeunload', (e) => {
                if (this.hasUnsavedChanges() || this.is_posting) {
                    e.returnValue = 'Are you sure you want to leave the page?'
                    return 'Are you sure you want to leave the page?';
                }
            });
        },

        setScreenStatus: function(message) {
            if (!this.ui.screen_status) {
                this.ui.screen_status = $('<ubdiv>').addClass('userback-screen-status').attr('data-html2canvas-ignore', 'true');
                this.ui.screen_status.appendTo($(document.body));
            }

            this.ui.screen_status.text(message);
        },

        clearScreenStatus: function() {
            if (this.ui.screen_status) {
                this.ui.screen_status.remove();
                this.ui.screen_status = false;
            }
        },

        addNativeScreenshot: function(image_data_uri) {
            this.native_image_data_uri = image_data_uri;
            this.addOverlay(image_data_uri);
        },

        show2StepOverlay: function() {
            if (this.isNextGen()) {
                return false;
            } else {
                return true;
            }
        },

        showUserInfoStep: function(form_settings) {
            if (!this.isNextGen()) {
                return false;
            }

            // email field or name field is in use
            if (form_settings.email_field || form_settings.name_field) {
                return false;
            }

            // user info has email and name
            var user_data = User_Identification.getAll();
            if (user_data && user_data.info && (user_data.info.email || user_data.info.name)) {
                return false;
            }

            // none of the above
            return true;
        },

        // add the overlay
        addOverlay: function(image_data_uri) {
            if (this.ui.overlay) {
                return;
            }

            this.checkLive();

            this.ui.element.attr('drawing', 'true');

            // hide widget
            if (this.show2StepOverlay()) {
                this.hideControls();
            }

            var dpr = typeof window.devicePixelRatio === 'undefined' ? 1 : window.devicePixelRatio;

            // create overlay
            this.ui.overlay = $('<ubdiv>').addClass('userback-overlay')
                                .html('<ubdiv class="userback-overlay-boundary-top"></ubdiv>' +
                                        '<ubdiv class="userback-overlay-boundary-bottom"></ubdiv>' +
                                        '<ubdiv class="userback-overlay-boundary-left"></ubdiv>' +
                                        '<ubdiv class="userback-overlay-boundary-right"></ubdiv>')
                                .attr('data-html2canvas-ignore', 'true')
                                .attr('lang', Feedback.configs.language)
                                .attr('dpr', dpr.toFixed(2))
                                .attr('tooltype', this.Tools.type);

            this.ui.overlay.appendTo($(document.body));

            if (image_data_uri) {
                this.ui.overlay.append('<ubdiv id="native_screenshot"><img src="' + image_data_uri + '"></ubdiv>');
            }

            this.ui.overlay.append('<svg id="snap_svg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"></svg>');

            // svg
            this.Tools.init();

            this.removeComments(); // destroy the hidden comments

            this.ui.overlay.on('click mouseup mousedown mouseout mousemove mouseenter mouseleave touchstart touchmove touchcancel touchend', (e) => {
                if (this.Tools.dragging_obj || this.Tools.draw_started) {
                    return;
                }

                e.stopPropagation();
            });

            // reset to false
            this.overlay_hint_tooltip_added = false;

            var _setTooltipPos = (e) => {
                if (!this.ui.overlay_hint_tooltip) {
                    return;
                }

                this.ui.overlay_hint_tooltip.css({
                    top  : e.clientY,
                    left : e.clientX + 5
                });
            };

            this.ui.overlay.on('mouseenter', (e) => {
                if (!this.ui.overlay.attr('screenshot-capturing') && !this.ui.overlay_hint_tooltip && !this.overlay_hint_tooltip_added) {
                    this.ui.overlay_hint_tooltip = $('<div>').addClass('userback-overlay-hint-cursor');

                    _setTooltipPos(e);

                    this.ui.overlay_hint_tooltip.appendTo(this.ui.overlay);
                }
            });

            this.ui.overlay.on('mousemove', (e) => {
                _setTooltipPos(e);
            });

            this.ui.overlay.on('mouseleave', () => {
                if (this.ui.overlay_hint_tooltip) {
                    this.ui.overlay_hint_tooltip.remove();
                }

                this.ui.overlay_hint_tooltip = false;
            });

            $(window).on('resize', this.windowResize.bind(this));
        },

        Tools: {
            type: 'square', // arrow, square, line, path, comment

            colour: {
                options : ['#E80000', '#FF7E42', '#FFD042', '#84FF42', '#42FFE6', '#2878F0', '#7828F0', '#FF42F9', '#FFFFFF', '#000000'],
                value   : Lib.storage.get('userback_tool_colour') || '#E80000'
            },

            start_x : 0,
            start_y : 0,
            stop_x  : 0,
            stop_y  : 0,

            path_min_x: 0,
            path_min_y: 0,
            path_max_x: 0,
            path_max_y: 0,

            svg_delete_icons : [],

            annotation_count: 0,

            // cut outs
            cut_outs: [],

            // base path
            svg_obj_base_path   : false,

            // arrow
            svg_obj_arrow_group : false,
            svg_obj_new_arrow   : false,
            svg_obj_new_arrow_top : false,

            // line
            svg_obj_line : false,

            // square
            svg_obj_rectangular : false,

            // path
            svg_obj_path : false,

            draw_started : false,
            dragging_obj: false,

            toolbar: null,
            toolbar_close: null,

            min_drag_distance: 10,

            init: async function() {
                await Scripts.loadSnapSvg();

                this.snap = Userback.Snap('#snap_svg');

                this.svg_obj_base_path = this.snap.path();
                this.svg_obj_base_path.attr({
                    'fill'         : 'rgb(128, 144, 160)',
                    'fill-opacity' : 0.3,
                    'fill-rule'    : 'evenodd'
                });

                this.setBasePathSize();

                // mouse
                this.snap.drag(this.dragMove.bind(this), this.dragStart.bind(this), this.dragStop.bind(this));

                // touch
                this.snap.touchstart(this.dragStart.bind(this));
                this.snap.touchmove(this.dragMove.bind(this));
                this.snap.touchcancel(this.dragStop.bind(this));
                this.snap.touchend(this.dragStop.bind(this));

                this.addTools();
            },

            isOpen: function() {
                return this.toolbar ? true : false;
            },

            setBasePathSize: function() {
                if (!this.svg_obj_base_path) {
                    return;
                }

                var base_width  = $('#snap_svg').width();
                var base_height = $('#snap_svg').height();

                var path = 'M0 0 h' + base_width + ' v' + base_height + ' h-' + base_width + ' Z';

                for (var i = 0; i < this.cut_outs.length; i++) {
                    path += ' M' + this.cut_outs[i].x + ' ' + this.cut_outs[i].y + ' h' + this.cut_outs[i].w + ' v' + this.cut_outs[i].h + ' h-' + this.cut_outs[i].w + ' Z';
                }

                this.svg_obj_base_path.attr('path', path);
            },

            hasNextButton: function() {
                return Feedback.show2StepOverlay() || Feedback.launched_from_intercom ? true : false;
            },

            addTools: function() {
                if (Lib.isTouch()) {
                    Feedback.disableScroll();
                }

                if (this.toolbar) {
                    return;
                }

                var tool_colour_indicator = '';

                tool_colour_indicator += '<ubdiv class="userback-toolbar-tool-colour-indicator">';
                tool_colour_indicator +=     '<ubcolourpicker>';
                $.each(this.colour.options, function(index, colour) {
                    tool_colour_indicator +=     '<ubdiv class="userback-toolbar-tool-colour-indicator-option" data-colour="' + colour + '">' +
                                                        '<span style="background-color:' + colour + ';"' + (colour === '#FFFFFF' ? ' hasborder="1"' : '') + '></span>' +
                                                    '</ubdiv>';
                });
                tool_colour_indicator +=     '</ubcolourpicker>';
                tool_colour_indicator += '</ubdiv>';

                var display_tutorial = false;

                if (Feedback.configs.display_tutorial == 1) {
                    display_tutorial = true; // always
                } else if (Feedback.configs.display_tutorial == 2) {
                    display_tutorial = Lib.storage.get('userback_tool_tutorial') ? false : true; // first time
                }

                var tools = '<btn class="userback-toolbar-tool userback-toolbar-tool-square" data-type="square" title="' + Lang.get('highlight', false) + '">' + Svg_Icons.square + '</btn>' +
                            '<btn class="userback-toolbar-tool userback-toolbar-tool-path" data-type="path" title="' + Lang.get('pencil', false) + '">' + Svg_Icons.highlighter + '</btn>' +
                            '<btn class="userback-toolbar-tool userback-toolbar-tool-line" data-type="line" title="' + Lang.get('line', false) + '">' + Svg_Icons.line + '</btn>' +
                            '<btn class="userback-toolbar-tool userback-toolbar-tool-arrow" data-type="arrow" title="' + Lang.get('arrow', false) + '">' + Svg_Icons.arrow + '</btn>' +
                            '<btn class="userback-toolbar-tool userback-toolbar-tool-blackout" data-type="blackout" title="' + Lang.get('blackout', false) + '">' + Svg_Icons.blackout + '</btn>' +
                            '<btn class="userback-toolbar-tool userback-toolbar-tool-text" data-type="text" title="' + Lang.get('text', false) + '">' + Svg_Icons.text + '</btn>' +
                            '<btn class="userback-toolbar-tool userback-toolbar-tool-colour" data-type="colour">' + tool_colour_indicator + '</btn>' +
                            (this.hasNextButton() ?
                            '<btn class="userback-toolbar-tool-done" title="' + Lang.get('capture_screenshot', false) + '">' +
                                (Lang.get('next') ? Lang.get('next') : Lang.get('save')) +
                            '</btn>' : '') +
                            (display_tutorial ?
                            '<btn class="userback-toolbar-help">' + Svg_Icons.widget_question + '</btn>' : '');

                this.toolbar = $('<utoolbar>')
                    .attr('data-html2canvas-ignore', 'true')
                    .attr('lang', Feedback.configs.language)
                    .attr('hasnext', this.hasNextButton() ? 'true' : 'false')
                    .attr('nextgen', Feedback.isNextGen() ? 1 : 0);

                this.toolbar_close = $('<btn>')
                    .addClass('userback-toolbar-close')
                    .attr('title', Lang.get('close'))
                    .html(Svg_Icons.close);

                this.toolbar.html(tools);
                this.setToolColour();
                this.toolbar.appendTo($(document.body));
                this.toolbar_close.appendTo($(document.body));

                // set default tool
                this.toolbar.find('.userback-toolbar-tool[data-type="' + this.type + '"]').addClass('userback-toolbar-tool-active');

                this.toolbar.find('.userback-toolbar-tool').on('click', (e) => {
                    var tool_type = $(e.currentTarget).data('type');

                    if (tool_type === 'colour') {
                        return;
                    } else if (tool_type === 'blackout') {
                        this.colour.value = '#000000';
                        this.setToolColour();
                    } else {
                        this.colour.value = Lib.storage.get('userback_tool_colour') || this.colour.value;
                        this.setToolColour();
                    }

                    Feedback.closeEmptyComment();

                    this.type = tool_type;

                    this.toolbar.find('.userback-toolbar-tool').removeClass('userback-toolbar-tool-active');
                    $(e.currentTarget).addClass('userback-toolbar-tool-active');

                    Feedback.ui.overlay.attr('tooltype', this.type);
                });

                this.toolbar.find('.userback-toolbar-tool-done').on('click', (e) => {
                    // debugger;
                    e.stopPropagation();
                    e.preventDefault();

                    if (!Feedback.configs.is_live_checked) {
                        Feedback.check_live_callback = function() {
                            Feedback.Tools.submitAnnotation();
                        };

                        Feedback.closeEmptyComment();
                        Feedback.Tools.removeTools();
                        Feedback.hideComments();
                        Feedback.setScreenStatus('Taking screenshot...');
                        return;
                    }

                    Feedback.Tools.submitAnnotation();

                    Session_Recorder.addCustomEvent('widget_open', {
                        type: 'widget_interaction',
                        name: 'Capture Screenshot'
                    });
                });

                this.toolbar_close.on('click', (e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this.cancelAnnotation();
                });

                this.toolbar.find('.userback-toolbar-help').on('click', function(e) {
                    Lib.storage.set('userback_tool_tutorial', 1);
                    Feedback.videoFullscreen(e, 'https://static.userback.io/video/widget_tutorial_v2.mp4');
                });

                this.toolbar.find('.userback-toolbar-tool-colour-indicator-option').on('click', (e) => {
                    var colour    = $(e.currentTarget).data('colour');
                    var tool_type = $(e.currentTarget).parents('.userback-toolbar-tool:first').data('type');

                    this.colour.value = colour;
                    Lib.storage.set('userback_tool_colour', colour);

                    this.setToolColour();
                });

                this.toolbar.on('click', function(e) {
                    e.stopPropagation();
                });
            },

            cancelVideoCapture: function() {
                this.cancelRoutingOption();
            },

            submitVideoCapture: function(video_blob, annotations) {
                Feedback.removeScreenshotData();

                Feedback.video_blob       = video_blob;
                Feedback.video_url        = window.URL.createObjectURL(video_blob);
                Feedback.video_annotation = annotations;

                Feedback.controls_step = 2;
                if (typeof Feedback.on_toolbar_submit === 'function') {
                    const propagate_ui = Feedback.on_toolbar_submit({
                        video_url: Feedback.video_url,
                        video_annotation: Feedback.video_annotation,
                    });
                    if (propagate_ui !== false) {
                        Feedback.showControls(true);
                    }
                } else {
                    Feedback.showControls(true);
                }
                Feedback.addScreenshotBadge();
                Feedback.renderAttachment(true); // attachment got reset, so update it again
            },

            cancelAnnotation: function(e) {
                this.cancelRoutingOption();
            },

            autoScreenshot: function(callback) {
                Feedback.capture(function(data_uri) {
                    Feedback.screenshot_data_uri.push({
                        data_uri    : Lib.replaceUnpairedSurrogates(data_uri),
                        window_x    : window.innerWidth,
                        window_y    : window.innerHeight,
                        window_top  : $(window).scrollTop()  || $(document).scrollTop(),
                        window_left : $(window).scrollLeft() || $(document).scrollLeft()
                    });

                    callback();
                }, 'html2image');
                // always use the html2image to take auto screenshots
                // - native screenshot has the browser prompt
                // - html2canvas is not reliable
            },

            submitAnnotation: function(callback) {
                Feedback.removeVideoData();

                // don't capture the overlay when there is no annotations
                if (Feedback.Tools.annotation_count === 0) {
                    if (Feedback.ui.overlay) {
                        Feedback.ui.overlay.addClass('_userback-blank');
                    }
                }

                Feedback.submitUnsavedComment();
                Feedback.closeEmptyComment();
                Feedback.Tools.removeTools();
                Feedback.hideComments();

                if (!Userback.screen_capture && !Feedback.configs.is_live) {
                    Feedback.setScreenStatus('Taking screenshot...');
                }

                Feedback.ui.overlay.attr('screenshot-capturing', 'true');

                // extension
                if (Userback.screen_capture) {
                    Feedback.ui.overlay.attr('extension-capturing', 'true');
                }

                // Cache window parameters
                Feedback.screenshot_window_x    = window.innerWidth;
                Feedback.screenshot_window_y    = window.innerHeight;
                Feedback.screenshot_window_top  = $(window).scrollTop()  || $(document).scrollTop();
                Feedback.screenshot_window_left = $(window).scrollLeft() || $(document).scrollLeft();

                setTimeout(function() {
                    Feedback.capture(function(data_uri) {
                        Feedback.clearScreenStatus();

                        Feedback.controls_step = 2;

                        Feedback.screenshot_data_uri.push({
                            data_uri    : Lib.replaceUnpairedSurrogates(data_uri),
                            window_x    : window.innerWidth,
                            window_y    : window.innerHeight,
                            window_top  : $(window).scrollTop()  || $(document).scrollTop(),
                            window_left : $(window).scrollLeft() || $(document).scrollLeft()
                        });

                        Feedback.checkScreenshotLimit();

                        const cancel_ui = Feedback.on_toolbar_submit?.(Feedback.screenshot_data_uri[Feedback.screenshot_data_uri.length - 1]);

                        // TODO: is this behaviour still needed for intercom?
                        if (!Feedback.isNextGen()) {
                            if (cancel_ui !== false) {
                                Feedback.showControls(true);
                            }
                        }

                        Feedback.addScreenshotBadge();
                        Feedback.renderAttachment(true); // attachment got reset, so update it again
                        Feedback.removeOverlay();
                        Feedback.Tools.annotation_count = 0;

                        // TODO: chrome extension && retina or zommed
                        // FIXME: code is no longer used?
                        if (Userback.screen_capture && (Config.load_type === 'chrome_extension' || Config.load_type === 'firefox_extension' || Config.load_type === 'edge_extension')) {
                            Lib.getImageSize(data_uri, function(image_width, image_height) {
                                if (Math.abs(image_width - $(window).width()) > 30) {
                                    Lib.resizeImage(data_uri, $(window).width(), 'image/png', function(data) {
                                        Feedback.screenshot_data_uri[Feedback.screenshot_data_uri.length - 1].data_uri = data;
                                    });
                                }
                            });
                        }

                        if (callback) {
                            callback();
                        }

                    });
                }, Userback.screen_capture ? 20 : 0);
            },

            // when the close button is clicked
            cancelRoutingOption: function() {
                if (Feedback.controls_step) {
                    Feedback.showControls();
                    Feedback.removeOverlay();
                    Feedback.removeComments();
                    this.removeTools();
                } else {
                    Feedback.removeOverlay();
                    Feedback.removeComments();
                    this.removeTools();

                    Feedback.reset();
                }
                Feedback.on_toolbar_close?.();
            },

            removeTools: function() {
                Feedback.enableScroll();

                if (!this.toolbar) {
                    return;
                }

                this.toolbar.remove();
                this.toolbar_close.remove();
                this.toolbar = null;
                this.toolbar_close = null;

                this.svg_obj_base_path = null;
                this.cut_outs = [];
            },

            removeDeleteIcons: function() {
                $(this.svg_delete_icons).each(function() {
                    this.remove();
                });

                this.svg_delete_icons = [];
            },

            setToolColour: function() {
                this.toolbar.find('.userback-toolbar-tool-colour-indicator').css('background-color', this.colour.value);

                if (this.colour.value === '#FFFFFF') {
                    this.toolbar.find('.userback-toolbar-tool-colour-indicator').attr('hasborder', 1);
                } else {
                    this.toolbar.find('.userback-toolbar-tool-colour-indicator').removeAttr('hasborder');
                }
            },

            dragStart: function() {
                var x, y, event;

                if (typeof arguments[0] === 'object') {
                    event = arguments[0];
                } else {
                    event = arguments[2];
                }

                if (event.changedTouches && event.changedTouches.length) {
                    x = event.changedTouches[0].clientX;
                    y = event.changedTouches[0].clientY;
                } else {
                    x = event.clientX;
                    y = event.clientY;
                }

                // avoid half pixel issue
                x = parseInt(x, 10);
                y = parseInt(y, 10);

                if (this.dragging_obj) {
                    // start
                    if (this.dragging_obj.data('drag_started')) {
                        return;
                    }

                    this.dragging_obj.appendTo(self.snap);

                    this.dragging_obj.data('drag_started', true);

                    this.dragging_obj.data('original_x', x);
                    this.dragging_obj.data('original_y', y);

                    if (!this.dragging_obj.data('transform_x')) {
                        this.dragging_obj.data('transform_x', 0);
                        this.dragging_obj.data('transform_y', 0);
                    }

                    if (this.dragging_obj.data('type') === 'square') {
                        var cut_out_x = this.dragging_obj[0].attr('x');
                        var cut_out_y = this.dragging_obj[0].attr('y');

                        var cut_out_index = false;
                        for (var i = 0; i < this.cut_outs.length; i++) {
                            if (this.cut_outs[i].x == cut_out_x && this.cut_outs[i].y == cut_out_y) {
                                cut_out_index = i;
                                break;
                            }
                        }

                        if (cut_out_index !== false) {
                            this.dragging_obj.data('cut_out_index',      cut_out_index);
                            this.dragging_obj.data('cut_out_original_x', this.cut_outs[cut_out_index].x)
                            this.dragging_obj.data('cut_out_original_y', this.cut_outs[cut_out_index].y)
                        }
                    }
                } else {
                    // start
                    if (this.draw_started) {
                        return;
                    }

                    this.draw_started = true;

                    // do not hide the form when adding a pin comment
                    if (this.type !== 'text') {
                        Feedback.ui.controls.attr('annotation', 1);
                        Feedback.Tools.toolbar.attr('annotation', 1);
                    }

                    var cut_out_id = (new Date()).getTime();

                    switch (this.type) {
                        case 'arrow':
                            this.svg_obj_new_arrow = this.snap.line(x, y, x, y);
                            this.svg_obj_new_arrow.attr({
                                'stroke'          : this.colour.value,
                                'stroke-width'    : 6,
                                'stroke-linecap'  : 'round',
                                'stroke-linejoin' : 'round'
                            });

                            var point1 = x + ' ' + y;
                            var point2 = x + ' ' + y;
                            var point3 = x + ' ' + y;
                            this.svg_obj_new_arrow_top = this.snap.polyline(point1 + ',' + point2 + ',' + point3);
                            this.svg_obj_new_arrow_top.attr({
                                fill: this.colour.value
                            });

                            this.svg_obj_arrow_group = this.snap.group(this.svg_obj_new_arrow, this.svg_obj_new_arrow_top);
                            this.svg_obj_arrow_group.attr({
                                'opacity': 0.5
                            });

                            break;
                        case 'line':
                            this.svg_obj_line = this.snap.line(x, y, x, y);
                            this.svg_obj_line.attr({
                                'stroke'          : this.colour.value,
                                'stroke-width'    : 4,
                                'stroke-linecap'  : 'round',
                                'stroke-linejoin' : 'round',
                                'opacity'         : 0.5
                            });

                            break;
                        case 'square':
                            this.svg_obj_rectangular = this.snap.rect(x, y, 1, 1, 3, 3);
                            this.svg_obj_rectangular.attr({
                                'stroke'           : this.colour.value,
                                'stroke-width'     : 4,
                                'stroke-linecap'   : 'round',
                                'stroke-linejoin'  : 'round',
                                'fill'             : 'transparent',
                                'opacity'          : 0.5
                            });

                            this.cut_outs.push({
                                id : cut_out_id,
                                x  : x,
                                y  : y,
                                w  : 0,
                                h  : 0
                            });

                            break;
                        case 'blackout':
                            this.svg_obj_rectangular = this.snap.rect(x, y, 1, 1, 3, 3);
                            this.svg_obj_rectangular.attr({
                                'fill'           : this.colour.value,
                                'class'          : 'blackout',
                                'opacity'        : 0.5
                            });

                            break;
                        case 'path':
                            this.svg_obj_path = this.snap.path('M'+ x +',' + y);
                            this.svg_obj_path.attr({
                                'stroke'          : this.colour.value,
                                'stroke-width'    : 15,
                                'stroke-linecap'  : 'round',
                                'stroke-linejoin' : 'round',
                                'fill'            : 'transparent',
                                'opacity'         : 0.8
                            });

                            break;
                    }
                }

                this.start_x = x;
                this.start_y = y;
                this.stop_x  = x;
                this.stop_y  = y;

                this.path_min_x = x;
                this.path_min_y = y;
                this.path_max_x = x;
                this.path_max_y = y;

                this.toolbar_left   = this.toolbar.offset().left - 30;
                this.toolbar_right  = this.toolbar_left + this.toolbar.outerWidth() + 60;
                this.toolbar_top    = this.toolbar.position().top - 30;
                this.toolbar_bottom = this.toolbar_top + this.toolbar.outerHeight() + 60;
            },

            dragStop: function(event) {
                var x, y;

                if (event.changedTouches && event.changedTouches.length) {
                    x = event.changedTouches[0].clientX;
                    y = event.changedTouches[0].clientY;
                } else {
                    x = event.clientX;
                    y = event.clientY;
                }

                // when dragging is stopped
                if (this.dragging_obj) {
                    if (!this.dragging_obj.data('drag_started')) {
                        return;
                    }

                    // update data so that they can be dragged again
                    this.dragging_obj.data('drag_started', false);
                    this.dragging_obj.data('transform_x', this.dragging_obj.data('transform_x_temp'));
                    this.dragging_obj.data('transform_y', this.dragging_obj.data('transform_y_temp'));

                    // add comment inside box
                    var movement_x = Math.abs(this.start_x - x);
                    var movement_y = Math.abs(this.start_y - y);

                    if (!$(event.target).hasClass('userback-svg-delete') && movement_x <= 2 && movement_y <= 2) {
                        Feedback.commentStart.call(Feedback, event);
                    }

                    // reset
                    this.dragging_obj = false;

                    this.start_x = 0;
                    this.start_y = 0;
                    this.stop_x  = 0;
                    this.stop_y  = 0;

                    this.path_min_x = 0;
                    this.path_min_y = 0;
                    this.path_max_x = 0;
                    this.path_max_y = 0;
                } else {
                    // stop
                    if (!this.draw_started) {
                        return;
                    }

                    if (this.type === 'text' || (Math.abs(this.path_max_x - this.path_min_x) < this.min_drag_distance && Math.abs(this.path_max_y - this.path_min_y) < this.min_drag_distance)) {
                        // do not draw when distance is too short
                        switch (this.type) {
                            case 'arrow':
                                this.svg_obj_arrow_group.remove();
                                break;
                            case 'line':
                                this.svg_obj_line.remove();
                                break;
                            case 'square':
                            case 'blackout':
                                this.svg_obj_rectangular.remove();
                                break;
                            case 'path':
                                this.svg_obj_path.remove();
                                break;
                        }

                        if (this.cut_outs.length) {
                            var last_cut_out = this.cut_outs[this.cut_outs.length - 1];

                            if (last_cut_out.w < this.min_drag_distance && last_cut_out.h < this.min_drag_distance) {
                                this.cut_outs.splice(this.cut_outs.length - 1, 1);
                            }
                        }

                        this.setBasePathSize();

                        Feedback.commentStart.call(Feedback, event);

                        // remove the tooltip
                        Feedback.ui.overlay.trigger('mouseleave');
                    } else {
                        // ok, we are ready to draw now
                        var icon_x = x - 12;
                        var icon_y = y - 12;

                        if (this.type === 'square' || this.type === 'blackout') {
                            icon_x = parseInt(this.svg_obj_rectangular.attr('x'), 10) + parseInt(this.svg_obj_rectangular.attr('width'), 10) - 12;
                            icon_y = parseInt(this.svg_obj_rectangular.attr('y'), 10) - 12;
                        }

                        var icon = this.snap.image(Svg_Icons.delete_icon, icon_x, icon_y, 24, 24)
                                            .attr({cursor:'pointer', display:'none'})
                                            .addClass('_userback-blank userback-svg-delete');

                        this.svg_delete_icons.push(icon);

                        switch (this.type) {
                            case 'arrow':
                                this.svg_obj_arrow_group.attr({
                                    'opacity': 1
                                });
                                this.svg_obj_arrow_group = this.snap.group(this.svg_obj_arrow_group, icon);

                                this.hoverable(this.svg_obj_arrow_group, icon, this.type);
                                this.draggable(this.svg_obj_arrow_group, this.type);

                                break;
                            case 'line':
                                this.svg_obj_line.attr({
                                    'opacity': 1
                                });
                                this.svg_obj_line = this.snap.group(this.svg_obj_line, icon);

                                this.hoverable(this.svg_obj_line, icon, this.type);
                                this.draggable(this.svg_obj_line, this.type);

                                break;
                            case 'square':
                                this.svg_obj_rectangular.attr({
                                    'opacity': 1
                                });

                                this.svg_obj_rectangular = this.snap.group(this.svg_obj_rectangular, icon);

                                this.hoverable(this.svg_obj_rectangular, icon, this.type, this.cut_outs[this.cut_outs.length - 1].id);
                                this.draggable(this.svg_obj_rectangular, this.type);

                                break;
                            case 'blackout':
                                this.svg_obj_rectangular.attr({
                                    'opacity': 1
                                });
                                this.svg_obj_rectangular = this.snap.group(this.svg_obj_rectangular, icon);

                                this.hoverable(this.svg_obj_rectangular, icon, this.type);
                                this.draggable(this.svg_obj_rectangular, this.type);

                                break;
                            case 'path':
                                this.svg_obj_path = this.snap.group(this.svg_obj_path, icon);

                                this.hoverable(this.svg_obj_path, icon, this.type);
                                this.draggable(this.svg_obj_path, this.type);

                                break;
                        }
                    }

                    this.draw_started = false;

                    Feedback.ui.controls.removeAttr('annotation');
                    Feedback.Tools.toolbar.removeAttr('annotation');

                    // next gen: auto focus feedback form after drawing
                    if (Feedback.isNextGen() && ['square', 'arrow'].indexOf(this.type) !== -1) {
                        Feedback.focusControls();
                    }

                    this.start_x = 0;
                    this.start_y = 0;
                    this.stop_x  = 0;
                    this.stop_y  = 0;

                    this.svg_obj_arrow_group = false;
                    this.svg_obj_new_arrow_top = false;
                    this.svg_obj_new_arrow   = false;

                    this.svg_obj_line        = false;
                    this.svg_obj_rectangular = false;
                    this.svg_obj_path        = false;

                    this.toolbar.find('.userback-toolbar-tool-done').addClass('userback-toolbar-tool-done-active');
                }

                Feedback.overlay_hint_tooltip_added = true;
                if (Feedback.ui.overlay_hint_tooltip) {
                    Feedback.ui.overlay_hint_tooltip.remove();
                    Feedback.ui.overlay_hint_tooltip = false;
                }

                Feedback.disableScroll();
            },

            dragMove: function() {
                var x, y, event;

                if (typeof arguments[0] === 'object') {
                    event = arguments[0];
                } else {
                    event = arguments[4];
                }

                if (event.changedTouches && event.changedTouches.length) {
                    x = event.changedTouches[0].clientX;
                    y = event.changedTouches[0].clientY;
                } else {
                    x = event.clientX;
                    y = event.clientY;
                }

                if (this.dragging_obj) {
                    if (!this.dragging_obj.data('drag_started')) {
                        return;
                    }

                    var t = new Snap.Matrix();
                    var mouse_movement_x = x - this.dragging_obj.data('original_x');
                    var mouse_movement_y = y - this.dragging_obj.data('original_y');

                    var transform_x = mouse_movement_x + this.dragging_obj.data('transform_x');
                    var transform_y = mouse_movement_y + this.dragging_obj.data('transform_y');

                    this.dragging_obj.data('transform_x_temp', transform_x);
                    this.dragging_obj.data('transform_y_temp', transform_y);

                    // move square
                    t.translate(transform_x, transform_y);
                    this.dragging_obj.transform(t);

                    if (this.dragging_obj.data('type') === 'square') {
                        var cut_out_index = this.dragging_obj.data('cut_out_index');
                        this.cut_outs[cut_out_index].x = this.dragging_obj.data('cut_out_original_x') + transform_x;
                        this.cut_outs[cut_out_index].y = this.dragging_obj.data('cut_out_original_y') + transform_y;

                        this.setBasePathSize();
                    }
                } else {
                    // move
                    if (!this.draw_started) {
                        return;
                    }

                    var direction_x = x - this.start_x > 0 ? 'right' : 'left';
                    var direction_y = y - this.start_y > 0 ? 'down'  : 'up';

                    this.stop_x = x;
                    this.stop_y = y;

                    // don't draw off the screen
                    this.stop_x = Math.max(6, this.stop_x);
                    this.stop_y = Math.max(6, this.stop_y);
                    this.stop_x = Math.min(this.snap.node.clientWidth - 6,  this.stop_x);
                    this.stop_y = Math.min(this.snap.node.clientHeight - 6, this.stop_y);

                    // update min/max path
                    this.path_min_x = Math.min(this.path_min_x, x);
                    this.path_min_y = Math.min(this.path_min_y, y);
                    this.path_max_x = Math.max(this.path_max_x, x);
                    this.path_max_y = Math.max(this.path_max_y, y);

                    switch (this.type) {
                        case 'arrow':
                            this.svg_obj_new_arrow.attr({
                                x2: this.stop_x,
                                y2: this.stop_y
                            });

                            var x_length = Math.abs(this.stop_x - this.start_x);
                            var y_length = Math.abs(this.stop_y - this.start_y);

                            var y_amount = 40;

                            if (x_length < y_amount && y_length < y_amount) {
                                y_amount = Math.max(x_length, y_length);
                            }

                            var x_amount = y_amount / 2.5;

                            var point1 = (this.stop_x) + ' ' + (this.stop_y);
                            var point2;
                            var point3;
                            var point4;
                            var translate_y = 0;

                            if (this.stop_y >= this.start_y) {
                                point2 = (this.stop_x - x_amount) + ' ' + (this.stop_y - y_amount);
                                point3 = this.stop_x + ' ' + (this.stop_y - y_amount + 5);
                                point4 = (this.stop_x + x_amount) + ' ' + (this.stop_y - y_amount);
                                translate_y = 8;
                            } else {
                                point2 = (this.stop_x - x_amount) + ' ' + (this.stop_y + y_amount);
                                point3 = this.stop_x + ' ' + (this.stop_y + y_amount - 5);
                                point4 = (this.stop_x + x_amount) + ' ' + (this.stop_y + y_amount);
                                translate_y = -8;
                            }

                            this.svg_obj_new_arrow_top.attr({
                                points: point1 + ',' + point2 + ',' + point3 + ',' + point4 + ',' + point1
                            });

                            var deg = Math.atan((this.stop_x - this.start_x) / (this.stop_y - this.start_y)) * 180 / Math.PI;

                            // NaN
                            if (!deg) {
                                deg = 0;
                            }

                            var arrow_2_matrix = new Snap.Matrix();
                            arrow_2_matrix.rotate(deg * -1, this.stop_x, this.stop_y);
                            arrow_2_matrix.translate(0, translate_y);
                            this.svg_obj_new_arrow_top.transform(arrow_2_matrix);

                            break;
                        case 'line':
                            this.svg_obj_line.attr({
                                x2: this.stop_x,
                                y2: this.stop_y
                            });
                            break;
                        case 'square':
                            var square_x = direction_x === 'right' ? this.start_x : this.stop_x;
                            var square_y = direction_y === 'down'  ? this.start_y : this.stop_y;
                            var square_w = Math.abs(this.stop_x - this.start_x);
                            var square_h = Math.abs(this.stop_y - this.start_y);

                            this.svg_obj_rectangular.attr({
                                x      : square_x,
                                y      : square_y,
                                width  : square_w,
                                height : square_h
                            });

                            this.cut_outs[this.cut_outs.length - 1].x = square_x;
                            this.cut_outs[this.cut_outs.length - 1].y = square_y;
                            this.cut_outs[this.cut_outs.length - 1].w = square_w;
                            this.cut_outs[this.cut_outs.length - 1].h = square_h;

                            this.setBasePathSize();

                            break;
                        case 'blackout':
                            this.svg_obj_rectangular.attr({
                                x      : direction_x === 'right' ? this.start_x : this.stop_x,
                                y      : direction_y === 'down'  ? this.start_y : this.stop_y,
                                width  : Math.abs(this.stop_x - this.start_x),
                                height : Math.abs(this.stop_y - this.start_y)
                            });
                            break;
                        case 'path':
                            var current_path = this.svg_obj_path.attr('path');
                            var new_path = current_path + 'L' + this.stop_x + ',' + this.stop_y;

                            this.svg_obj_path.attr('path', new_path);
                            break;
                    }
                }
            },

            hoverable: function(obj, delete_icon, type, cut_out_id) {
                var shadow_filter = this.snap.filter(Snap.filter.shadow(0, 0, 5, 'black', 0.5));
                var timeout_id = false;

                this.annotation_count++;

                var _doRemove = () => {
                    obj.remove();

                    if (type === 'square') {
                        var cut_out_index = false;

                        for (var i = 0; i < this.cut_outs.length; i++) {
                            if (this.cut_outs[i].id === cut_out_id) {
                                cut_out_index = i;
                                break;
                            }
                        }

                        if (cut_out_index !== false) {
                            this.cut_outs.splice(cut_out_index, 1);
                            this.setBasePathSize();
                        }
                    }
                };

                delete_icon.click((e) => {
                    _doRemove();
                    this.annotation_count--;

                    if (this.annotation_count === 0) {
                        Feedback.enableScroll();
                    }
                });

                obj.mouseover(() => {
                    Feedback.ui.overlay.attr('hidecursor', 'true');

                    if (this.draw_started) {
                        return;
                    }

                    clearTimeout(timeout_id);

                    delete_icon.attr('display', '');
                    obj.attr({
                        filter : shadow_filter
                    });
                });

                obj.mouseout(() => {
                    Feedback.ui.overlay.removeAttr('hidecursor');

                    timeout_id = setTimeout(() => {
                        delete_icon.attr('display', 'none');
                        obj.attr({
                            filter: ''
                        });
                    }, 200);
                });

                if (Feedback.has_touch) {
                    delete_icon.touchend(function() {
                        // let the other dragging touch event finish first
                        setTimeout(function() {
                            _doRemove();
                        }, 0);
                    });

                    obj.touchstart(function() {
                        if (delete_icon.attr('display') === 'none') {
                            delete_icon.attr('display', '');
                        } else {
                            delete_icon.attr('display', 'none');
                        }
                    });
                }
            },

            draggable: function(obj, type) {
                var dragStart = function(event, x, y) {
                    this.dragging_obj = obj;
                    this.dragging_obj.data('type', type);
                };

                obj.attr({cursor:'move'});

                obj.mousedown(dragStart.bind(this));
                obj.touchstart(dragStart.bind(this));
            }
        },

        // remove the overlay
        removeOverlay: function() {
            if (!this.ui.overlay) {
                return;
            }

            this.ui.element.removeAttr('drawing');

            if (this.ui.overlay.overlay_hint_tooltip) {
                this.ui.overlay.overlay_hint_tooltip.remove();
                this.ui.overlay.overlay_hint_tooltip = null;
            }

            this.ui.overlay.remove();
            this.ui.overlay = null;

            $(window).off('resize', this.windowResize.bind(this));
        },

        windowResize: function(e) {
            if (this.ui.overlay) {
                this.Tools.setBasePathSize.call(this.Tools);
            }
        },

        _mouseScroll: function(e) {
            if ($(e.target).hasClass('ubmousescroll')) {
            } else {
                e.preventDefault();
            }
        },

        _scrollKeyDown: function(e) {
            if ($(e.target).hasClass('ubmousescroll')) {
                return;
            }

            if ($(e.target).prop('tagName') === 'TEXTAREA') {
                return;
            }

            var keys = {37: 1, 38: 1, 39: 1, 40: 1};

            if (keys[e.keyCode]) {
                e.preventDefault();
            }
        },

        _scrollWindow: function(e) {
            $(window).scrollTop(this._scroll_top);
        },

        disableScroll: function() {
            if (this._scroll_disabled) {
                return;
            }

            this._scroll_top = $(window).scrollTop();
            this._scroll_disabled = true;

            $('html').addClass('ub-noscroll');

            $(window).on('scroll.lock', this._scrollWindow.bind(this));
            window.addEventListener('wheel',          this._mouseScroll, {passive: false});
            window.addEventListener('mousewheel',     this._mouseScroll, {passive: false});
            window.addEventListener('DOMMouseScroll', this._mouseScroll, {passive: false});
            window.addEventListener('touchmove',      this._mouseScroll, {passive: false});
            $(document).on('keydown', this._scrollKeyDown);
        },

        enableScroll: function() {
            this._scroll_disabled = false;

            $('html').removeClass('ub-noscroll');

            $(window).off('scroll.lock');
            window.removeEventListener('wheel',          this._mouseScroll, {passive: false});
            window.removeEventListener('mousewheel',     this._mouseScroll, {passive: false});
            window.removeEventListener('DOMMouseScroll', this._mouseScroll, {passive: false});
            window.removeEventListener('touchmove',      this._mouseScroll, {passive: false});
            $(document).off('keydown', this._scrollKeyDown);
        },

        addComment: function(x, y) {
            this.closeEmptyComment();

            var screenshot_num = this.screenshot_data_uri.length;
            var counter = 0;

            $.each(this.comments, function(index, comment) {
                if (comment.screenshot_num === screenshot_num) {
                    counter++;
                }
            });

            var comment = new Feedback_Comment({
                x: x,
                y: y,
                scroll_l: $(window).scrollLeft(),
                scroll_t: $(window).scrollTop(),
                offset_x: 0,
                offset_y: 0,
                screenshot_num: screenshot_num,
                counter: counter,
                onOpen: (counter) => {
                    $.each(this.comments, function(index, comment) {
                        if (comment.screenshot_num === screenshot_num && comment.counter !== counter) {
                            comment.hideForm();
                        }
                    });
                },
                onDelete: (counter) => {
                    var index_lookup = false;
                    $.each(this.comments, function(index, comment) {
                        if (comment.screenshot_num === screenshot_num && counter === comment.counter) {
                            index_lookup = index;
                        }
                    });

                    if (index_lookup !== false) {
                        this.comments.splice(index_lookup, 1);
                    }

                    // update labels
                    var new_counter = 0;
                    $.each(this.comments, function(index, comment) {
                        if (comment.screenshot_num === screenshot_num) {
                            comment.setCounter(new_counter);
                            new_counter++;
                        }
                    });
                }
            });

            comment.add();
            this.comments.push(comment);
        },

        hideComments: function() {
            $.each(this.comments, function(index, comment) {
                comment.hide();
            });
        },

        removeComments: function() {
            var screenshot_num = this.screenshot_data_uri.length;

            var new_comments = [];
            $.each(this.comments, function(index, comment) {
                if (comment.screenshot_num === screenshot_num) {
                    comment.destroy();
                } else {
                    new_comments.push(comment);
                }
            });

            this.comments = new_comments;
        },

        feedbackHTML: function(feedback) {
            var feedback_title = feedback.title ? feedback.title : feedback.page;

            if (feedback.video_url) {
                return '<ubdiv class="userback-feedback userback-feedback-video">' +
                            '<a href="' + feedback.url + '" target="viewer">' +
                                Svg_Icons.play +
                                '<video src="' + feedback.video_url + '" preload="metadata" muted></video>' +
                                '<ubdiv>' +
                                    '<ubdiv class="userback-feedback-description">' + Lib.htmlEntities(feedback.description, true) + '</ubdiv>' +
                                '</ubdiv>' +
                            '</a>' +
                            '<div class="userback-feedback-page" title="' + Lib.htmlEntities(feedback_title) + '">' +
                                '<span>' + (feedback.id || '') + '</span>' +
                                Lib.htmlEntities(feedback_title) +
                            '</div>' +
                        '</ubdiv>';
            } else {
                return '<ubdiv class="userback-feedback' + (feedback.screenshot ? '' : ' userback-feedback-general') + '">' +
                            '<a href="' + feedback.url + '" target="viewer"' + (feedback.screenshot ? ' style="background-image:url(\'' + Lib.htmlEntities(feedback.screenshot) + '\');"' : '') + '>' +
                                '<ubdiv>' +
                                    '<ubdiv class="userback-feedback-description">' + Lib.htmlEntities(feedback.description, true) + '</ubdiv>' +
                                '</ubdiv>' +
                            '</a>' +
                            '<ubdiv class="userback-feedback-page" title="' + Lib.htmlEntities(feedback_title) + '">' +
                                '<span>' + (feedback.id || '') + '</span>' +
                                Lib.htmlEntities(feedback_title) +
                            '</ubdiv>' +
                        '</ubdiv>';
            }
        },

        addFeedbackView: function() {
            if (this.ui.feedback_view) {
                return;
            }

            var html = '';

            html += '<ubdiv class="userback-feedback-view-title">' +
                        '<uclose class="userback-feedback-view-close userback-button-close-light">' + Svg_Icons.xmark_r + '</uclose>' +
                        '<ubdiv class="userback-feedback-view-search">' + Svg_Icons.search + '</ubdiv>' +
                        '<ubdiv class="userback-feedback-view-search-total">' +
                            '<span>' + Lang.get('feedback_type_all') + '</span>' +
                            Svg_Icons.angle_down +
                            '<ubdropdown>' +
                                '<uboption class="feedback-filter-type active" data-val="all">' + Svg_Icons.tick + Lang.get('feedback_type_all') + '</uboption>' +
                                '<uboption class="feedback-filter-type" data-val="general">' + Svg_Icons.tick + Lang.get('feedback_type_general') + '</uboption>' +
                                '<uboption class="feedback-filter-type" data-val="bug">' + Svg_Icons.tick + Lang.get('feedback_type_bug') + '</uboption>' +
                                '<uboption class="feedback-filter-type" data-val="feature_request">' + Svg_Icons.tick + Lang.get('feedback_type_feature_request') + '</uboption>' +
                                '<uboptionline></uboptionline>' +
                                '<uboption class="feedback-filter-page active" data-val="page">' + Svg_Icons.tick + Lang.get('this_page_only') + '</uboption>' +
                            '</ubdropdown>' +
                        '</ubdiv>' +
                    '</ubdiv>';

            html += '<ubdiv class="userback-feedback-view-scroller userback-feedback-view-scroller-loading"></ubdiv>';
            html += '<form class="userback-feedback-view-search-form">' +
                        '<input type="text" placeholder="' + Lang.get('search') + '">' +
                        '<a href="#">' + Lang.get('cancel') + '</a>' +
                    '</form>';

            this.ui.feedback_view = $('<div>').addClass('userback-feedback-view userback-feedback-view-' + this.configs.position);
            this.ui.feedback_view.html(html);
            this.ui.feedback_view.appendTo($(document.body));

            // wait for animation to finish
            setTimeout(() => {
                this.searchFeedbackView();
            }, 200);

            this.bindEvents();
        },

        bindEvents: function() {
            if (this.feedback_view_events_binded) {
                return;
            }

            $(document).on('click', '.userback-feedback-view-close', (e) => {
                this.removeFeedbackView();

                Feedback.Tools.cancelRoutingOption.call(Feedback.Tools);
            });

            $(document).on('click', '.userback-feedback-view-search-total', (e) => {
                e.stopPropagation();

                this.ui.feedback_view.find('.userback-feedback-view-search-form').hide();
                this.ui.feedback_view.find('ubdropdown').toggleClass('isopen');
            });

            $(document).on('click', (e) => {
                if (this.ui.feedback_view) {
                    this.ui.feedback_view.find('ubdropdown').removeClass('isopen');
                }
            });

            $(document).on('click', '.userback-feedback-view-search-form a', (e) => {
                e.preventDefault();
                this.ui.feedback_view.find('.userback-feedback-view-search-form').hide();
            });

            $(document).on('submit', '.userback-feedback-view-search-form', (e) => {
                e.preventDefault();

                this.ui.feedback_view.find('.userback-feedback-view-search-form').hide();
                this.searchFeedbackView();
            });

            $(document).on('click', 'uboption.feedback-filter-type', (e) => {
                this.ui.feedback_view.find('uboption.feedback-filter-type').removeClass('active');
                $(e.currentTarget).addClass('active');

                this.ui.feedback_view.find('.userback-feedback-view-search-total > span').html($(e.currentTarget).text());

                this.searchFeedbackView();
            });

            $(document).on('click', 'uboption.feedback-filter-page', (e) => {
                $(e.currentTarget).toggleClass('active');
                this.searchFeedbackView();
            });

            this.feedback_view_events_binded = true;
        },

        searchFeedbackView: function() {
            this.ui.feedback_view.find('.userback-feedback-view-scroller').addClass('userback-feedback-view-scroller-loading');
            this.ui.feedback_view.find('.userback-feedback-view-scroller').html('');

            var search_string = this.ui.feedback_view.find('.userback-feedback-view-search-form input').val().trim();
            var feedback_type = this.ui.feedback_view.find('uboption.feedback-filter-type').filter('.active').data('val');
            var page          = this.ui.feedback_view.find('uboption.feedback-filter-page').hasClass('active') ? window.location.href.replace(window.location.search, '').replace(/\/$/, '') : '';

            if (feedback_type === 'all') {
                feedback_type = '';
            }

            $.ajax(Config.request_url + '/?loadWidgetFeedback', {
                data: {
                    action:       'widget/loadFeedback',
                    load_type:    Config.load_type,
                    access_token: Config.access_token,
                    widget_id:    this.configs.widget_id,
                    search:       search_string,
                    type:         feedback_type,
                    page:         page
                },
                crossDomain: true,
                dataType: 'json',
                type: 'POST',
                context: this,
                success: function(response) {
                    var feedback_html = '';
                    var result_count  = 0;

                    if (response) {
                        this.configs.feedback = response;

                        if (response.length) {
                            $.each(response, (index, feedback) => {
                                feedback_html += this.feedbackHTML(feedback);
                            });

                            result_count = response.length;
                        }
                    }

                    if (!result_count) {
                        feedback_html += '<div class="userback-feedback-view-empty">' + Svg_Icons.file_search + Lang.get('feedback_not_found') + '</div>';
                    }

                    this.ui.feedback_view.find('.userback-feedback-view-scroller').html(feedback_html);

                    this.ui.feedback_view.find('.userback-feedback-view-search').on('click', (e) => {
                        this.ui.feedback_view.find('.userback-feedback-view-search-form').show().find('input').focus();
                    });
                },
                complete: function() {
                    this.ui.feedback_view.find('.userback-feedback-view-scroller').removeClass('userback-feedback-view-scroller-loading');
                }
            });
        },

        removeFeedbackView: function() {
            if (!this.ui.feedback_view) {
                return;
            }

            this.ui.feedback_view.remove();
            this.ui.feedback_view = null;
        },

        isNextGen: function() {
            return this.configs.widget_version === 2;
        },

        hasBottomNav: function() {
            return this.isNextGen() && (this.configs.workflow_type.indexOf('portal') !== -1 || this.configs.workflow_type.indexOf('roadmap') !== -1 || this.configs.workflow_type.indexOf('announcement') !== -1 || this.configs.help_link);
        },

        // add the control panel
        addControls: function() {
            if (this.ui.controls) {
                return;
            }

            this.ui.controls = $('<div>').addClass('userback-controls userback-controls-' + this.configs.position);
            this.ui.controls.attr('wstyle', this.configs.style);
            this.ui.controls.attr('nextgen', this.isNextGen() ? 1 : 0);

            if (this.configs.display_powered_by) {
                this.ui.controls.attr('poweredby', 1);
            }

            if ((!this.isNextGen() && this.isAutoHide()) || this.configs.use_modal) {
                this.ui.controls.attr('modal', 'true');
            }

            // open form without launcher (via JS SDK)
            if (this.ui.button.is(':hidden')) {
                this.ui.controls.attr('nolauncher', 'true');
            }

            this.setControls();
            this.ui.controls.appendTo(this.ui.element);

            this.ui.controls.on('click',      '.userback-controls-back',              this.goBack.bind(this));
            this.ui.controls.on('click',      '.userback-controls-close',             this.closeWidget.bind(this));
            this.ui.controls.on('click',      '.userback-feedback-type-draw',         this.attachScreenshot.bind(this));
            this.ui.controls.on('click',      '.userback-feedback-type-video',        this.startVideo.bind(this));
            this.ui.controls.on('click',      '.userback-feedback-type-form',         this.menuItemClick.bind(this));
            this.ui.controls.on('click',      '.userback-feedback-type-help',         this.openHelp.bind(this));
            this.ui.controls.on('click',      '.userback-feedback-type-other',        this.viewOtherFeedback.bind(this));
            this.ui.controls.on('click',      '.userback-controls-add-attachment',    this.selectAttachment.bind(this));
            this.ui.controls.on('click',      '.userback-controls-remove-attachment', this.removeAttachment.bind(this));
            this.ui.controls.on('click',      'ubnav > ubdiv[data-val="feedback"]' ,  this.goToFeedback.bind(this));
            this.ui.controls.on('click',      'ubnav > ubdiv[data-val="portal"]' ,    this.goToPortal.bind(this));
            this.ui.controls.on('click',      'ubnav > ubdiv[data-val="roadmap"]',    this.goToRoadmap.bind(this));
            this.ui.controls.on('click',      'ubnav > ubdiv[data-val="announcement"]',    this.goToAnnouncement.bind(this));
            this.ui.controls.on('click',      'ubnav > ubdiv[data-val="help"]' ,      this.openHelp.bind(this));
            this.ui.controls.on('click',      'ubrating > ubdiv',                     this.selectRating.bind(this));
            this.ui.controls.on('mouseenter', 'ubrating > ubdiv',                     this.previewRating.bind(this));
            this.ui.controls.on('mouseleave', 'ubrating > ubdiv',                     this.unPreviewRating.bind(this));
            this.ui.controls.on('change',     '.userback-controls-attachment',        this.uploadAttachment.bind(this));
            this.ui.controls.on('submit',     '.userback-controls-options',           this.send.bind(this));
            this.ui.controls.on('change',     'select[name="category"]',              this.changeCategory.bind(this));
            this.ui.controls.on('change',     'select[name="priority"]',              this.changePriority.bind(this));
            this.ui.controls.on('change',     'select[name="assignee"]',              this.changeAssignee.bind(this));
            this.ui.controls.on('change',     'select',                               this.dropdownChange.bind(this));
            this.ui.controls.on('click',      '.userback-controls-video',             this.startVideo.bind(this));
            this.ui.controls.on('click',      '.userback-controls-screenshot',        this.attachScreenshot.bind(this));
            this.ui.controls.on('click',      '.userback-controls-add-screenshot',    this.attachScreenshot.bind(this));
            this.ui.controls.on('click',      '.userback-controls-remove-screenshot', this.removeScreenshot.bind(this));
            this.ui.controls.on('click',      '.userback-controls-video-thumbnail',   this.videoFullscreen.bind(this));
            this.ui.controls.on('click',      '.userback-controls-remove-video',      this.removeVideo.bind(this));
            this.ui.controls.on('click',      '.userback-checkbox-container > ubdiv', this.checkboxToggle.bind(this));

            this.ui.controls.on('keyup',  'textarea[name="description"]',    this.rememberFormData.bind(this));
            this.ui.controls.on('keyup',  'textarea[name="custom_field_1"]', this.rememberFormData.bind(this));
            this.ui.controls.on('keyup',  'input[name="name"]',              this.rememberFormData.bind(this));
            this.ui.controls.on('keyup',  'input[name="email"]',             this.rememberFormData.bind(this));
            this.ui.controls.on('keyup',  'input[name="title"]',             this.rememberFormData.bind(this));
            this.ui.controls.on('keyup',  'input[name="custom_field_1"]',    this.rememberFormData.bind(this));
            this.ui.controls.on('change', 'select[name="category"]',         this.rememberFormData.bind(this));
            this.ui.controls.on('change', 'select[name="priority"]',         this.rememberFormData.bind(this));
            this.ui.controls.on('change', 'select[name="assignee"]',         this.rememberFormData.bind(this));

            this.ui.controls.on('click mouseup mousedown', function(e) {
                e.stopPropagation();
            });
        },

        rememberFormData: function(e) {
            var field_name = $(e.currentTarget).attr('name');

            if (field_name && typeof this.form_data[field_name] !== 'undefined') {
                this.form_data[field_name] = $(e.currentTarget).val();
            }
        },

        // remove the control panel
        removeControls: function() {
            if (!this.ui.controls) {
                return;
            }

            this.ui.controls.remove();
            this.ui.controls = null;
        },

        setControls: function() {
            var routing_items = {};

            routing_items.screenshot =
            (this.configs.workflow_type.indexOf('capture') === -1 ? '' :
            '<ubroutemenu class="userback-feedback-type-draw">' +
                '<ubdiv>' + Svg_Icons.feedback_screenshot + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_screenshot) || Lang.get('draw_on_the_screen')) + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_screenshot_help) || Lang.get('draw_on_the_screen_help')) + '</ubdiv>' +
            '</ubroutemenu>');

            routing_items.video =
            (this.configs.workflow_type.indexOf('video') === -1 || !this.configs.has_video || (Lib.isMobile() || Lib.isTablet()) ? '' :
            '<ubroutemenu class="userback-feedback-type-video">' +
                '<ubdiv>' + Svg_Icons.feedback_video + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_video) || Lang.get('capture_video', true)) + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_video_help) || Lang.get('capture_video_help', true)) + '</ubdiv>' +
            '</ubroutemenu>');

            routing_items.bug =
            (this.configs.workflow_type.indexOf('bug') === -1 ? '' :
            '<ubroutemenu class="userback-feedback-type-form" data-type="bug">' +
                '<ubdiv>' + Svg_Icons.feedback_bug + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_report_bug) || Lang.get('report_bug', true)) + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_report_bug_help) || Lang.get('report_bug_help', true)) + '</ubdiv>' +
            '</ubroutemenu>');

            routing_items.feature_request =
            (this.configs.workflow_type.indexOf('feature_request') === -1 ? '' :
            '<ubroutemenu class="userback-feedback-type-form" data-type="feature_request">' +
                '<ubdiv>' + Svg_Icons.feedback_feature_request + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_feature_request) || Lang.get('feature_request', true)) + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_feature_request_help) || Lang.get('feature_request_help', true)) + '</ubdiv>' +
            '</ubroutemenu>');

            routing_items.general =
            (this.configs.workflow_type.indexOf('general') === -1 ? '' :
            '<ubroutemenu class="userback-feedback-type-form" data-type="general">' +
                '<ubdiv>' + Svg_Icons.feedback_general + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_general) || Lang.get('general_feedback')) + '</ubdiv>' +
                '<ubdiv>' + (Lib.htmlEntities(this.configs.text_general_help) || Lang.get('general_feedback_help')) + '</ubdiv>' +
            '</ubroutemenu>');

            routing_items.view_other =
            (this.configs.display_feedback ?
                '<ubroutemenu class="userback-feedback-type-other">' +
                    '<ubdiv>' + Svg_Icons.feedback_other + '</ubdiv>' +
                    '<ubdiv>' + (Lib.htmlEntities(this.configs.text_view_other) || Lang.get('view_other_feedback')) + '</ubdiv>' +
                    '<ubdiv>' + (Lib.htmlEntities(this.configs.text_view_other_help) || Lang.get('view_other_feedback_help', true)) + '</ubdiv>' +
                '</ubroutemenu>' : '');

            routing_items.help =
            (this.configs.help_link ?
                '<ubroutemenu class="userback-feedback-type-help">' +
                    '<ubdiv>' + Svg_Icons.feedback_help + '</ubdiv>' +
                    '<ubdiv>' + (this.configs.help_title   ? Lib.htmlEntities(this.configs.help_title)   : Lang.get('contact_us')) + '</ubdiv>' +
                    '<ubdiv>' + (this.configs.help_message ? Lib.htmlEntities(this.configs.help_message) : Lang.get('contact_us_help')) + '</ubdiv>' +
                '</ubroutemenu>' : '');

            // routing_item_order
            var item_order = this.configs.routing_item_order.split(',');
            var routing_item_options = '';

            $.each(routing_items, function(item_type, item) {
                if (item_order.indexOf(item_type) === -1) {
                    routing_item_options += item;
                }
            });

            item_order.forEach(function(item_type) {
                if (typeof routing_items[item_type]) {
                    routing_item_options += (routing_items[item_type] || '');
                }
            });

            var html = '';

            // routing screen
            if (!this.isNextGen()) {
                var has_header = this.configs.logo || this.configs.header_text;

                html += '<ubdiv class="userback-controls-step" data-step="1">' +
                            '<uclosel class="userback-controls-close" title="' + Lang.get('close') + '">' + Svg_Icons.xmark_r + '</uclosel>' +
                            '<ubroute>' +
                                (this.configs.logo ? '<img class="userback-controls-logo" src="' + Lib.htmlEntities(this.configs.logo) + '">' : '') +
                                (this.configs.header_text ? '<ubdiv class="userback-header-text">' + Lib.htmlEntities(this.configs.header_text) + '</ubdiv>' : '') +
                                routing_item_options +
                            '</ubroute>' +
                        '</ubdiv>';
            }

            // feedback form
            html += '<ubdiv class="userback-controls-step" data-step="2"></ubdiv>';

            if (this.hasBottomNav()) {
                html += '<ubdiv class="userback-footer-nav">';
            }

            if (this.hasBottomNav()) {
                var local_announcement_date = Lib.storage.get('portal_announcement_date') || 0;
                var badge = !!this.configs.portal_announcement_date && this.configs.portal_announcement_date > local_announcement_date;

                html +=
                '<ubnav' + (this.configs.display_powered_by? ' data-poweredby="1"' : '') + '>' +
                    '<ubdiv data-val="feedback" data-active="1">' +
                        '<ubicon>' + Svg_Icons.message_lines_light + '</ubicon>' +
                        '<ubicon>' + Svg_Icons.message_lines_solid + '</ubicon>' +
                        (Lib.htmlEntities(this.configs.text_feedback) || Lang.get('feedback', true)) +
                    '</ubdiv>' +
                    (this.configs.workflow_type.indexOf('portal') !== -1 ?
                    '<ubdiv data-val="portal">' +
                        '<ubicon>' + Svg_Icons.lightbulb_on_light + '</ubicon>' +
                        '<ubicon>' + Svg_Icons.lightbulb_on_solid + '</ubicon>' +
                        (Lib.htmlEntities(this.configs.text_portal) || Lang.get('feature_request', true)) +
                    '</ubdiv>' : '') +
                    (this.configs.workflow_type.indexOf('roadmap') !== -1 ?
                    '<ubdiv data-val="roadmap">' +
                        '<ubicon>' + Svg_Icons.chart_mixed_light + '</ubicon>' +
                        '<ubicon>' + Svg_Icons.chart_mixed_solid + '</ubicon>' +
                        (Lib.htmlEntities(this.configs.text_roadmap) || Lang.get('roadmap', true)) +
                    '</ubdiv>' : '') +
                    (this.configs.workflow_type.indexOf('announcement') !== -1 ?
                    '<ubdiv data-val="announcement">' +
                        '<ubdiv>' +
                            '<ubicon>' + Svg_Icons.bullhorn_light + '</ubicon>' +
                            '<ubicon>' + Svg_Icons.bullhorn_solid + '</ubicon>' +
                            (badge ? '<ubdiv></ubdiv>' : '') +
                        '</ubdiv>' +
                        (Lib.htmlEntities(this.configs.text_announcement) || Lang.get('announcement', true)) +
                    '</ubdiv>' : '') +
                    (this.configs.help_link ?
                    '<ubdiv data-val="help">' +
                        '<ubicon>' + Svg_Icons.circle_question_light + '</ubicon>' +
                        '<ubicon>' + Svg_Icons.circle_question_solid + '</ubicon>' +
                        (this.configs.help_title   ? Lib.htmlEntities(this.configs.help_title)   : Lang.get('contact_us')) +
                    '</ubdiv>' : '') +
                '</ubnav>';
            }

            if (this.configs.display_powered_by) {
                html += '<ubfooter>' +
                            '<a href="https://www.userback.io?utm_source=application&utm_medium=feedback_widget&utm_campaign=powered_by">' +
                                '<span>Powered by</span>' +
                                Svg_Icons.logo +
                            '</a>' +
                        '</ubfooter>';
            }

            if (this.hasBottomNav()) {
                html += '</ubdiv>';
            }

            this.ui.controls.html(html);
            this.setFormHTML();

            var has_help     = this.configs.help_link ? true : false;
            var has_one_type = (this.configs.workflow_type === 'capture' ||
                                this.configs.workflow_type === 'video' ||
                                this.configs.workflow_type === 'general'  ||
                                this.configs.workflow_type === 'bug' ||
                                this.configs.workflow_type === 'feature_request') &&
                                !this.configs.display_feedback;

            if ((has_one_type && !has_help) || this.isNextGen()) {
                // skip the first step when a single workflow type is selected
                this.ui.controls.find('.userback-controls-step[data-step="1"]').hide();
                this.ui.controls.find('.userback-controls-step[data-step="2"]').show();
                this.ui.controls.attr('data-step', '2');
            } else {
                this.ui.controls.find('.userback-controls-step[data-step="1"]').show();
                this.ui.controls.find('.userback-controls-step[data-step="2"]').hide();
                this.ui.controls.attr('data-step', '1');
            }
        },

        addScreenshotBadge: function() {
            if (this.video_url) {
                this.ui.controls.find('.userback-controls-video-preview').show();
                this.ui.controls.find('.userback-controls-screenshot, .userback-controls-video').attr('disabled', 'disabled');
                this.ui.controls.find('.userback-controls-video .userback-screenshot-preview-checkmark').show();

                this.ui.controls.find('.userback-controls-screenshot-preview').hide();
            } else {
                // next gen submits screenshot and feedback together
                if (this.isNextGen()) {
                    return;
                }

                this.ui.controls.find('.userback-controls-screenshot-preview').show();
                this.ui.controls.find('.userback-controls-screenshot, .userback-controls-video').attr('disabled', 'disabled');
                this.ui.controls.find('.userback-controls-screenshot .userback-screenshot-preview-checkmark').show();

                this.ui.controls.find('.userback-controls-video-preview').hide();

                this.ui.controls.find('.userback-controls-screenshot-counter').text(this.screenshot_data_uri.length);

                if (Userback.screen_capture || this.configs.native_screenshot) {
                    this.ui.controls.find('.userback-screenshot-preview img').remove();
                    $(this.screenshot_data_uri).each((index, screenshot_data) => {
                        var preview_img = $('<img>').addClass('screenshot-preview').attr('src', screenshot_data.data_uri);
                        this.ui.controls.find('.userback-screenshot-preview').append(preview_img);
                    });
                }
            }

            this.toggleAttachButtons();
        },

        isNativeScreenshotSupported: function() {
            return !!navigator.mediaDevices?.getDisplayMedia;
        },

        setFormHTML: function() {
            var form_settings = this.getFormSettings(this.form_type);

            var display_screenshot = form_settings.allow_screenshot;
            var display_video      = form_settings.allow_video && this.configs.has_video && !Lib.isMobile() && !Lib.isTablet();
            var display_attachment = form_settings.allow_attachment && typeof FileReader !== 'undefined';

            var data_item = 0;

            data_item += display_screenshot ? 1 : 0;
            data_item += display_video      ? 1 : 0;
            data_item += display_attachment ? 1 : 0;

            // From User Data or JS API
            var default_name_integrated  = Userback.name  || Feedback.name;
            var default_email_integrated = Userback.email || Feedback.email;

            var default_name           = this.form_data.name           || default_name_integrated  || Lib.storage.get('ubw_name');
            var default_email          = this.form_data.email          || default_email_integrated || Lib.storage.get('ubw_email');
            var default_title          = this.form_data.title          || '';
            var default_description    = this.form_data.description    || '';
            var default_category       = this.form_data.category       || Userback.categories || Feedback.categories || '';
            var default_priority       = this.form_data.priority       || Userback.priority   || Feedback.priority   || '';
            var default_assignee       = this.form_data.assignee       || '';
            var default_rating         = this.form_data.rating         || '';
            var default_custom_field_1 = this.form_data.custom_field_1 || '';

            var priority_options = '<option value="urgent">Urgent</option>' +
                                    '<option value="high">High</option>' +
                                    '<option value="neutral">Neutral</option>' +
                                    '<option value="low">Low</option>';

            var category_options = '';
            if (form_settings.display_category) {
                this.configs.categories.forEach((category) => {
                    category_options += '<option>' + category + '</option>';
                });
            }

            var assignee_options = '';
            if (form_settings.display_assignee) {
                this.configs.assignee.forEach(function(data) {
                    assignee_options += '<option value="' + Lib.htmlEntities(data.id) + '">' + Lib.htmlEntities(data.name) + '</option>';
                });
            }

            var rating_icons = '';

            if (form_settings.rating_type === 'emoji') {
                rating_icons += '<ubdiv data-rating="hate" class="inactive">' + Svg_Icons.face_hate + '</ubdiv>' +
                                '<ubdiv data-rating="dislike" class="inactive">' + Svg_Icons.face_dislike + '</ubdiv>' +
                                '<ubdiv data-rating="neutral" class="inactive">' + Svg_Icons.face_neutral + '</ubdiv>' +
                                '<ubdiv data-rating="like" class="inactive">' + Svg_Icons.face_like + '</ubdiv>' +
                                '<ubdiv data-rating="love" class="inactive">' + Svg_Icons.face_love + '</ubdiv>';
            } else if (form_settings.rating_type === 'star') {
                rating_icons += '<ubdiv data-rating="star_1" class="inactive">' + Svg_Icons.star + '</ubdiv>' +
                                '<ubdiv data-rating="star_2" class="inactive">' + Svg_Icons.star + '</ubdiv>' +
                                '<ubdiv data-rating="star_3" class="inactive">' + Svg_Icons.star + '</ubdiv>' +
                                '<ubdiv data-rating="star_4" class="inactive">' + Svg_Icons.star + '</ubdiv>' +
                                '<ubdiv data-rating="star_5" class="inactive">' + Svg_Icons.star + '</ubdiv>';
            } else if (form_settings.rating_type === 'heart') {
                rating_icons += '<ubdiv data-rating="heart_1" class="inactive">' + Svg_Icons.heart + '</ubdiv>' +
                                '<ubdiv data-rating="heart_2" class="inactive">' + Svg_Icons.heart + '</ubdiv>' +
                                '<ubdiv data-rating="heart_3" class="inactive">' + Svg_Icons.heart + '</ubdiv>' +
                                '<ubdiv data-rating="heart_4" class="inactive">' + Svg_Icons.heart + '</ubdiv>' +
                                '<ubdiv data-rating="heart_5" class="inactive">' + Svg_Icons.heart + '</ubdiv>';
            } else if (form_settings.rating_type === 'number') {
                rating_icons += '<ubdiv data-rating="1" class="inactive">1</ubdiv>' +
                                '<ubdiv data-rating="2" class="inactive">2</ubdiv>' +
                                '<ubdiv data-rating="3" class="inactive">3</ubdiv>' +
                                '<ubdiv data-rating="4" class="inactive">4</ubdiv>' +
                                '<ubdiv data-rating="5" class="inactive">5</ubdiv>';
            } else if (form_settings.rating_type === 'thumb') {
                rating_icons += '<ubdiv data-rating="thumb_up" class="inactive">' + Svg_Icons.thumb_up + '</ubdiv>' +
                                '<ubdiv data-rating="thumb_down" class="inactive">' + Svg_Icons.thumb_down + '</ubdiv>';
            }

            var custom_field_1 =
            (form_settings.custom_field_1_type === 'short_answer' ?
            '<input name="custom_field_1" type="text" placeholder="' + Lib.htmlEntities(form_settings.custom_field_1_label) + '" name=""' + (form_settings.custom_field_1_mandatory ? ' required' : '') + '>' : '') +

            (form_settings.custom_field_1_type === 'long_answer' ?
            '<textarea name="custom_field_1" placeholder="' + Lib.htmlEntities(form_settings.custom_field_1_label) + '" name=""' + (form_settings.custom_field_1_mandatory ? ' required' : '') + '></textarea>' : '') +

            (form_settings.custom_field_1_type === 'disclaimer' && form_settings.custom_field_1_label ?
            '<ubdiv class="userback-disclaimer">' + Lib.htmlEntitiesWithA(form_settings.custom_field_1_label, true) + '</ubdiv>' : '') +

            (form_settings.custom_field_1_type === 'checkbox' ?
            '<ubdiv class="userback-checkbox-container">' +
                '<input name="custom_field_1" type="checkbox"' + (form_settings.custom_field_1_mandatory ? ' required' : '') + '>' +
                '<ubdiv class="userback-checkbox">' + Svg_Icons.check + '</ubdiv>' +
                '<ubdiv class="userback-checkbox-label">' + Lib.htmlEntitiesWithA(form_settings.custom_field_1_label, true) + '</ubdiv>' +
            '</ubdiv>': '');

            var feedback_type_select = '';
            var has_single_feedback_type = this.configs.feedback_type.split(',').length === 1;

            if (this.isNextGen()) {
                feedback_type_select =
                '<ubdiv' + (has_single_feedback_type || this.configs.ai_feedback_type ? ' style="display:none;"' : '') + '>' +
                    '<select name="feedback_type"' + (has_single_feedback_type || this.configs.ai_feedback_type ? '' : ' required') + '>' +
                        (this.configs.feedback_type_default === '' ?
                            '<option value="" selected>' + Lang.get('select_empty', true) + '</option>' : '') +
                        [
                            { val: 'bug', lang: 'feedback_type_bug', setting: 'text_feedback_type_bug' },
                            { val: 'feature_request', lang: 'feedback_type_feature_request', setting: 'text_feedback_type_feature_request' },
                            { val: 'general', lang: 'feedback_type_general', setting: 'text_feedback_type_general' }
                        ].map((data) => {
                            if (this.configs.feedback_type.split(',').indexOf(data.val) === -1) {
                                return '';
                            } else {
                                return '<option value="' + data.val + '"' + (this.configs.feedback_type_default === data.val ? ' selected' : '') + '>' + (Lib.htmlEntities(this.configs[data.setting]) || Lang.get(data.lang, true)) + '</option>';
                            }
                        }).join('') +
                    '</select>' +
                '</ubdiv>';
            }

            var form_header = '';

            var field_items = {
                name           : form_settings.name_field       ? '<input type="text" name="name" maxlength="100" placeholder="' + (Lib.htmlEntities(form_settings.name_field_placeholder) || Lang.get('your_name')) + '"' + (form_settings.name_field_mandatory ? ' required' : '') + '>' : '',
                email          : form_settings.email_field      ? '<input type="email" name="email" maxlength="200" placeholder="' + (Lib.htmlEntities(form_settings.email_field_placeholder) || Lang.get('your_email_address')) + '"' + (form_settings.email_field_mandatory ? ' required' : '') + '>' : '',
                title          : form_settings.title_field      ? '<input type="text" name="title" maxlength="80" data-gramm_editor="false" placeholder="' + (Lib.htmlEntities(form_settings.title_field_placeholder) || Lang.get('feedback_title', true)) + '"' + (form_settings.title_field_mandatory ? ' required' : '') + '>' : '',
                comment        : form_settings.comment_field    ? (this.isNextGen() ?
                                                                  // next gen comment field
                                                                  '<ubdiv class="userback-field-comment">' +
                                                                  '<textarea name="description" data-gramm_editor="false" placeholder="' + (Lib.htmlEntities(form_settings.comment_field_placeholder) || Lang.get('leave_us_your_comment')) + '"' + (form_settings.comment_field_mandatory || this.isNextGen() ? ' required' : '') + '></textarea>' +
                                                                  feedback_type_select +
                                                                  '</ubdiv>' :

                                                                  // old comment field
                                                                  '<textarea name="description" data-gramm_editor="false" placeholder="' + (Lib.htmlEntities(form_settings.comment_field_placeholder) || Lang.get('leave_us_your_comment')) + '"' + (form_settings.comment_field_mandatory || this.isNextGen() ? ' required' : '') + '></textarea>') : '',
                category       : form_settings.display_category ? '<select name="category" data-empty="true"' + (form_settings.category_field_mandatory ? ' required' : '') + '><option value="">' + (Lib.htmlEntities(form_settings.category_field_placeholder) || Lang.get('select_a_category')) + '</option>' + category_options + '</select>' : '',
                priority       : form_settings.display_priority ? '<select name="priority" data-empty="true"' + (form_settings.priority_field_mandatory ? ' required' : '') + '><option value="">' + (Lib.htmlEntities(form_settings.priority_field_placeholder) || Lang.get('select_a_priority')) + '</option>' + priority_options + '</select>' : '',
                assignee       : form_settings.display_assignee ? '<select name="assignee" data-empty="true"' + (form_settings.assignee_field_mandatory ? ' required' : '') + '><option value="">' + (Lib.htmlEntities(form_settings.assignee_field_placeholder) || Lang.get('assign_to')) + '</option>' + assignee_options + '</select>' : '',
                custom_field_1 : custom_field_1
            };

            var field_order = form_settings.field_order.split(',');
            var field_options = '';

            field_order.forEach((field_name) => {
                if (typeof field_items[field_name] !== 'undefined') {
                    field_options += field_items[field_name];
                }
            });

            var html = '';

            switch (this.form_type) {
                case 'bug':
                    form_header = (Lib.htmlEntities(this.configs.text_report_bug) || Lang.get('report_bug', true));
                    break;
                case 'general':
                    form_header = (Lib.htmlEntities(this.configs.text_general) || Lang.get('general_feedback', true));
                    break;
                case 'feature_request':
                    form_header = (Lib.htmlEntities(this.configs.text_feature_request) || Lang.get('feature_request', true));
                    break;
            }

            if (this.isNextGen()) {
                form_header = Lib.htmlEntities(User_Identification.wildcard(this.configs.header_text)) || Lang.get('welcome_message', true);
            }

            html += '<ubtab data-val="form">';
            html += '<ubdiv>';
            html += '<ubdiv class="userback-controls-header">' +
                        (this.isNextGen() ? '' :
                        '<ubdiv class="userback-controls-back disabled">' + Svg_Icons.chevron_left_r + '</ubdiv>')+
                        '<ubdiv class="userback-controls-header-text">' +
                            (this.isNextGen() ?
                            (
                                (this.configs.logo ? '<img src="' + Lib.htmlEntities(this.configs.logo) + '">' : '') ||
                                this.getButtonIcon(true) ||
                                Svg_Icons.widget_pointer
                            ) : '') +
                            form_header +
                        '</ubdiv>' +
                        '<ubdiv class="userback-controls-close">' + Svg_Icons.xmark_r + '</ubdiv>' +
                    '</ubdiv>';

            html += '<form class="userback-controls-options">' +
                        (form_settings.rating_help_message ?
                        '<div class="userback-controls-help-message">' + Lib.htmlEntities(form_settings.rating_help_message) + '</div>' : '') +
                        (form_settings.rating_type ?
                        '<ubrating>' +
                            (form_settings.rating_mandatory ? '<input type="text" class="rating-required" value="" required>' : '') +
                            rating_icons +
                        '</ubrating>' : '') +

                        '<ubdiv class="userback-controls-form"' + (form_settings.rating_type && form_settings.rating_mandatory && !this.isNextGen() ? ' style="display:none;"' : '') + '>' +
                            field_options +

                            (display_screenshot || display_video || display_attachment ?
                            '<ubdiv class="userback-controls-attach-actions" data-item="' + data_item + '">' +
                                '<ubdiv>' +
                                    (display_screenshot ?
                                    '<btn class="userback-controls-screenshot">' +
                                        (this.isNextGen() ? Svg_Icons.camera : Svg_Icons.draw_square_solid) +
                                        '<ubdiv>' + Lang.get('attach_a_screenshot') + '</ubdiv>' +
                                        '<utooltip>' + Lang.get('attach_a_screenshot') + '</utooltip>' +
                                        '<div class="userback-screenshot-preview-checkmark">' + Svg_Icons.checkmark + '</div>' +
                                    '</btn>' : '') +

                                    (display_video ?
                                    '<btn class="userback-controls-video">' +
                                        Svg_Icons.video_solid +
                                        '<ubdiv>' + Lang.get('capture_video') + '</ubdiv>' +
                                        '<utooltip>' + Lang.get('capture_video') + '</utooltip>' +
                                        '<div class="userback-screenshot-preview-checkmark">' + Svg_Icons.checkmark + '</div>' +
                                    '</btn>' : '') +

                                    (display_attachment ?
                                    '<btn class="userback-controls-add-attachment">' +
                                        Svg_Icons.paperclip_regular +
                                        '<ubdiv>' + Lang.get('attach_a_file') + '</ubdiv>' +
                                        '<utooltip>' + Lang.get('attach_a_file') + '</utooltip>' +
                                        '<div class="userback-screenshot-preview-checkmark">' + Svg_Icons.checkmark + '</div>' +
                                    '</btn>' : '') +
                                '</ubdiv>' +
                                '<input type="file" class="userback-controls-attachment">' +
                            '</ubdiv>' : '') +

                            // next gen does not support multiple screenshots
                            (this.isNextGen() ? '' :
                            '<ubdiv class="userback-controls-screenshot-preview">' +
                                '<btn class="userback-controls-remove-screenshot">' + Svg_Icons.circle_xmark_s + '</btn>' +
                                '<btn class="userback-controls-add-screenshot">' + Svg_Icons.plus_s + '</btn>' +
                                '<ubidv class="userback-controls-screenshot-thumbnail">' +
                                    Svg_Icons.draw_square_solid +
                                    '<span>' + Lang.get('screenshot_attached') + '</span>' +
                                    '<div class="userback-screenshot-preview"></div>' +
                                '</ubidv>' +
                                '<div class="userback-controls-screenshot-counter">' + this.screenshot_data_uri.length + '</div>' +
                            '</ubdiv>') +

                            '<ubdiv class="userback-controls-video-preview">' +
                                '<btn class="userback-controls-remove-video">' + Svg_Icons.trash + '</btn>' +
                                '<ubidv class="userback-controls-video-thumbnail">' +
                                    Svg_Icons.video_solid +
                                    '<span>' + Lang.get('screen_recording') + '</span>' +
                                '</ubidv>' +
                            '</ubdiv>' +

                            '<ubdiv class="userback-controls-attachment-name">' +
                                '<ubdiv class="userback-controls-attachment-thumbnail">' +
                                    Svg_Icons.paperclip_regular +
                                    '<span class="userback-attachment-filename"></span>' +
                                '</ubdiv>' +
                                '<btn class="userback-controls-remove-attachment">' + Svg_Icons.trash + '</btn>' +
                            '</ubdiv>' +

                            (Feedback.configs.hcaptcha_sitekey ?
                            '<ubdiv class="userback-controls-captcha">' +
                                '<ub-captcha id="userback-captcha" site-key="' + Feedback.configs.hcaptcha_sitekey + '" size="normal" tabindex="0"></ub-captcha>' +
                            '</ubdiv>' : '') +

                            '<ubdiv class="userback-controls-send-container">' +
                                '<button class="userback-controls-send userback-button-input">' +
                                    '<span class="userback-controls-send-progress"></span>' +
                                    '<span class="userback-controls-send-text">' + (Lib.htmlEntities(form_settings.send_button_text) || Lang.get('send')) + '</span>' +
                                '</button>' +
                            '</ubdiv>' +
                        '</ubdiv>' +
                    '</form>';
            html += '</ubdiv>';
            html += '</ubtab>';

            if (
                (this.configs.workflow_type.indexOf('portal') !== -1 && this.configs.portal_target === 'widget') ||
                (this.configs.workflow_type.indexOf('roadmap') !== -1 && this.configs.roadmap_target === 'widget') ||
                (this.configs.workflow_type.indexOf('announcement') !== -1 && this.configs.announcement_target === 'widget')
            ) {
                html +=
                '<ubtab data-val="portal" class="ubtab-hidden">' +
                    '<ubdiv class="userback-portal-header">' +
                        '<ubdiv class="userback-portal-header-text"></ubdiv>' +
                    '</ubdiv>' +
                '</ubtab>';
            }

            this.ui.controls.find('.userback-controls-step[data-step="2"]').html(html);

            // TODO: fix me
            if (default_rating) {
                this.ui.controls.find('ubrating ubdiv[data-rating="' + default_rating + '"]').click();
            }

            if (default_name) {
                this.ui.controls.find('.userback-controls-step[data-step="2"]').find('input[name="name"]').val(default_name);

                if (this.isNextGen() && default_name_integrated) {
                    this.ui.controls.find('.userback-controls-step[data-step="2"]').find('input[name="name"]').hide();
                }
            }

            if (default_email) {
                this.ui.controls.find('.userback-controls-step[data-step="2"]').find('input[name="email"]').val(default_email);

                if (this.isNextGen() && default_email_integrated) {
                    this.ui.controls.find('.userback-controls-step[data-step="2"]').find('input[name="email"]').hide();
                }
            }

            if (default_title) {
                this.ui.controls.find('.userback-controls-step[data-step="2"]').find('input[name="title"]').val(default_title);
            }

            if (default_description) {
                this.ui.controls.find('.userback-controls-step[data-step="2"]').find('textarea[name="description"]').val(default_description);
            }

            if (default_category) {
                this.ui.controls.find('.userback-controls-step[data-step="2"]').find('select[name="category"]').val(default_category);
            }

            if (default_priority) {
                this.ui.controls.find('.userback-controls-step[data-step="2"]').find('select[name="priority"]').val(default_priority);
            }

            if (default_assignee) {
                this.ui.controls.find('.userback-controls-step[data-step="2"]').find('select[name="assignee"]').val(default_assignee);
            }

            if (this.configs.hcaptcha_sitekey) {
                this.ui.controls.find('#userback-captcha')[0].addEventListener('verified', (e) => { this.hcaptcha_token = e.token; });
            }

            if (form_settings.custom_field_1_type && default_custom_field_1) {
                switch (form_settings.custom_field_1_type) {
                    case 'short_answer':
                        this.ui.controls.find('.userback-controls-step[data-step="2"]').find('input[name="custom_field_1"]').val(default_custom_field_1);
                        break;
                    case 'long_answer':
                        this.ui.controls.find('.userback-controls-step[data-step="2"]').find('textarea[name="custom_field_1"]').val(default_custom_field_1);
                        break;
                    case 'checkbox':
                        this.ui.controls.find('.userback-controls-step[data-step="2"]').find('input[name="custom_field_1"]').prop('checked', default_custom_field_1);
                        break;
                }
            }
        },

        menuItemClick: function(e) {
            if (Lib.isIos()) {
                this.page_scroll_top = document.documentElement.scrollTop;
            }

            this.form_type = $(e.currentTarget).data('type');

            var direct_to = 'form';

            if (this.form_type === 'bug') {
                direct_to = this.configs.direct_to_bug;
            } else if (this.form_type === 'feature_request') {
                direct_to = this.configs.direct_to_feature_request;
            } else {
                direct_to = this.configs.direct_to_general;
            }

            if (direct_to === 'screenshot') {
                this.direct_from = 'routing';

                this.attachScreenshot();
            } else if (direct_to === 'video') {
                this.direct_from = 'routing';

                this.startVideo();
            } else if (direct_to === 'portal') {
                this.goToPortal();
            } else {
                this.openForm(this.form_type, true);
            }
        },

        getPortalUrl: function(embed, component_name) {
            var session_info;
            if (Feedback.name && Feedback.email) {
                session_info = btoa(JSON.stringify({
                    email: Feedback.email,
                    name : Feedback.name
                }));
            }

            var portal_url = this.configs.portal_url;

            if (!portal_url) {
                return '';
            }

            if (component_name === 'roadmap') {
                portal_url += '/roadmap';
            }

            if (component_name === 'announcement') {
                portal_url += '/announcement';
            }

            var params = [];

            if (embed) {
                params.push('embed=widget');
            }

            if (session_info) {
                if (embed) {
                    params.push('gl=' + encodeURIComponent(session_info));
                } else {
                    params.push('sso_gl=' + encodeURIComponent(session_info));
                }
            }

            return portal_url + (params.length ? '?' + params.join('&') : '');
        },

        // when people select to open form
        openForm: function(form_type, show_back_button) {
            if (this.isNextGen()) {
                form_type = 'general';
            }

            show_back_button = !!show_back_button;

            this.form_type = form_type;

            this.setFormHTML();
            this.ui.controls.find('.userback-controls-step[data-step="1"]').hide();
            this.ui.controls.find('.userback-controls-step[data-step="2"]').show();
            this.ui.controls.find('.userback-controls-back').toggleClass('disabled', !show_back_button);

            this.ui.controls.attr('data-step', '2');

            this.focusControls();

            this.controls_step = 2;

            if (this.isNextGen() && form_type) {
                this.ui.controls.find('select[name="feedback_type"]').val(form_type);
            }
        },

        goToFeedback: function() {
            if (this.ui.controls.find('ubnav ubdiv[data-val="feedback"]').attr('data-active')) {
                return;
            }

            this.ui.controls
                .find('ubnav ubdiv[data-val="feedback"]').attr('data-active', '1')
                .siblings().removeAttr('data-active');

            // show the tab
            this.ui.controls.find('ubtab[data-val="form"]')
                .height(this.ui.controls.find('ubtab[data-val="portal"]').height())
                .removeClass('ubtab-hidden')
                .siblings().addClass('ubtab-hidden');

            // reset height just in case the measurement above is wrong
            $(this.ui.controls.find('ubtab[data-val="form"]')).one('transitionend', () => {
                this.ui.controls.find('ubtab[data-val="form"]').height('');
            });

            // animate height
            this.ui.controls.find('ubtab[data-val="form"]')
                .height(this.ui.controls.find('ubtab[data-val="form"] > ubdiv').height());
        },

        goToPortal: function() {
            if (this.configs.portal_target === 'redirect') {
                window.location.href = this.getPortalUrl(false);
            } else if (this.configs.portal_target === 'window') {
                window.open(this.getPortalUrl(false), '_blank');
            } else if (this.configs.portal_target === 'widget') {
                this.openPortal('portal');
            }
        },

        goToRoadmap: function() {
            if (this.configs.roadmap_target === 'redirect') {
                window.location.href = this.getPortalUrl(false, 'roadmap');
            } else if (this.configs.roadmap_target === 'window') {
                window.open(this.getPortalUrl(false, 'roadmap'), '_blank');
            } else if (this.configs.roadmap_target === 'widget') {
                this.openPortal('roadmap');
            }
        },

        goToAnnouncement: function(e) {
            Lib.storage.set('portal_announcement_date', this.configs.portal_announcement_date);
            $(e.currentTarget).find('ubdiv ubdiv').hide();
            this.ui.button.attr('badge', '');
            if (this.ui.dot) {
                this.ui.dot.remove();
            }

            if (this.configs.announcement_target === 'redirect') {
                window.location.href = this.getPortalUrl(false, 'announcement');
            } else if (this.configs.announcement_target === 'window') {
                window.open(this.getPortalUrl(false, 'announcement'), '_blank');
            } else if (this.configs.announcement_target === 'widget') {
                this.openPortal('announcement');
            }
        },

        // open custom help link
        openHelp: function() {
            if (this.configs.help_link.toLowerCase().indexOf('javascript:') === 0) {
                eval(this.configs.help_link.replace('javascript:', ''));
                Feedback.reset();
            } else if (this.configs.help_target === 'window') {
                window.open(this.configs.help_link, '_blank');
            } else {
                // redirect
                window.location.href = this.configs.help_link;
            }
        },

        // embed portal in widget
        openPortal: function(component) {
            if (!this.isNextGen()) {
                // should not happen because the embed version is not in the old widget
                return;
            }

            // open portal when widget form is not added yet (happens via JS SDK)
            if (!this.ui.controls) {
                this.openControls();
            }

            var ubtab_menu = this.ui.controls.find('ubnav ubdiv[data-val="' + component + '"]');
            var ubtab = this.ui.controls.find('ubtab[data-val="portal"]');

            if (ubtab_menu.attr('data-active')) {
                return;
            }

            var header_text = '';

            if (component === 'roadmap') {
                header_text = this.configs.text_roadmap || Lang.get('roadmap', true);
            } else if (component === 'announcement') {
                header_text = this.configs.text_announcement || Lang.get('announcement', true);
            } else if (component === 'portal') {
                header_text = this.configs.text_portal || Lang.get('feature_request', true);
            }

            // set active tab
            ubtab_menu
                .attr('data-active', '1')
                .siblings().removeAttr('data-active');

            // show portal tab with animation
            ubtab
                .height(this.ui.controls.find('ubtab[data-val="form"]').height())
                .removeClass('ubtab-hidden')
                .siblings().addClass('ubtab-hidden');

            // set header text
            this.ui.controls.find('.userback-portal-header-text').text(header_text);

            // reset height with animation
            ubtab.height('');

            // load portal iframe if not already loaded
            if (!ubtab.find('iframe').length) {
                var iframe = $('<iframe src="' + this.getPortalUrl(true, component) + '" allowfullscreen>');

                this.nav_component_loading = component;

                iframe.on('load', () => {
                    ubtab.removeAttr('data-loading');
                    delete this.nav_component_loading;
                });

                ubtab.attr('data-loading', 'true').append(iframe);
            } else if (this.nav_component_loading && this.nav_component_loading !== component) {
                ubtab.find('iframe').attr('src', this.getPortalUrl(true, component));
                this.nav_component_loading = component;
            } else{
                ubtab.find('iframe').get(0).contentWindow.postMessage({
                    action: 'navigate',
                    component: component
                }, '*');
            }
        },

        // when attach screnshot link/button is clicked
        attachScreenshot: function(e) {
            if (typeof e !== 'undefined' && $(e.currentTarget).attr('disabled')) {
                return;
            }

            if (Lib.isIos()) {
                document.documentElement.scrollTop = this.page_scroll_top;
            }

            const is_native_screenshot_supported = this.isNativeScreenshotSupported();
            const fallback_to_native = is_native_screenshot_supported && !this.configs.is_live

            // auto fallback to native screenshot when is_live is false
            if (this.configs.native_screenshot || fallback_to_native) {
                if (window.location.protocol === 'http:') {
                    Modal.modalConfirm('<div class="userback-c-title">HTTPS required</div><div class="userback-modal-info-text">A secure HTTPS connection is required for native browser screenshot.</div>', function() {
                        Modal.modalClose();
                    }, Lang.get('ok', true));

                    return;
                }

                // hide widget
                this.hideControls();

                Screenshot_Capture.start({
                    onSubmit: (image_data_uri) => {
                        this.addNativeScreenshot(image_data_uri);

                        if (this.isNextGen()) {
                            this.showControls(true);
                        }
                    },
                    onCancel: () => {
                        this.Tools.cancelRoutingOption();

                        if (this.isNextGen()) {
                            this.showControls(true);
                        }
                    }
                });
            } else {
                if (this.screenshot_data_uri.length < this.screenshot_limit) {
                    // Check if the browser is Safari and the device is iOS
                    if (Lib.isSafari() && Lib.isIos()) {
                        // Use setTimeout to ensure the overlay appears after the keyboard is dismissed in iOS Safari，
                        // to avoid incorrect annotate Y-axis coordinates.
                        setTimeout(() => {
                            this.addOverlay();
                        }, 100);
                    } else {
                        this.addOverlay();
                    }
                }
            }
        },

        startVideo: function(e) {
            if (typeof e !== 'undefined' && $(e.currentTarget).attr('disabled')) {
                return;
            }

            if (window.location.protocol === 'http:') {
                Modal.modalConfirm('<div class="userback-modal-title">' + Lang.get('video_not_supported', true) + '</div><div class="userback-modal-info-text">' + Lang.get('video_requires_https', true) + '</div>', function() {
                    Modal.modalClose();
                }, Lang.get('ok', true));

                return;
            }

            // cancel screenshot tools
            this.Tools.cancelAnnotation();

            this.hideControls();

            Video_Capture.start({
                onSubmit : this.Tools.submitVideoCapture,
                onCancel : this.Tools.cancelVideoCapture.bind(this.Tools),
                timer    : this.configs.video_time
            });
        },

        toggleAttachButtons: function() {
            this.ui.controls.find('.userback-controls-attach-actions').toggle(this.ui.controls.find('.userback-controls-attach-actions btn[disabled]').length !== this.ui.controls.find('.userback-controls-attach-actions btn').length);
        },

        checkScreenshotLimit: function() {
            if (this.screenshot_data_uri.length < this.screenshot_limit) {
                this.ui.controls.find('.userback-controls-add-screenshot').removeAttr('disabled');
            } else {
                this.ui.controls.find('.userback-controls-add-screenshot').attr('disabled', 'disabled');
            }
        },

        videoFullscreen: function(e, video_url) {
            video_url = video_url || this.video_url;

            var video_overlay = $('<div id="userback_video_player"><div></div></div>');
            var video         = $('<video preload="auto" controls src="' + video_url + '"></video>');
            var video_close   = $('<btn id="userback_video_player_close">').html(Svg_Icons.close);

            video.appendTo(video_overlay.children('div'));
            video_close.appendTo(video_overlay);
            video_overlay.appendTo($(document.body));

            video_close.on('click', function() {
                video_overlay.remove();
            });
        },

        removeVideoData: function() {
            this.video_blob = null;
            this.video_url  = '';
            this.video_annotation = [];

            this.ui.controls.find('.userback-controls-video-preview').hide();
            this.ui.controls.find('.userback-controls-screenshot, .userback-controls-video').removeAttr('disabled');
            this.ui.controls.find('.userback-controls-screenshot, .userback-controls-video').find('.userback-screenshot-preview-checkmark').hide();

            this.toggleAttachButtons();
        },

        removeScreenshotData: function() {
            this.screenshot_data_uri = [];
            this.comments = [];

            this.ui.controls.find('.userback-controls-screenshot-preview').hide();
            this.ui.controls.find('.userback-controls-screenshot, .userback-controls-video').removeAttr('disabled');
            this.ui.controls.find('.userback-controls-screenshot, .userback-controls-video').find('.userback-screenshot-preview-checkmark').hide();

            this.toggleAttachButtons();
        },

        removeVideo: function(e) {
            e.stopPropagation();
            this.removeVideoData();
        },

        removeScreenshot: function(e) {
            e.stopPropagation();

            if (this.feedback_url) {
                this.feedback_url = '';
            } else {
                this.removeScreenshotData();
            }

            this.checkScreenshotLimit();
        },

        hideControls: function() {
            this.ui.button.hide();
            this.ui.controls.hide();

            this.ui.element.removeAttr('isopen');
        },

        showControls: function(force_show_form) {
            if (!this.isAutoHide()) {
                this.ui.button.show();
            }

            if (this.controls_step === 2) {
                // go straight to the form step
                this.setFormHTML();
                this.ui.controls.find('.userback-controls-step[data-step="1"]').hide();
                this.ui.controls.find('.userback-controls-step[data-step="2"]').show();

                this.ui.controls.attr('data-step', '2');

                if (this.direct_from === 'routing') {
                    this.ui.controls.find('.userback-controls-back').removeClass('disabled');
                }

                if (force_show_form) {
                    this.ui.controls.find('.userback-controls-form').show();
                }

                if (this.screenshot_data_uri.length) {
                    this.ui.controls.find('.userback-controls-screenshot-preview').show();
                }
            }

            this.ui.controls.show();
            this.focusControls();

            this.ui.element.attr('isopen', 'true');
        },

        // open the control panel
        openControls: function(e) {
            Session_Recorder.addCustomEvent('widget_open', {
                type: 'widget_interaction',
                name: 'Widget Open'
            });

            this.checkLive();
            this.addControls(); // open widget form

            var has_help  = this.configs.help_link ? true : false;
            var open_tool = false;

            this.controls_step = 1; // step: routing screen

            if (this.isNextGen()) {
                this.controls_step = 2;

                if (this.configs.direct_to_general === 'screenshot') {
                    this.attachScreenshot();
                }
            } else {
                if (!has_help && !this.configs.display_feedback) {
                    if (
                        (this.configs.workflow_type === 'capture' || this.configs.workflow_type === 'video') ||
                        (this.configs.workflow_type === 'general'         && this.configs.direct_to_general         !== 'form') ||
                        (this.configs.workflow_type === 'bug'             && this.configs.direct_to_bug             !== 'form') ||
                        (this.configs.workflow_type === 'feature_request' && this.configs.direct_to_feature_request !== 'form')) {

                        // step: screenshot or video tool
                        this.controls_step = 0;
                    } else if (this.configs.workflow_type === 'general' ||
                            this.configs.workflow_type === 'bug' ||
                            this.configs.workflow_type === 'feature_request') {

                        // step: form
                        this.controls_step = 2;
                    }

                    if (this.configs.workflow_type === 'capture' ||
                        (this.configs.workflow_type === 'general'         && this.configs.direct_to_general         === 'screenshot') ||
                        (this.configs.workflow_type === 'bug'             && this.configs.direct_to_bug             === 'screenshot') ||
                        (this.configs.workflow_type === 'feature_request' && this.configs.direct_to_feature_request === 'screenshot')) {

                        if (this.configs.workflow_type === 'capture') {
                            this.form_type = 'general';
                        } else {
                            this.form_type = this.configs.workflow_type;
                        }

                        // auto start screenshot
                        this.hideControls();
                        this.attachScreenshot();
                        open_tool = true;
                    } else if (
                        this.configs.workflow_type === 'video' ||
                        (this.configs.workflow_type === 'general'         && this.configs.direct_to_general         === 'video') ||
                        (this.configs.workflow_type === 'bug'             && this.configs.direct_to_bug             === 'video') ||
                        (this.configs.workflow_type === 'feature_request' && this.configs.direct_to_feature_request === 'video')
                    ) {

                        if (this.configs.workflow_type === 'capture') {
                            this.form_type = 'general';
                        } else {
                            this.form_type = this.configs.workflow_type;
                        }

                        // video
                        this.startVideo();
                        open_tool = true;
                    }
                }
            }

            if (!open_tool) {
                this.ui.element.attr('isopen', 'true');
            }

            if (this.on_open) {
                this.on_open.call(Userback_Api);
            }

            // track impression
            $.ajax(Config.request_url + '/?impression', {
                data: {
                    action: 'widget/impression',
                    widget_id: this.configs.widget_id,
                    access_token: Config.access_token
                },
                crossDomain: true,
                dataType: 'json',
                type: 'POST'
            });
        },

        focusControls: function(e) {
            if (!this.ui.controls) {
                return;
            }

            this.ui.controls.find('input[type="text"],input[type="email"],textarea,select')
                .not('.rating-required')
                .filter(':visible')
                .filter(function() {
                    return this.value === '';
                })
                .eq(0)
                .focus();
        },

        viewOtherFeedback: function(e) {
            this.ui.element.attr('isopen', 'true');

            this.ui.controls.hide();
            this.addFeedbackView();
        },

        dropdownChange: function(e) {
            $(e.currentTarget).attr('data-empty', $(e.currentTarget).val() === '' ? 'true' : 'false');
        },

        changeCategory: function(e) {
            this.categories = this.ui.controls.find('select[name="category"]').val();
        },

        changePriority: function(e) {
            this.priority = this.ui.controls.find('select[name="priority"]').val();
        },

        changeAssignee: function(e) {
            this.assignee = this.ui.controls.find('select[name="assignee"]').val();
        },

        checkboxToggle: function(e) {
            if ($(e.currentTarget).attr('disabled')) {
                return;
            }

            var is_checked = this.ui.controls.find('.userback-checkbox-container input[type="checkbox"]').prop('checked');

            this.ui.controls.find('.userback-checkbox-container input[type="checkbox"]').prop('checked', !is_checked);

            var field_name = this.ui.controls.find('.userback-checkbox-container input[type="checkbox"]').attr('name');

            if (field_name && typeof this.form_data[field_name] !== 'undefined') {
                this.form_data[field_name] = !is_checked;
            }
        },

        // onclick paperclip icon
        selectAttachment: function(e) {
            if ($(e.currentTarget).attr('disabled')) {
                return;
            }

            this.ui.controls.find('.userback-controls-attachment').click();
        },

        selectRating: function(e) {
            var form_settings = this.getFormSettings(this.form_type);

            this.ui.controls.find('ubrating > ubdiv').addClass('inactive');

            if (form_settings.rating_type === 'emoji' || form_settings.rating_type === 'number' || form_settings.rating_type === 'thumb') {
                $(e.currentTarget).removeClass('inactive');
            } else if (form_settings.rating_type === 'star' || form_settings.rating_type === 'heart') {
                $(e.currentTarget).prevAll().removeClass('inactive');
                $(e.currentTarget).removeClass('inactive');
            }

            var rating = '';
            if (form_settings.rating_type) {
                var selected_icon = this.ui.controls.find('ubrating > ubdiv:not(.inactive):last');
                if (selected_icon.length) {
                    rating = selected_icon.data('rating');
                }

                this.form_data.rating = rating;
            }

            this.ui.controls.find('.rating-required').val(rating);
            this.ui.controls.find('.userback-controls-form').show();
            this.focusControls();
        },

        previewRating: function(e) {
            var form_settings = this.getFormSettings(this.form_type);

            if (form_settings.rating_type === 'star' || form_settings.rating_type === 'heart') {
                this.ui.controls.find('ubrating > ubdiv').removeClass('highlight').addClass('preview');
                $(e.currentTarget).prevAll().addClass('highlight');
                $(e.currentTarget).addClass('highlight');
            } else if (form_settings.rating_type === 'emoji' || form_settings.rating_type === 'thumb') {
                this.ui.controls.find('ubrating > ubdiv').removeClass('highlight').addClass('preview');
                $(e.currentTarget).addClass('highlight');
            }
        },

        unPreviewRating: function(e) {
            this.ui.controls.find('ubrating > ubdiv').removeClass('highlight preview');
        },

        renderAttachment: function(is_update) {
            if (is_update) {
                this.ui.controls.find('.userback-controls-add-attachment .ub-checkmark').addClass('ub-checkmark-fixed');
            }

            if (this.attachment_file_name) {
                this.ui.controls.find('.userback-controls-attachment-name').show()
                    .find('.userback-attachment-filename').text(Lib.baseName(this.attachment_file_name));

                this.ui.controls.find('.userback-controls-add-attachment').attr('disabled', 'disabled');
                this.ui.controls.find('.userback-controls-add-attachment .userback-screenshot-preview-checkmark').show();
            } else {
                this.ui.controls.find('.userback-controls-attachment').val('');
                this.ui.controls.find('.userback-controls-attachment-name').hide();

                this.ui.controls.find('.userback-controls-add-attachment').removeAttr('disabled');
                this.ui.controls.find('.userback-controls-add-attachment .userback-screenshot-preview-checkmark').hide();
            }
        },

        removeAttachment: function(e) {
            this.attachment_data_uri  = '';
            this.attachment_file_name = '';

            this.renderAttachment();

            this.toggleAttachButtons();
        },

        uploadAttachment: function(e) {
            if (!e.target.files || !e.target.files.length) {
                return;
            }

            var file       = e.target.files[0];
            var reader     = new FileReader();
            var size_limit = Feedback.configs.attachment_size || 5; // file size limit: 5MB

            var error = false;

            var block_list = [
                'ade',
                'adp',
                'apk',
                'appx',
                'appxbundle',
                'bat',
                'cab',
                'chm',
                'cmd',
                'com',
                'cpl',
                'dll',
                'dmg',
                'ex',
                'ex_',
                'exe',
                'hta',
                'ins',
                'isp',
                'iso',
                'jar',
                'js',
                'jse',
                'lib',
                'lnk',
                'mde',
                'msc',
                'msi',
                'msix',
                'msixbundle',
                'msp',
                'mst',
                'nsh',
                'pif',
                'ps1',
                'scr',
                'sct',
                'shb',
                'sys',
                'vb',
                'vbe',
                'vbs',
                'vxd',
                'wsc',
                'wsf',
                'wsh'
            ];

            if (block_list.indexOf(Lib.fileExtension(file.name).toLowerCase()) !== -1) {
                error = Lang.get('file_format_not_supported');
            }

            if (file.size > size_limit * 1024 * 1024) {
                error = Lang.get('max') + ': ' + size_limit + 'MB';
            }

            if (error) {
                this.ui.controls.find('.userback-controls-attachment-name').show().children('span').text(error);
            } else {
                reader.onload = () => {
                    this.attachment_data_uri  = reader.result;
                    this.attachment_file_name = file.name;

                    this.renderAttachment();
                    this.toggleAttachButtons();
                };

                reader.readAsDataURL(file);
            }
        },

        // get data
        getData: function() {
            var form_settings = this.getFormSettings(this.form_type);

            var comments = [];

            $.each(this.comments, function(key, comment) {
                comments.push(comment.getData());
            });

            var rating = '';
            if (form_settings.rating_type) {
                var selected_icon = this.ui.controls?.find('ubrating > ubdiv:not(.inactive):last');
                if (selected_icon?.length) {
                    rating = selected_icon.data('rating');
                }
            }

            if (!this.screenshot_window_x) {
                this.screenshot_window_x    = window.innerWidth;
                this.screenshot_window_y    = window.innerHeight;
                this.screenshot_window_top  = $(window).scrollTop()  || $(document).scrollTop();
                this.screenshot_window_left = $(window).scrollLeft() || $(document).scrollLeft();
            }

            var custom_field_data = [];

            if (form_settings.custom_field_1_type === 'checkbox' && form_settings.custom_field_1_label) {
                custom_field_data.push({
                    type: 'checkbox',
                    label: form_settings.custom_field_1_label,
                    value: this.ui.controls?.find('.userback-checkbox-container input[type="checkbox"]').prop('checked') ? 'Yes' : ''
                });
            } else if (form_settings.custom_field_1_type === 'disclaimer' && form_settings.custom_field_1_label) {
                custom_field_data.push({
                    type: 'disclaimer',
                    label: form_settings.custom_field_1_label,
                    value: 'Yes'
                });
            } else if (form_settings.custom_field_1_type === 'short_answer' || form_settings.custom_field_1_type === 'long_answer') {
                custom_field_data.push({
                    type: form_settings.custom_field_1_type,
                    label: form_settings.custom_field_1_label,
                    value: this.ui.controls?.find('[name="custom_field_1"]').val()
                });
            }

            var form_type = this.form_type;

            if (this.isNextGen()) {
                form_type = this.ui.controls.find('select[name="feedback_type"]').val();

                if (this.configs.ai_feedback_type) {
                    form_type = 'auto';
                }
            }

            return {
                action:                  'feedback/new',
                widget_id:                this.configs.widget_id,
                load_type:                Config.load_type,
                form_type:                form_type,
                access_token:             Config.access_token,
                hcaptcha_token:           this.hcaptcha_token,
                domain:                   window.location.hostname,
                pathname:                 window.location.pathname,
                page:                     this.session_data.page       || window.location.href,
                page_title:               this.session_data.page_title || document.title || '',
                http_header:              this.http_header,
                is_live:                  this.configs.is_live,
                is_auto_screenshot:       this.is_auto_screenshot,
                name:                     this.ui.controls?.find('input[name="name"]').length           ? this.ui.controls.find('input[name="name"]').val()           : (this.name  || ''),
                email:                    this.ui.controls?.find('input[name="email"]').length          ? this.ui.controls.find('input[name="email"]').val()          : (this.email || ''),
                title:                    this.ui.controls?.find('input[name="title"]').length          ? this.ui.controls.find('input[name="title"]').val()          : '',
                description:              this.ui.controls?.find('textarea[name="description"]').length ? this.ui.controls.find('textarea[name="description"]').val() : '',
                comments:                 comments,
                has_video:                this.video_url ? true: false,
                video_annotation:         this.video_annotation,
                screenshot:               this.screenshot_data_uri,
                attachment:               this.attachment_data_uri || '',
                attachment_file_name:     this.attachment_file_name || '',
                ua_data:                  JSON.stringify(Lib.getUAData()),
                user_agent:               window.navigator.userAgent,
                window_x:                 this.session_data.window_x || this.screenshot_window_x,
                window_y:                 this.session_data.window_y || this.screenshot_window_y,
                window_top:               this.screenshot_window_top,
                window_left:              this.screenshot_window_left,
                resolution_x:             this.session_data.resolution_x || window.screen.width,
                resolution_y:             this.session_data.resolution_y || window.screen.height,
                dpi:                      this.session_data.dpi          || window.devicePixelRatio || 0,
                colour_depth:             this.session_data.colour_depth || window.screen.colorDepth || 0,
                categories:               this.categories,
                priority:                 this.priority,
                assignee:                 this.assignee,
                user_data:                !Features.user_segment ? User_Identification.getAll() : undefined,
                user_identification:      Features.user_segment  ? User_Identification.getIdentification() : undefined,
                custom_data:              this.custom_data,
                custom_field:             custom_field_data,
                rating:                   rating,
                console_logs:             JSON.stringify(Console_Recorder.getAll()),
                events:                   Event_Recorder.getAll(),
                session_id:               Session_Recorder.getSessionId(),
                session_recording_events: !Session_Recorder.full_session_replay && !this.video_url && form_settings.allow_session_recording ? JSON.stringify(Session_Recorder.getAll()) : undefined
            };
        },

        goBack: function(e) {
            this.controls_step = 1;
            this.setControls();
        },

        closeWidget: function(e) {
            var _doClose = () => {
                clearTimeout(this.close_timeout);
                this.reset();

                if (this.on_close) {
                    this.on_close.call(Userback_Api);
                }

                Modal.modalClose();
            };

            // ask for confirm
            if (this.hasUnsavedChanges()) {
                Modal.modalConfirm('<div class="userback-modal-title">' + Lang.get('want_to_leave', true) + '</div><div class="userback-modal-info-text">' + Lang.get('changes_not_saved', true) + '</div>', _doClose);
            } else {
                _doClose();
            }
        },

        hasUnsavedChanges: function(e) {
            if (!this.ui.controls) {
                return false;
            }

            var widget_data = this.getData();

            // ask for confirm
            if ((widget_data.description || widget_data.rating || widget_data.screenshot.length || widget_data.attachment) && this.controls_step === 2) {
                return true;
            } else {
                return false;
            }
        },

        // reset widget to the initial state/looking
        reset: function(e) {
            if (e && e.type === 'click') {
                e.preventDefault();
            }

            if (!this.isAutoHide()) {
                if (this.ui.button) {
                    this.ui.button.show();
                }
            }

            if (this.ui.element) {
                this.ui.element.removeAttr('isopen');
            }

            if (Video_Capture.isOpen()) {
                Video_Capture.stopCapture();
            }

            this.removeComments();

            this.comments             = [];
            this.screenshot_data_uri  = [];
            this.attachment_data_uri  = '';
            this.attachment_file_name = '';

            this.local_styles = [];

            this.video_blob = null;
            this.video_url  = '';
            this.video_annotation = [];

            this.form_data = {
                rating        : '',
                name          : '',
                email         : '',
                title         : '',
                description   : '',
                category      : '',
                priority      : '',
                assignee      : '',
                custom_field_1: ''
            };

            this.removeOverlay();
            this.removeControls();
            this.removeFeedbackView();

            this.Tools.removeTools();

            this.controls_step = 0;

            if (Lib.isIos()) {
                document.documentElement.scrollTop = this.page_scroll_top;
            }
        },

        // send data to server
        send: function(e) {
            e.preventDefault();

            if (this.before_send) {
                this.before_send.call(Userback_Api);
            }

            Session_Recorder.addCustomEvent('feedback', {
                name: 'Feedback Submit'
            });

            var form_settings = this.getFormSettings(this.form_type);

            var post_data = this.getData();

            var total_size = 0;
            post_data.screenshot.forEach(function(screenshot_data) {
                total_size += screenshot_data.data_uri.length / 1024 / 1024;
            });

            var send_button = this.ui.controls.find('.userback-controls-send');
            send_button.prop('disabled', true);

            if (post_data.has_video) {
                send_button.addClass('userback-controls-send-uploading');
            } else {
                send_button.addClass('userback-controls-send-loading');
            }

            this.ui.controls.find('.userback-controls-send-error').remove();

            var post_success_timeout;

            var _post_success = (post_data) => {
                if (post_success_timeout) {
                    clearTimeout(post_success_timeout);
                    post_success_timeout = null;
                }

                // widget form is already closed
                if (this.controls_step === 3) {
                    return;
                }

                send_button.removeClass('userback-controls-send-loading');
                send_button.removeClass('userback-controls-send-uploading');

                var outro_icon = '';

                switch (form_settings.outro_icon) {
                    case 1:
                        outro_icon = Svg_Icons.checkmark;
                        break;
                    // deprecated
                    case 2:
                        outro_icon = Svg_Icons.outro_message_check;
                        break;
                    // deprecated
                    case 3:
                        outro_icon = Svg_Icons.outro_hand_clapping;
                        break;
                    // deprecated
                    case 4:
                        outro_icon = Svg_Icons.outro_thumb_up;
                        break;
                    // deprecated
                    case 5:
                        outro_icon = Svg_Icons.widget_stars;
                        break;
                    case 6:
                        // 🥳
                        outro_icon = '<div class="emoji-icon"><span>&#129395;</span></div>';
                        break;
                    case 7:
                        // 👏
                        outro_icon = '<div class="emoji-icon"><span>&#128079;</span></div>';
                        break;
                    case 8:
                        // 👍
                        outro_icon = '<div class="emoji-icon"><span>&#128077;</span></div>';
                        break;
                    case 9:
                        // ✨
                        outro_icon = '<div class="emoji-icon"><span>&#10024;</span></div>';
                        break;
                    case 10:
                        // 🎉
                        outro_icon = '<ubdiv class="emoji-icon"><span>&#127881;</span></ubdiv>';
                        break;
                }

                var additional_data = {
                    name: post_data.name,
                    email: post_data.email
                };

                var success_icon = $('<ubdiv class="userback-controls-send-success">').html(
                                    '<style>' +
                                        ':root {' +
                                            '--widget-outro-icon: ' + this.configs.main_button_background_colour + ' !important;' +
                                        '}' +
                                    '</style>' +
                                    '<uclosel class="userback-controls-close" title="' + Lang.get('close') + '">' + Svg_Icons.xmark_r + '</uclosel>' +
                                    '<ubdiv data-icon="' + form_settings.outro_icon + '">' +
                                        outro_icon +
                                        '<ubspan>' + (Lib.htmlEntities(User_Identification.wildcard(form_settings.outro_headline, additional_data))) + '</ubspan>' +
                                        '<p>' + Lib.htmlEntitiesWithA(User_Identification.wildcard(form_settings.outro_paragraph, additional_data), true) + '</p>' +
                                    '</ubdiv>' +
                                    (this.configs.display_powered_by ?
                                    '<ubfooter>' +
                                        '<a href="https://www.userback.io?utm_source=widget&utm_medium=power_by_link&utm_campaign=widget_code">' +
                                            '<span>Powered by</span>' +
                                            Svg_Icons.logo +
                                        '</a>' +
                                    '</ubfooter>' : ''));

                this.ui.controls.append(success_icon);

                this.controls_step = 3;

                var outro_has_link = this.ui.controls.find('.userback-controls-send-success p a').length ? true : false;

                var reset = () => {
                    if (this.is_posting) {
                        return;
                    }

                    this.reset.call(this);

                    if (this.after_send) {
                        var callback_data = {...post_data};
                        delete callback_data.action;
                        delete callback_data.access_token;

                        if (!Userback.screen_capture && this.configs.is_live) {
                            delete callback_data.screenshot;
                        }

                        this.after_send.call(Userback_Api, callback_data);
                    }
                };

                this.close_timeout = setTimeout(reset, outro_has_link || this.is_posting ? 10000 : 3000); // dismiss in 10 seconds
            };

            var _post_error = (error_msg) => {
                error_msg = error_msg || 'Submit error, please try again.';

                if (post_success_timeout) {
                    clearTimeout(post_success_timeout);
                    post_success_timeout = null;
                }

                if (this.controls_step === 2) {
                    send_button.removeClass('userback-controls-send-loading');
                    send_button.removeClass('userback-controls-send-uploading');

                    send_button.prop('disabled', false);

                    $('<ubdiv>').addClass('userback-controls-send-error').text(error_msg).insertAfter(send_button);
                } else if (this.controls_step === 3) {
                    $('<ubdiv>').addClass('userback-controls-send-error-2').text(error_msg).insertAfter($('.userback-controls-send-success'));
                }
            };

            var _post = () => {
                this.ui.controls.find('input[type="text"],input[type="email"],textarea,select').prop('disabled', true);
                this.ui.controls.find('.userback-controls-attach-actions btn, .userback-checkbox-container > ubdiv').attr('disabled', 'disabled');

                this.is_posting = true;

                // when posting large session recording
                // wait for 3 seconds
                if (post_data.session_recording_events) {
                    post_success_timeout = setTimeout(() => {
                        _post_success(post_data);
                    }, 5000);
                }

                $.ajax(Config.request_url + '/?postWidget', {
                    data: post_data,
                    crossDomain: true,
                    dataType: 'json',
                    type: 'POST',
                    context: this,
                    success: function(response) {
                        if (!this.video_blob || !response.url) {
                            this.is_posting = false;

                            if (response === true) {
                                _post_success(post_data);
                            } else if (response.error && response.error === 'ip_limit') {
                                _post_error('Too many requests submitted from the same IP address. Please wait...');
                            } else {
                                _post_error();
                            }

                            return;
                        }

                        // upload video
                        $.ajax(response.url, {
                            type: 'PUT',
                            data: this.video_blob,
                            crossDomain: true,
                            context: this,
                            processData: false,
                            xhr: function() {
                                var xhr = new window.XMLHttpRequest();

                                if (xhr.upload) {
                                    send_button.width(send_button.width());

                                    xhr.upload.addEventListener('progress', function(evt) {
                                        if (evt.lengthComputable) {
                                            var percent = parseInt(evt.loaded / evt.total * 100, 10);

                                            send_button.find('.userback-controls-send-text').text(percent + '%');
                                            send_button.find('.userback-controls-send-progress').width(percent + '%');
                                        }
                                    }, false);
                                }

                                return xhr;
                            },
                            complete: function() {
                                this.is_posting = false;
                                _post_success(post_data);
                            }
                        });
                    },
                    error: function() {
                        this.is_posting = false;

                        _post_error();

                        this.ui.controls.find('input[type="text"],input[type="email"],textarea,select,.userback-controls-attach-actions btn').prop('disabled', false);
                        this.ui.controls.find('.userback-controls-attach-actions btn, .userback-checkbox-container > ubdiv').removeAttr('disabled');
                    }
                });

                if (post_data.access_token) {
                    if (post_data.name) {
                        Lib.storage.set('ubw_name',  post_data.name);
                    }

                    if (post_data.email) {
                        Lib.storage.set('ubw_email', post_data.email);
                    }
                }
            };

            var _send = () => {
                if (Feedback.Tools.isOpen() && this.isNextGen()) {
                    // next gen: submit screenshot and feedback together
                    Feedback.Tools.submitAnnotation(() => {
                        _post();
                    });
                } else if (this.configs.auto_screenshot && post_data.screenshot.length === 0 && !post_data.has_video) {
                    var delay = Lib.isSafari() && (Lib.isMacOs() || Lib.isIos()) ? 100 : 0;

                    // Use setTimeout to ensure the overlay appears after the keyboard is dismissed in IOS/MacOS Safari，
                    // auto screenshot
                    setTimeout(() => {
                        Feedback.Tools.autoScreenshot(() => {
                            post_data.is_auto_screenshot = true;
                            _post();
                        });
                    }, delay);
                } else {
                    _post();
                }
            };

            // too large
            if (total_size > 50) { // > 50MB
                _post_error('Error: page size is too large.');
            } else {
                _send();
            }
        },

        // remove the last open empty comment
        closeEmptyComment: function() {
            if (this.comments.length && this.comments[this.comments.length - 1].isEmpty()) {
                this.comments[this.comments.length - 1].destroy();
                this.comments.splice(this.comments.length - 1, 1);
            }
        },

        submitUnsavedComment: function() {
            this.comments.forEach(function(comment) {
                if (comment.isOpen()) {
                    comment.submit();
                }
            });
        },

        commentStart: function(e) {
            var pin_x, pin_y;

            if (e.changedTouches && e.changedTouches.length) {
                pin_x = e.changedTouches[0].pageX;
                pin_y = e.changedTouches[0].pageY;
            } else {
                pin_x = e.pageX;
                pin_y = e.pageY;
            }

            if (this.comments.length && this.comments[this.comments.length - 1].isOpen()) {
                this.comments[this.comments.length - 1].setPosition(pin_x, pin_y);
            } else {
                $.each(this.comments, function(index, comment) {
                    comment.hideForm();
                });

                this.addComment(pin_x, pin_y);

                Feedback.disableScroll();
            }
        },

        capture: function(callback, screenshot_mode) {
            if (Userback.screen_capture) {
                // chrome extension
                Userback.screen_capture(callback.bind(this));
            } else if ((this.configs.native_screenshot && !screenshot_mode) || screenshot_mode === 'native') {
                this.nativeScreenshotCapture(callback.bind(this));
            } else if ((this.configs.is_live && !screenshot_mode) || screenshot_mode === 'html2image') {
                // html2image
                this.html2imageCapture(callback.bind(this));
            } else {
                if (typeof html2canvas === 'undefined') {
                    Scripts.lazyLoad([Scripts.html2canvas], () => {
                        this.html2canvasCapture(callback.bind(this));
                    });
                } else {
                    this.html2canvasCapture(callback.bind(this));
                }
            }
        },

        addLocalStyles: function() {
            if (!this.configs.is_live) {
                return;
            }

            if (!document.styleSheets || !document.styleSheets.length) {
                return;
            }

            this.local_styles = [];

            for (var i = 0; i < document.styleSheets.length; i++) {
                var style_sheet = document.styleSheets[i];

                if (!style_sheet.ownerNode) {
                    continue;
                }

                var add_style     = false;
                var replace_style = false;

                if (style_sheet.ownerNode.tagName === 'LINK' && style_sheet.ownerNode.getAttribute('href')) {
                    if (style_sheet.ownerNode.getAttribute('href').substr(0, 5) === 'blob:') {
                        add_style = true;
                    }

                    if (style_sheet.href) {
                        style_sheet.ownerNode.setAttribute('ubhref', style_sheet.href);
                    }
                } else if (style_sheet.ownerNode.tagName === 'LINK' && style_sheet.ownerNode.integrity) {
                    add_style = true;
                } else if (style_sheet.ownerNode.tagName === 'STYLE') {
                    // inline style tag
                    replace_style = true;
                }

                if (!add_style && !replace_style) {
                    continue;
                }

                if (!style_sheet.cssRules || !style_sheet.cssRules.length) {
                    continue;
                }

                var styles = '';

                for (var j = 0; j < style_sheet.cssRules.length; j++) {
                    styles += style_sheet.cssRules[j].cssText;
                }

                if (styles) {
                    if (add_style) {
                        $('<style data-ub-local-style-append="' + this.local_styles.length + '">').insertAfter(style_sheet.ownerNode);
                    } else {
                        $(style_sheet.ownerNode).attr('data-ub-local-style-replace', this.local_styles.length);
                    }

                    this.local_styles.push({
                        type   : add_style ? 'append' : 'replace',
                        styles : styles
                    });
                }
            }
        },

        html2imageCapture: function(callback) {
            var doctype = '';
            var node = document.doctype;

            // local styles (blob styles, hidden inline style tag)
            try {
                this.addLocalStyles();
            } catch (e) {
            }

            if (node) {
                doctype = "<!DOCTYPE "
                        + node.name
                        + (node.publicId ? ' PUBLIC "' + node.publicId + '"' : '')
                        + (!node.publicId && node.systemId ? ' SYSTEM' : '')
                        + (node.systemId ? ' "' + node.systemId + '"' : '')
                        + '>';
            }

            var iframe_data  = '';
            var iframe_count = 0;

            $('body *').add('svg').each(function() {
                var element     = $(this);
                var tag_name    = element.prop('tagName').toUpperCase();
                var scroll_top  = element.scrollTop();
                var scroll_left = element.scrollLeft();

                var messure_height = element.hasClass('userback-lazy-load');

                if (scroll_top > 0) {
                    element.attr('data-scrolltop', scroll_top);
                }

                if (scroll_left > 0) {
                    element.attr('data-scrollleft', scroll_left);
                }

                if (tag_name === 'SELECT' || tag_name === 'TEXTAREA' || tag_name === 'INPUT') {
                    element.attr('data-value', element.val());

                    if (element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
                        if (element.prop('checked') === true) {
                            element.attr('data-checked', 'true');
                        }
                    }
                }

                if (messure_height || tag_name === 'VIDEO' || tag_name === 'SELECT' || tag_name === 'SVG') {
                    if (element.css('box-sizing') === 'border-box') {
                        element.attr('data-ubheight', element.outerHeight());
                    } else {
                        element.attr('data-ubheight', element.height());
                    }
                } else if (tag_name === 'BASE') {
                    element.attr('href', element.get(0).href);
                }

                // iframe
                if (tag_name === 'IFRAME' && !element.attr('srcdoc')) {
                    try {
                        var iframe_window = element.get(0).contentWindow;
                        var iframe_document = iframe_window.document;

                        if (iframe_document) {
                            var node = iframe_document.doctype;
                            var doctype = "<!DOCTYPE "
                                        + node.name
                                        + (node.publicId ? ' PUBLIC "' + node.publicId + '"' : '')
                                        + (!node.publicId && node.systemId ? ' SYSTEM' : '')
                                        + (node.systemId ? ' "' + node.systemId + '"' : '')
                                        + '>';

                            var base_url = element.attr('src');

                            if (typeof URL !== 'undefined') {
                                base_url = new URL(element.attr('src'), window.location.href).href;
                            }

                            var iframe_content = doctype + iframe_document.documentElement.outerHTML;
                            var base_tag = '<base href="' + base_url + '" target="_blank">';
                            iframe_content = iframe_content.replace(/<head>/i, '<head>' + base_tag);

                            element.attr('userback_iframe_count', iframe_count);
                            iframe_data += '<span style="display:none;" class="iframedata_' + iframe_count + '">' + Lib.b64EncodeUnicode(iframe_content) + '</span>';

                            iframe_count++;
                        }
                    } catch (e) {
                    }
                }
            });

            if ($(document).scrollTop() > 0) {
                $('body').attr('data-scrolltop', $(document).scrollTop());
            } else if ($(document.body).scrollTop() > 0) {
                $('body').attr('data-scrolltop', $(document.body).scrollTop());
            }

            $(document.head).find('iframe').hide();

            // add ignore class
            this.configs.recording_rules.screenshot.forEach(function(selector) {
                try {
                    $(selector).addClass('_userback-ignore');
                } catch(e) {
                    // catch invalid selectors
                }
            });

            var document_html = doctype + "\r\n" + document.documentElement.outerHTML;

            if (iframe_data) {
                document_html = document_html.replace(/userback_iframe_count/g, 'scrolling="no" userback_iframe_count');
            }

            var scrollbar_size = Lib.getScrollbarSize();
            var scrollbar_styles = '<style>' +
                                    '::-webkit-scrollbar {' +
                                        'width:' + scrollbar_size + 'px;' +
                                        'height:' + scrollbar_size + 'px;' +
                                    '}' +
                                    '</style>';

            document_html = document_html.replace(/(<head[^>]*>)/gi, '$1' + scrollbar_styles);

            this.local_styles.forEach(function(opt, index) {
                var regex;

                if (opt.type === 'replace') {
                    regex = new RegExp('(<style[^>]*data-ub-local-style-replace="' + index + '"[^>]*>)([^<>]*)(<\/style>)', 'gi');
                } else if (opt.type === 'append') {
                    regex = new RegExp('(<style[^>]*data-ub-local-style-append="' + index + '"[^>]*>)([^<>]*)(<\/style>)', 'gi');
                }

                document_html = document_html.replace(regex, '$1' + opt.styles + '$3');
            });
            this.local_styles = [];

            $('[userback_iframe_count]').removeAttr('userback_iframe_count');
            $('[data-scrolltop]').removeAttr('data-scrolltop');
            $('[data-scrollleft]').removeAttr('data-scrollleft');
            $('[data-value]').removeAttr('data-value');
            $('[data-checked]').removeAttr('data-checked');
            $('[data-ubheight]').removeAttr('data-ubheight');
            $('[data-ub-local-style-append]').remove();
            $('[data-ub-local-style-replace]').removeAttr('data-ub-local-style-replace');
            $('._userback-ignore').removeClass('_userback-ignore');

            var canvas_data = '';
            var canvas_array = document.getElementsByTagName('canvas');
            for (var i = 0; i < canvas_array.length; i++) {
                var canvas_data_url;

                if ($(canvas_array[i]).is(':visible')) {
                    try {
                        canvas_data_url = canvas_array[i].toDataURL('image/png', 1.0);
                    } catch (e) {
                        canvas_data_url = '';
                    }
                } else {
                    canvas_data_url = '';
                }

                // greater than 10MB?
                if (canvas_data_url > 10 * 1024 * 1024) {
                    canvas_data_url = '';
                }

                canvas_data += '<span style="display:none;" class="canvasdata_' + i + '">' + canvas_data_url + '</span>';
            }

            var video_data = '';
            var video_array = document.getElementsByTagName('video');
            for (var i = 0; i < video_array.length; i++) {
                var poster = video_array[i].getAttribute('poster');
                video_data += '<span style="display:none;" class="videodata_' + i + '">' + (poster ? poster : '') + '</span>';
            }

            // find the last body tag to bypass iframe with srcdoc containing body
            var last_index = document_html.lastIndexOf('</body>');
            if (last_index && (canvas_data || video_data || iframe_data)) {
                document_html = document_html.substring(0, last_index) + canvas_data + video_data + iframe_data + '</body></html>';
            }

            document_html = document_html.replace(/<!--(.|\s)*?-->/g, ''); // remove html comments
            document_html = document_html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ''); // remove script tags

            callback(document_html);
        },

        nativeScreenshotCapture: function(callback) {
            var canvas = $('<canvas>').get(0);
            var context = canvas.getContext('2d');

            canvas.width  = this.ui.overlay.width();
            canvas.height = this.ui.overlay.height();

            var screen_img = new Image();
            var snap_img   = new Image();

            screen_img.onload = () => {
                var image_ratio  = screen_img.width / screen_img.height;
                var dwidth  = Math.min(screen_img.width, canvas.width);
                var dheight = Math.min(screen_img.height, canvas.height);

                if (dwidth / dheight > image_ratio) {
                    dwidth = dheight * image_ratio;
                } else {
                    dheight = dwidth / image_ratio;
                }

                var dx = (canvas.width - dwidth) / 2;
                var dy = (canvas.height - dheight) / 2;

                // center image
                context.drawImage(screen_img, dx, dy, dwidth, dheight);

                var snap_svg = this.ui.overlay.find('#snap_svg');
                // svg must have width and height attributes for image to be rendered in canvas in firefox
                snap_svg.attr('width', canvas.width);
                snap_svg.attr('height', canvas.height);

                // draw svg
                var serializedSVG = new XMLSerializer().serializeToString(snap_svg.get(0));
                snap_img.src = 'data:image/svg+xml;base64,' + window.btoa(serializedSVG);
            };

            snap_img.onload = () => {
                context.drawImage(snap_img, 0, 0);
                callback(canvas.toDataURL(this.mime_type, this.compression_rate));
            };

            screen_img.src = this.ui.overlay.find('#native_screenshot img').attr('src');
        },

        // capture a screnshot and update it in the controls
        html2canvasCapture: function(callback) {
            var svg_image_onload = (svg_img_load_success) => {
                if (this.Tools.snap) {
                    this.Tools.snap.remove();
                }

                try {
                    html2canvas(document.body, {
                        scale: 1,
                        logging: false,
                        proxy: Config.proxy_url
                    })
                    .then((page_canvas) => {
                        // control closed before callback
                        if (!this.ui.controls) {
                            return;
                        }

                        var error_count = 0;

                        // merge html2canvas with our canvas
                        var imageOnload = function() {
                            var canvas = $('<canvas>').get(0);
                            canvas.width  = page_canvas.width;
                            canvas.height = page_canvas.height;
                            var context = canvas.getContext('2d');

                            // full size canvas created by html2canvas
                            context.drawImage(html2canvas_image, 0, 0);

                            // crop image to current viewport
                            try {
                                var image_data_uri = canvas.toDataURL(this.mime_type, this.compression_rate);
                            } catch (e) {
                                if (error_count > 0) {
                                    this.html2imageCapture(callback.bind(this));
                                    return;
                                }

                                error_count++;

                                // IE10/11 Blob image in canvas can't be decoded to data url
                                var canvg_canvas = $(canvas).clone().hide().appendTo($(document.body)).get(0);
                                canvg(canvg_canvas, svg_string);
                                var canvg_data_url = canvg_canvas.toDataURL('image/png', 1);
                                $(canvg_canvas).remove();

                                svg_img = new Image();

                                svg_img.onload = (e) => {
                                    imageOnload.call(this, true);
                                };

                                svg_img.onerror = (e) => {
                                    imageOnload.call(this, false);
                                };

                                svg_img.src = canvg_data_url;
                            }

                            var temp_canvas    = $('<canvas>').get(0);
                            var context        = temp_canvas.getContext('2d');
                            var image          = new Image();
                            var image_height   = $(window).height();

                            temp_canvas.width  = canvas.width;
                            temp_canvas.height = image_height;

                            image.onload = function() {
                                // draw cropped image
                                var sourceX      = 0;
                                var sourceY      = $(window).scrollTop();
                                var sourceWidth  = canvas.width;
                                var sourceHeight = image_height;
                                var destWidth    = canvas.width;
                                var destHeight   = image_height;
                                var destX        = 0;
                                var destY        = 0;

                                context.drawImage(image, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);

                                if (svg_img_load_success) {
                                    context.drawImage(svg_annotation_img, 0, 0);
                                }

                                callback(temp_canvas.toDataURL(this.mime_type, this.compression_rate));
                            };

                            image.src = image_data_uri;
                        };

                        var imageOnError = () => {
                            this.html2imageCapture(callback.bind(this));
                        };

                        var html2canvas_image = new Image();
                        html2canvas_image.onload = imageOnload;
                        html2canvas_image.onerror = imageOnError;
                        html2canvas_image.src = page_canvas.toDataURL(this.mime_type, this.compression_rate);
                    })
                    .catch(() => {
                        // something went wrong, use live
                        this.html2imageCapture(callback.bind(this));
                    });
                } catch (e) {
                    this.html2imageCapture(callback.bind(this));
                }
            };

            // remove icons from svg, otherwise svg will have parsing problems in some browsers
            this.Tools.removeDeleteIcons();

            // when use auto screenshot, snap is not created
            if (this.Tools.snap) {
                // set svg container size so image created from URL.createObjectURL will have a size
                $('#snap_svg').attr({
                    width  : $('#snap_svg').width(),
                    height : $('#snap_svg').height()
                });

                // SVG to Canvas
                var svg_string = this.Tools.snap.outerSVG();

                // parse string using browser's internal api
                svg_string = $('<div>').html(svg_string).html();

                // load the annotation svg first
                // it will be added to the canvas later
                var svg_annotation_img = new Image();
                var url = 'data:image/svg+xml;base64,' + btoa(svg_string);

                svg_annotation_img.onload = (e) => {
                    svg_image_onload.call(this, true);
                };

                svg_annotation_img.onerror = (e, b, c, d) => {
                    svg_image_onload.call(this, false);
                };

                svg_annotation_img.src = url;
            } else {
                svg_image_onload.call(this, false);
            }
        }
    };

    // feedback pin comment
    var Feedback_Comment = function(options) {
        'use strict';

        this.x              = options.x;
        this.y              = options.y;
        this.scroll_t       = options.scroll_t;
        this.scroll_l       = options.scroll_l;
        this.offset_x       = options.offset_x;
        this.offset_y       = options.offset_y;
        this.counter        = options.counter;
        this.screenshot_num = options.screenshot_num;
        this.onDelete       = options.onDelete;
        this.onOpen         = options.onOpen;

        this.is_new = true;

        var element = $('<div>').addClass('userback-comment').attr('data-html2canvas-ignore', 'true');
        var comment_message = '';

        element.on('click mouseup mousedown', function(e) {
            e.stopPropagation();
        });

        this._getHTML = function() {
            return '<div class="userback-comment-pin"><span>' + (this.counter + 1)+ '</span></div>' +
                    '<form class="userback-comment-form">' +
                        '<ubcomment class="ubmousescroll" contenteditable="true" data-ph="' + Lang.get('add_your_comment_here') + '" gramm_editor="false"></ubcomment>' +
                        '<btn class="userback-button-input">' + Lang.get('save') + '</btn>' +
                        '<btn class="userback-comment-form-delete" title="' + Lang.get('delete') + '">' + Svg_Icons.trash + '</btn>' +
                        '<btn class="userback-comment-form-close" title="' + Lang.get('close') + '">' + Svg_Icons.times + '</btn>' +
                    '</form>';
        };

        this.isOpen = function() {
            return element.find('.userback-comment-form').is(':visible');
        };

        this.isEmpty = function() {
            return comment_message ? false : true;
        };

        this.setCounter = function(counter) {
            this.counter = counter;
            element.find('.userback-comment-pin > span').text(counter + 1);
        };

        this.submit = function() {
            element.find('.userback-button-input').click();
        },

        this.show = function() {
            element.show();
        };

        this.hide = function() {
            element.hide();
        };

        this.showForm = function() {
            element.find('.userback-comment-form').show();
            element.find('ubcomment').html(Lib.htmlEntities(comment_message, true)).focus();
        };

        this.hideForm = function() {
            element.find('.userback-comment-form-delete').show();
            element.find('.userback-comment-form').hide();
        };

        this.setPosition = function(x, y) {
            this.x = x;
            this.y = y;

            this.setFormDirection();

            element.css({
                top  : this.y,
                left : this.x
            });
        };

        this.setFormDirection = function() {
            var form_width  = Lib.isMobile() ? 210 : 430; // 30 is the left position
            var form_height = Lib.isMobile() ? 150 : 200;

            if (Lib.isMobile()) {
                if (this.x > $(window).width() / 2) {
                    element.find('.userback-comment-form').attr('xdirection', 'left');
                } else {
                    element.find('.userback-comment-form').attr('xdirection', 'right');
                }

                if (this.y > 140) {
                    element.find('.userback-comment-form').attr('ydirection', 'top');
                } else {
                    element.find('.userback-comment-form').attr('ydirection', 'down');
                }
            } else {
                if ($(window).scrollLeft() + $(window).width() - this.x >= form_width) {
                    element.find('.userback-comment-form').attr('xdirection', 'right');
                } else {
                    element.find('.userback-comment-form').attr('xdirection', 'left');
                }


                if ($(window).scrollTop() + $(window).height() - this.y >= form_height) {
                    element.find('.userback-comment-form').attr('ydirection', 'down');
                } else {
                    element.find('.userback-comment-form').attr('ydirection', 'top');
                }
            }
        };

        this.add = function() {
            element.css({
                top  : this.y,
                left : this.x
            });

            element.html(this._getHTML());

            this.setFormDirection();

            // open form
            element.find('.userback-comment-pin').on('click', (e) => {
                this.showForm();
                this.onOpen(this.counter);
            });

            // delete
            element.find('.userback-comment-form-close').on('click', (e) => {
                e.stopPropagation();

                if (this.is_new) {
                    this.destroy();
                    this.onDelete(this.counter);
                    return;
                }

                this.hideForm();
            });

            element.find('.userback-comment-form-delete').on('click', (e) => {
                e.stopPropagation();

                this.destroy();
                this.onDelete(this.counter);
            });

            element.find('ubcomment').on('keydown', function(e) {
                if (e.which === 13) {
                    if (e.ctrlKey || e.metaKey) {
                        element.find('button[type="submit"]').click();
                    }
                }
            });

            element.find('ubcomment').on('keyup', function(e) {
                if ($(this).html() === '<br>') {
                    $(this).html('');
                };
            });

            element.find('ubcomment').on('paste', function(e) {
                e.preventDefault();

                try {
                    var text = (e.originalEvent || e).clipboardData.getData('text/plain');
                    document.execCommand('insertText', false, text);
                } catch (e) {
                }
            });

            // form submit
            element.find('.userback-button-input').on('click', (e) => {
                comment_message = element.find('ubcomment').html();

                comment_message = comment_message.replace(/<br\/?>/ig, '\n');
                comment_message = comment_message.replace(/(<([^>]+)>)/gi, '');
                comment_message = comment_message.replace(/&nbsp;/gi, ' ');
                comment_message = comment_message.replace(/&lt;/gi, '<');
                comment_message = comment_message.replace(/&gt;/gi, '>');
                comment_message = comment_message.replace(/&amp;/gi, '&');
                comment_message = comment_message.replace(/&quot;/gi, '"');

                comment_message = comment_message.trim();

                if (!comment_message) {
                    return;
                }

                this.is_new = false;

                this.hideForm();

                if (Feedback.isNextGen()) {
                    Feedback.focusControls();
                }
            });

            // add to the document
            element.appendTo($(document.body));
            element.find('ubcomment').focus();
        };

        this.getData = function() {
            var screen_x = parseInt(this.x - this.scroll_l - this.offset_x, 10);
            var screen_y = parseInt(this.y - this.scroll_t - this.offset_y, 10);

            return {
                screen_x       : screen_x,
                screen_y       : screen_y,
                screen_number  : this.counter + 1,
                screenshot_num : this.screenshot_num,
                message        : comment_message
            };
        };

        this.destroy = function() {
            element.remove();
            element = undefined;
            comment_message = '';
        };
    };

    var JS_Snippet = {
        load: () => {
            var cached_widget_settings = Lib.storage.get('widget_configs_' + Config.access_token);

            if (cached_widget_settings) {
                try {
                    cached_widget_settings = JSON.parse(cached_widget_settings);
                } catch (e) {
                }

                var cache_time = 10000; // 10 seconds

                if (cached_widget_settings.data && cached_widget_settings.time && (new Date()).getTime() - cached_widget_settings.time <= cache_time) {
                    JS_Snippet.loadSuccess(cached_widget_settings.data);
                    return;
                }
            }

            $.ajax(Config.request_url + '/?jsSnippetLoad', {
                data: {
                    action:       'js_snippet/load',
                    page:         window.location.href,
                    load_type:    Config.load_type,
                    access_token: Config.access_token,
                    pathname:     window.location.pathname,
                    user_id:      User_Identification.getIdentification()
                },
                crossDomain: true,
                dataType: 'json',
                type: 'POST',
                success: (response) => {
                    // when someone refreshes a page repeatedly or navigate from one page to another very quickly
                    // use the cached widget settings
                    if (!Config.demo_mode) {
                        // Do not cahce the response in demo mode because the response can contain inactive projects
                        try {
                            Lib.storage.set('widget_configs_' + Config.access_token, JSON.stringify({
                                data: response,
                                time: (new Date()).getTime()
                            }));
                        } catch (e) {
                        }
                    }

                    JS_Snippet.loadSuccess(response);
                }
            });
        },

        loadSuccess: function(response) {
            if (!response || !response.features) {
                return;
            }

            if (Userback.on_init) {
                Userback.on_init();
            }

            // store the response for later use (refresh)
            this.response = response;

            Lib.deepExtend(Features, response.features);

            // now we know the account features, we can do a full db sync
            // note: the code can be removed when everyone gets the full db sync feature
            User_Identification.sync();

            Session_Recorder.init(response.replay);
            Feedback.init(response.widget, {
                is_live: response.is_live
            });
            Survey.init(response.survey);
        },

        refresh: function(refresh_feedback = true, refresh_survey = true) {
            if (!this.response) {
                return;
            }

            if (refresh_feedback) {
                Feedback.init(this.response.widget, {
                    is_live: this.response.is_live
                });
            }

            if (refresh_survey) {
                Survey.init(this.response.survey);
            }
        }
    };

    var callapi = function() {
        if (!Features.javascript_api) {
            console.warn('JavaScript API is not available on your plan. https://www.userback.io/pricing');
            return false;
        }

        return true;
    };

    // JS SDK
    var Userback_Api = {
        isLoaded: function() {
            return Feedback.is_loaded;
        },

        init: function(access_token, options) {
            Userback.auto_start = false;

            Feedback.removeAPIHooks();
            Config.access_token = access_token;

            if (typeof options === 'object') {
                Object.keys(options).forEach(function(key) {
                    if (Feedback.validateAPIHook(key, options)) {
                        Userback[key] = options[key];
                    }
                });

                if (options.on_init) {
                    Userback.on_init = options.on_init;
                }
            }

            Userback_Api.destroy(true, true); // destroy UI only, keep the recorders

            Config.init();

            User_Identification.init(Feedback);
            JS_Snippet.load();
        },

        start: function() {
            if (!callapi()) {
                return;
            }

            Feedback.init.call(Feedback);

            console.warn('The start() method will be deprecated soon. Please use init() instead. For more details, please see https://support.userback.io/en/articles/5209252');
        },

        refresh: function(refresh_feedback = true, refresh_survey = true) {
            // destroy existing feedback & survey

            if (refresh_feedback) {
                Feedback.destroy();
            }

            if (refresh_survey) {
                Survey.api().destroy();
            }

            // relaunch them using the current page url, segment, browser...etc
            JS_Snippet.refresh(refresh_feedback, refresh_survey);
        },

        destroy: function(keep_instance, keep_recorder) {
            Feedback.destroy();

            if (!keep_recorder) {
                Event_Recorder.stop();
                Console_Recorder.stop();
                Session_Recorder.stopRecording();
            }

            if (typeof Rage !== 'undefined') {
                Rage.stop();
            }

            if (!keep_instance) {
                $('link.userback-css').remove();
                delete window.Userback;
            }

            Survey.api().destroy();
        },

        // deprecated
        open: function(mode, direct_to) {
            this.openForm(mode, direct_to);
        },

        // Open feedback form
        openForm: function(mode, direct_to) {
            if (!Feedback.extension_domain_match) {
                Modal.modalMessage('The <b>account key</b> used is invalid. Please enter the correct key in extension option.');
                return;
            }

            if (!callapi() && Feedback.load_type !== 'chrome_extension' && Feedback.load_type !== 'firefox_extension' && Feedback.load_type !== 'edge_extension') {
                return;
            }

            if (!Feedback.isTargetingMatched()) {
                console.warn('Couldn\'t open widget. Check widget target settings.');
                return;
            }

            if (!Userback.isLoaded()) {
                console.warn('Widget code not loaded. Delay the open() call and check your project domain settings.');
                return;
            }

            var is_next_gen = Feedback.isNextGen();

            // reset retired settings
            if (is_next_gen) {
                direct_to = undefined;

                if (['general', 'bug', 'feature_request'].indexOf(mode) === -1) {
                    mode = 'general';
                }
            }

            Feedback.reset();
            Feedback.openControls();

            if (mode === 'capture' || mode === 'video') {
                Feedback.form_type = 'general';
            } else if (mode === 'general' || mode === 'form') {
                Feedback.form_type = 'general';
            } else if (mode === 'bug') {
                Feedback.form_type = 'bug';
            } else if (mode === 'feature_request') {
                Feedback.form_type = 'feature_request';
            }

            if (mode === 'capture' || direct_to === 'screenshot') {
                // old
                Feedback.attachScreenshot();
                Feedback.controls_step = 0;
            } else if (mode === 'video' || direct_to === 'video') {
                // old
                Feedback.startVideo();
                Feedback.controls_step = 0;
            } else if (mode === 'general' || mode === 'form') {
                Feedback.openForm('general');
            } else if (mode === 'bug') {
                Feedback.openForm('bug');
            } else if (mode === 'feature_request') {
                Feedback.openForm('feature_request');
                Feedback.controls_step = 0;
            } else if (mode === 'feedback') {
                Feedback.viewOtherFeedback();
                Feedback.controls_step = 0;
            }
        },

        openPortal: function() {
            if (!callapi()) {
                return;
            }

            Feedback.goToPortal();
        },

        openRoadmap: function() {
            if (!callapi()) {
                return;
            }

            Feedback.goToRoadmap();
        },

        openAnnouncement: function() {
            if (!callapi()) {
                return;
            }

            Feedback.goToAnnouncement();
        },

        close: function() {
            if (!callapi()) {
                return;
            }

            Feedback.reset();
        },

        // deprecated
        show: function() {
            this.showLauncher();
        },

        // deprecated
        hide: function() {
            this.hideLauncher();
        },

        showLauncher: function() {
            if (!callapi()) {
                return;
            }

            if (Feedback.isTargetingMatched()) {
                Feedback.ui.button.show();
                Feedback.ui.element.css('display', '');
            } else {
                console.warn('Couldn\'t show widget. Check widget target settings.');
            }
        },

        hideLauncher: function() {
            if (!callapi()) {
                return;
            }

            Feedback.reset();
            Feedback.ui.element.hide();
        },

        setEmail: function(email) {
            if (!callapi()) {
                return;
            }

            if (typeof email === 'string') {
                Feedback.email = email.trim();
            }
        },

        setName: function(name) {
            if (!callapi()) {
                return;
            }

            if (typeof name === 'string') {
                Feedback.name = name.trim();
            }
        },

        setCategories: function(categories) {
            if (!callapi()) {
                return;
            }

            if (typeof categories === 'string') {
                Feedback.categories = categories.trim();
            }
        },

        setPriority: function(priority) {
            if (!callapi()) {
                return;
            }

            if (typeof priority === 'string') {
                Feedback.priority = priority.trim().toLowerCase();
            }
        },

        startSessionReplay: function(options) {
            if (!callapi()) {
                return;
            }

            if (!Features.full_session_replay) {
                return;
            }

            if (Session_Recorder.isRecording()) {
                Session_Recorder.stopRecording();
            }

            // by default, log everything
            var block_rule, ignore_rule, mask_rules, log_level;
            var tags = [];

            if (options && typeof options.block_rule === 'string') {
                block_rule = options.block_rule;
            }

            if (options && typeof options.ignore_rule === 'string') {
                ignore_rule = options.ignore_rule;
            }

            if (options && Array.isArray(options.mask_rules)) {
                mask_rules = options.mask_rules;
            }

            if (options && Array.isArray(options.log_level)) {
                log_level = options.log_level;
            }

            if (options && Array.isArray(options.tags)) {
                tags = options.tags;
            }

            Session_Recorder.init({
                recording_auto_start: true,
                recording_rules: {
                    block  : block_rule,
                    ignore : ignore_rule,
                    mask   : mask_rules,
                    console: log_level
                },
                tags: tags
            });
        },

        stopSessionReplay: function() {
            if (!callapi()) {
                return;
            }

            Session_Recorder.stopRecording();
        },

        // add custom event to session replay
        addCustomEvent: function(event_title, event_details) {
            if (!callapi()) {
                return;
            }

            if (typeof event_title !== 'string' || (typeof event_details !== 'object' && event_details !== undefined)) {
                return;
            }

            Session_Recorder.addCustomEvent('custom_api', {
                name: event_title,
                event: event_details
            });
        },

        // identify user
        identify: function(user_id, user_info) {
            var old_user_id = User_Identification.getIdentification();

            // Note: identify() does not require JS API feature so it is intentionally not check callapi()
            User_Identification.identify(user_id, user_info, Feedback);

            var refresh_feedback = !Feedback.isLauncherOpen();
            var refresh_survey   = !Survey.api().isOpen();

            // Auto refresh widget and survey when:
            // a different user is identified, or
            // widget code is initilized without user id and then identify() is called
            // this will match the segment targeting again using the new user data
            // note: do not refresh if the widget or survey is already open
            if (old_user_id != user_id) {
                Userback.refresh(refresh_feedback, refresh_survey);
            }
        },

        // set custom data
        setData: function(data) {
            if (!callapi()) {
                return;
            }

            if (typeof data !== 'object') {
                return false;
            }

            var data_copy = {...data};

            $.each(data_copy, function(key, value) {
                if (typeof value !== 'number' && typeof value !== 'string' && typeof value !== 'boolean') {
                    delete data_copy[key];
                }
            });

            Feedback.custom_data = {...data_copy};
            Survey.api().setCustomData(data_copy);
            return data_copy;
        },

        setWidgetSettings: function(settings) {
            // deprecated
            return true;
        },

        // addHeader("EX: 123")
        addHeader: function(key, val) {
            if (!callapi()) {
                return;
            }

            var index_found = false;
            Feedback.http_header.forEach(function(header, index) {
                if (header.key == key) {
                    index_found = index;
                }
            });

            if (index_found !== false) {
                Feedback.http_header.splice(index_found, 1);
            }

            Feedback.http_header.push({
                key: key,
                value: val
            });

            return true;
        },

        openSurvey: Survey.api().openSurvey,
        closeSurvey: Survey.api().closeSurvey
    };

    // Put JS SDK in the global window object
    window.Userback = {...window.Userback, ...Userback_Api};

    // start widget code on dom ready
    $(() => {
        Config.init();


        if (window.Userback.auto_start !== false && window.Userback.access_token) {
            // init user identification module before JS_Snippet.load() so that we can send the user_id with the JS_Snippet load request
            // Note: because JS_Snippet hasn't been loaded yet, we can't do a full user db sync because we don't know account features yet
            User_Identification.init(Feedback);

            JS_Snippet.load();
        }

        window.addEventListener('message', (e) => {
            if (e.data === 'userback_reload') {
                Userback_Api.destroy(true);
                Userback_Api.init(Config.access_token);
            }

        });
    });

    // Rage Click detection
    var Rage = rage();

    // console & event recorder start immediately so that we can record as early as possible
    Console_Recorder.init();
    Event_Recorder.init();
}).call(window);
