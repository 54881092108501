import $ from 'jquery';
import Bowser2 from 'bowser';

const bowser_parser = Bowser2.getParser(window.navigator.userAgent);

const Lib = {
    length: function(obj) {
        var size = 0;

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }

        return size;
    },

    validateEmail(email) {
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    },

    htmlEntities: function(str, nl2br) {
        var text = String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');

        if (nl2br) {
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }

        return text;
    },

    htmlEntitiesWithA: function(str, nl2br) {
        var text = String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
        text = text.replace(/[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g, '<a href="$2" title="$4" target="_blank">$1</a>');

        if (nl2br) {
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }

        return text;
    },

    escapeRegExp: function(string) {
        var reRegExpChar = /[\\^$.*+?()[\]{}|]/g
        var reHasRegExpChar = RegExp(reRegExpChar.source)

        return (string && reHasRegExpChar.test(string)) ? string.replace(reRegExpChar, '\\$&') : (string || '');
    },

    getQueryString: function(key) {
        var search = window.location.search.replace(/^\?/, '').split('&');
        var query_string = {};

        $.each(search, function(index, parameter) {
            parameter = parameter.split('=');

            if (parameter.length === 2 && query_string[parameter[0]] === undefined) {
                query_string[parameter[0]] = decodeURIComponent(parameter[1]);
            }

        });

        if (key) {
            return query_string[key];
        } else {
            return query_string;
        }
    },

    b64EncodeUnicode: function(str) {
        str = Lib.replaceUnpairedSurrogates(str);

        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    },

    replaceUnpairedSurrogates: function(str) {
        return str
            .replace(/[\uD800-\uDBFF]+([^\uDC00-\uDFFF]|$)/g, '�$1')
            .replace(/(^|[^\uD800-\uDBFF])[\uDC00-\uDFFF]+/g, '$1�');
    },

    baseName: function(path) {
        var path_array = path.split('/');
        return path_array[path_array.length - 1];
    },

    fileExtension: function(path) {
        var base_name = this.baseName(path);
        var base_name_array = base_name.split('.');

        if (base_name_array.length >= 2) {
            return base_name_array[base_name_array.length - 1]
        } else {
            return false;
        }
    },

    getImageSize: function(image_src, callback, context) {
        var image = new Image();

        image.onload = function() {
            callback.call(context, image.width, image.height);
        };

        image.src = image_src;
    },

    resizeImage: function(image_data_uri, width, image_format, callback, context) {
        var canvas = document.createElement('canvas');

        if (!canvas.getContext || !canvas.getContext('2d')) {
            // canvas is not supported
            return image_data_uri;
        }

        var ctx = canvas.getContext('2d');
        var image = new Image();

        image.onload = function() {
            var image_ratio = image.width / image.height;

            // default image format to png
            image_format = image_format || 'image/png';

            canvas.width  = width;
            canvas.height = width / image_ratio;

            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            callback.call(context, canvas.toDataURL(image_format, 1));
        };

        image.src = image_data_uri;
    },

    getScrollbarSize: function() {
        var temp_element = $('<ubdiv>').css({
            position: 'absolute',
            opacity: 0,
            top: -999,
            left: -999,
            width: 100,
            height: 100,
            overflow: 'scroll'
        }).appendTo($(document.body)).get(0);

        var size = temp_element.offsetWidth  - temp_element.clientWidth;
        temp_element.remove();

        return size;
    },

    isChrome: function() {
        return bowser_parser.getBrowserName().toLowerCase() === 'chrome';
    },

    isFirefox: function() {
        return bowser_parser.getBrowserName().toLowerCase() === 'firefox';
    },

    isIE: function() {
        return bowser_parser.getBrowserName().toLowerCase() === 'internet explorer';
    },

    isSafari: function() {
        return bowser_parser.getBrowserName().toLowerCase() === 'safari';
    },

    isDesktop: function() {
        return bowser_parser.getPlatformType() === 'desktop' || bowser_parser.getOSName().toLowerCase() === 'chrome os';
    },

    isTablet: function() {
        return bowser_parser.getPlatformType() === 'tablet';
    },

    isMobile: function() {
        return bowser_parser.getPlatformType() === 'mobile';
    },

    isIos: function() {
        return bowser_parser.getOSName().toLowerCase() === 'ios';
    },

    isMacOs: function() {
        return bowser_parser.getOSName().toLowerCase() === 'macos';
    },

    isTouch: function() {
        var os_name = bowser_parser.getOSName().toLowerCase();
        var platform_name = bowser_parser.getPlatformType().toLowerCase();

        if ((os_name === 'ios' || os_name === 'android') && (platform_name === 'tablet' || platform_name === 'mobile')) {
            return true;
        } else {
            return false;
        }
    },

    setUAData: function() {
        if (typeof navigator.userAgentData === 'undefined' || typeof navigator.userAgentData.getHighEntropyValues === 'undefined') {
            return;
        }

        navigator.userAgentData.getHighEntropyValues(['platformVersion'])
        .then((ua_data) => {
            this.ua_data = ua_data;
        });
    },

    getUAData: function() {
        return this.ua_data;
    },

    uint8ArrayToBase64: function(bytes) {
        const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), "");
        return btoa(binary);
    },

    base64ToUint8Array: function(base64) {
        const binary_string = atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    },

    deepExtend: function(a, b) {
        for (let key in b) {
            if (b.hasOwnProperty(key)) {
                if (typeof b[key] === 'object' && b[key] !== null && !Array.isArray(b[key])) {
                    // If the key leads to an object, recursively deep extend it
                    if (!a.hasOwnProperty(key)) {
                        a[key] = {};
                    }
                    this.deepExtend(a[key], b[key]);
                } else {
                    // Otherwise, simply copy the value from b to a
                    a[key] = b[key];
                }
            }
        }

        return a;
    },

    storage: {
        key: 'ubwc',

        hasLocalStroage: function() {
            try {
                localStorage.setItem('test', 1);
                localStorage.removeItem('test');
                return true;
            } catch (e) {
                return false;
            }
        },

        set: function(key, value) {
            if (!Lib.storage.hasLocalStroage()) {
                return;
            }

            var items = localStorage.getItem(Lib.storage.key);

            if (items) {
                try {
                    items = JSON.parse(items);
                } catch (e) {
                    items = {};
                }
                items[key] = value;
            } else {
                items = {};
                items[key] = value;
            }

            localStorage.setItem(Lib.storage.key, JSON.stringify(items));
        },

        get: function(key) {
            if (!Lib.storage.hasLocalStroage()) {
                return null;
            }

            var items = localStorage.getItem(Lib.storage.key);

            if (items) {
                try {
                    items = JSON.parse(items);
                    return items[key] === undefined ? null : items[key];
                } catch (e) {
                }
            }

            return null;
        },

        remove: function(key) {
            if (!Lib.storage.hasLocalStroage()) {
                return;
            }

            var items = localStorage.getItem(Lib.storage.key);

            if (items) {
                try {
                    items = JSON.parse(items);
                    delete items[key];

                    localStorage.setItem(Lib.storage.key, JSON.stringify(items));
                } catch (e) {
                }
            }
        }
    }
};

Lib.setUAData();

export default Lib;