import pako from 'pako';
import Config from '../config';
import Features from 'lib/features';
import Scripts from 'lib/scripts';
import Lib from 'lib/lib';
import User_Identification from 'component/user_identification';

const Session_Recorder = {
    debug_mode: false,

    error_count: 0,

    user_identification: '',

    // session storage
    session_id: '',
    events: [],
    sequence: 0, // event sequence
    start_time: 0,

    full_session_replay: false,

    // full session replay: sync every 5 seconds
    sync_interval: 5000,

    document_size_limit: 5, // 5MB

    event_size_limit: 20, // 20MB per request

    // old session replay: max 3 mins
    time_limit: 3 * 60 * 1000, // old session replay: 3 mins

    // non recording page length
    resume_limit: 60 * 1000, // 1 min

    // inactivity length in millionseconds
    inactivity_length: 0,
    inactivity_limit: 30 * 60 * 1000, // 30 mins (stop recording after 30 mins of inactivity)

    // total length in millionseconds
    total_limit: 3 * 60 * 60 * 1000, // 3 hours (start a new session after 3 hours)

    recordFn: null,
    stopFn: null,

    is_script_loaded: false,
    is_event_bound: false,

    session_keys: {
        id         : '_ub_session_id',
        sequence   : '_ub_session_sequence',
        start_time : '_ub_session_start_time',
        events     : '_ub_session_events',
    },

    recording_rules: {
        block  : '.userback-block',
        ignore : '.userback-ignore',
        mask   : ['input[type="email"]', 'input[type="password"]'],
    },

    tags: [],
    log_level: ['log', 'warn', 'info', 'debug', 'error'],

    init_options: null,

    init_options: null,

    init: function(options) {
        if (!this.checkDocumentSize()) {
            console.warn('Session recording disabled (document size limit exceeded)');
            return;
        }

        this.debug('init: set options');

        this.init_options = options;

        if (options && typeof options.full_session_replay === 'boolean') {
            this.full_session_replay = options.full_session_replay;
        }

        if (options && options.recording_rules) {
            this.setRecordingRules(options.recording_rules);
        }

        if (options && options.recording_rules && options.recording_rules.console) {
            this.setLogLevel(options.recording_rules.console);
        }

        if (options && options.tags) {
            this.setTags(options.tags);
        }

        if (options && options.recording_auto_start) {
            if (this.full_session_replay && !Features.full_session_replay) {
                return false;
            }

            this.debug('init: auto start (' + (this.full_session_replay ? 'full' : 'non-full') + ')');

            this.bindEvents();

            this.sessionStart();

            if (this.is_script_loaded) {
                this.onScriptLoad();
            } else {
                Scripts.lazyLoad([Scripts.rrweb], this.onScriptLoad.bind(this));
            }
        }
    },

    checkDocumentSize: function() {
        var document_size = document.documentElement.outerHTML.length / 1024 / 1024;

        if (document_size > this.document_size_limit) {
            return false;
        } else {
            return true;
        }
    },

    checkEventSize: function(events) {
        var event_size = JSON.stringify(events).length / 1024 / 1024;

        if (event_size > this.event_size_limit) {
            return false;
        } else {
            return true;
        }
    },

    debug: function(message) {
        if (this.debug_mode) {
            console.debug('Session Replay:', message);
        }
    },

    onScriptLoad: function() {
        this.is_script_loaded = true;
        this.startRecording();
    },

    uid: function() {
        if (typeof window.crypto !== 'undefined' && typeof window.crypto.randomUUID === 'function') {
            return window.crypto.randomUUID();
        } else {
            return Math.random().toString(36).substring(2) +
                   Math.random().toString(36).substring(2) +
                   Math.random().toString(36).substring(2);
        }
    },

    bindEvents: function() {
        if (this.is_event_bound) {
            return;
        }

        window.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
        window.addEventListener('beforeunload',     this.onBeforeUnload.bind(this));
        window.addEventListener('offline',          this.onWindowOffline.bind(this));

        this.is_event_bound = true;
    },

    onVisibilityChange: function(e) {
        if (!this.isRecording()) {
            return;
        }

        if (document.visibilityState === 'hidden') {
            // browser tab is hidden or app is minimized on mobile
            this.addCustomEvent('browser_tab', {
                type: 'page_hidden'
            });
        } else if (document.visibilityState === 'visible') {
            this.addCustomEvent('browser_tab', {
                type: 'page_visible'
            });
        }
    },

    onBeforeUnload: function(e) {
        if (!this.isRecording()) {
            return;
        }

        // TODO:
        // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
        // sendBeacon() to tell the server that the session is closed

        Session_Recorder.addCustomEvent('navigation', {
            type: 'page_beforeunload',
            href: '' // the next url will be added after the page is loaded
        });

        var events = JSON.stringify({
            payload   : this.events,
            timestamp : (new Date()).getTime(),
        });

        var events_compressed = pako.deflate(events, {to: 'uint8array'});

        // base64 encode
        events_compressed = Lib.uint8ArrayToBase64(events_compressed);

        // data to be resumed
        try {
            window.sessionStorage.setItem(this.session_keys.sequence, this.sequence);
            window.sessionStorage.setItem(this.session_keys.events,   events_compressed);
        } catch (e) {
            // ignore the error when the session storage is full
        }
    },

    onWindowOffline: function(e) {
        this.debug('stop recording (offline)');
        this.stopRecording();
    },

    sessionStart: function() {
        this.events = [];

        var events_compressed   = window.sessionStorage.getItem(this.session_keys.events);
        var events_decompressed = '';

        if (events_compressed) {
            try {
                // convert Base64 back to Unit8Array
                events_compressed = Lib.base64ToUint8Array(events_compressed);
                // decompress
                events_decompressed = pako.inflate(events_compressed, {to: 'string'});
            } catch (e) {
                // something is wrong, ignore resume and start a new session
                events_decompressed = '';
            }
        }

        // resume from session storage
        var session_params = {
            session_id : window.sessionStorage.getItem(this.session_keys.id),
            sequence   : parseInt(window.sessionStorage.getItem(this.session_keys.sequence), 10),
            start_time : parseInt(window.sessionStorage.getItem(this.session_keys.start_time), 10),
            events     : events_decompressed
        };

        if (session_params.session_id &&
            session_params.sequence >= 0 &&
            session_params.start_time &&
            session_params.events) {

            var recordings;

            try {
                recordings = JSON.parse(session_params.events);
            } catch (e) {
                // can't json decode, ignore resume and start a new session
            }

            var now = (new Date()).getTime();

            if (recordings.payload && recordings.timestamp &&
                (now - recordings.timestamp) > 0 &&
                // do not resume when the data is too old
                (now - recordings.timestamp) < this.resume_limit) {

                this.debug('session resume');

                // resume from sessino storage
                this.session_id = session_params.session_id;
                this.sequence   = session_params.sequence;
                this.start_time = session_params.start_time;
                this.events     = recordings.payload;

                // backfill the href for the last page_beforeunload event
                this.events.forEach((event) => {
                    if (event.data && event.data.data && event.data.data.payload && event.data.data.payload.type === 'page_beforeunload') {
                        if (!event.data.data.payload.href) {
                            event.data.data.payload.href = window.location.href;
                        }
                    }
                });

                // events are not needed in the session storage after resuming
                window.sessionStorage.removeItem(this.session_keys.events);

                // make a sync call immediately so that the unsaved events
                // from the last page are saved before this page is closed
                this.sync();
                return;
            } else {
                this.debug('session resume discarded');
            }
        }

        // start a new session when there is nothing to resume from in the session storage
        this.debug('session new');

        this.session_id = this.uid();
        this.sequence   = 0;
        this.start_time = (new Date()).getTime();
        this.events     = [];

        try {
            window.sessionStorage.setItem(this.session_keys.id,         this.session_id);
            window.sessionStorage.setItem(this.session_keys.sequence,   this.sequence);
            window.sessionStorage.setItem(this.session_keys.start_time, this.start_time);
            window.sessionStorage.removeItem(this.session_keys.events);
        } catch (e) {
            // ignore the error when the session storage is full
        }
    },

    // clear session storage
    sessionDestroy: function() {
        Object.keys(this.session_keys).forEach((key) => {
            window.sessionStorage.removeItem(key);
        });
    },

    startRecording: function() {
        if (!this.is_script_loaded) {
            return false;
        }

        this.recordFn = typeof rrweb !== 'undefined' ? rrweb.record : rrwebRecord;

        if (this.full_session_replay) {
            // full session recording
            this.timer = setTimeout(this.sync.bind(this), this.sync_interval);
        } else {
            // old session recording
            this.timer = setInterval(this.checkoutEvent, this.time_limit);
        }

        this.stopFn = this.recordFn({
            recordLog: true,
            inlineStylesheet: this.full_session_replay ? false : true, // TODO: premium feature
            checkoutEveryNms: this.full_session_replay ? 0 : this.time_limit,
            collectFonts: false,
            blockClass: this.recording_rules.block.replace(/^./, ''),
            ignoreClass: this.recording_rules.ignore.replace(/^./, ''),
            maskInputOptions: {
                'email':          this.recording_rules.mask.indexOf('input[type="email"]') !== -1,
                'password':       this.recording_rules.mask.indexOf('input[type="password"]') !== -1,
                'color':          this.recording_rules.mask.indexOf('input[type="color"]') !== -1,
                'date':           this.recording_rules.mask.indexOf('input[type="date"]') !== -1,
                'datetime-local': this.recording_rules.mask.indexOf('input[type="datetime-local"]') !== -1,
                'month':          this.recording_rules.mask.indexOf('input[type="month"]') !== -1,
                'number':         this.recording_rules.mask.indexOf('input[type="number"]') !== -1,
                'range':          this.recording_rules.mask.indexOf('input[type="range"]') !== -1,
                'search':         this.recording_rules.mask.indexOf('input[type="search"]') !== -1,
                'tel':            this.recording_rules.mask.indexOf('input[type="tel"]') !== -1,
                'text':           this.recording_rules.mask.indexOf('input[type="text"]') !== -1,
                'time':           this.recording_rules.mask.indexOf('input[type="time"]') !== -1,
                'url':            this.recording_rules.mask.indexOf('input[type="url"]') !== -1,
                'week':           this.recording_rules.mask.indexOf('input[type="week"]') !== -1,
                'textarea':       this.recording_rules.mask.indexOf('textarea') !== -1,
                'select':         this.recording_rules.mask.indexOf('select') !== -1
            },
            emit: (event, is_checkout) => {
                this.events.push({
                    is_checkout: is_checkout,
                    data: event
                });

                if (is_checkout && !this.full_session_replay) {
                    // old session replay: keep the last 2 checkout events
                    var checkout_count = 0;
                    var checkout_position = 0;
                    [...this.events].reverse().forEach((event, index) => {
                        if (event.is_checkout) {
                            checkout_count++;
                        }

                        if (!checkout_position && checkout_count === 2) {
                            checkout_position = index;
                        }
                    });

                    if (checkout_position) {
                        this.events = this.events.slice(this.events.length - checkout_position - 1);
                    }
                }
            },
            plugins: this.log_level.length ? [rrwebConsoleRecord.getRecordConsolePlugin({
                logger: window.console,
                level: this.log_level,
                lengthThreshold: 100, // 100 log items
                stringifyOptions: {
                    stringLengthLimit: 1000,
                    numOfKeysLimit: 100,
                    depthOfLimit: 20
                }
            })] : undefined
        });

        return true;
    },

    stopRecording: function() {
        // reset params
        this.start_time = 0;
        this.inactivity_length = 0;
        this.events = [];
        this.sequence = 0;

        // stop recording
        if (this.stopFn) {
            this.stopFn();
            this.stopFn = null;
        }

        // stop timer
        if (this.full_session_replay) {
            clearTimeout(this.timer);
        } else {
            clearInterval(this.timer);
        }

        // destroy session
        this.sessionDestroy();

        this.debug('stop');

        return true;
    },

    isRecording: function() {
        return this.start_time > 0 ? true : false;
    },

    setRecordingRules: function(rules) {
        var block_rule, ignore_rule, mask_rules;

        if (typeof rules.block === 'string') {
            block_rule = rules.block;
        } else if (Array.isArray(rules.block)) {
            block_rule = rules.block[0];
        }

        if (typeof rules.ignore === 'string') {
            ignore_rule = rules.ignore;
        } else if (Array.isArray(rules.ignore)) {
            ignore_rule = rules.ignore[0];
        }

        if (Array.isArray(rules.mask)) {
            mask_rules = [...rules.mask];
        }

        this.recording_rules = {
            block  : block_rule  || this.recording_rules.block,
            ignore : ignore_rule || this.recording_rules.ignore,
            mask   : mask_rules  || this.recording_rules.mask
        };
    },

    setLogLevel: function(log_level) {
        this.log_level = Array.isArray(log_level) ? log_level : this.log_level;
        this.log_level = this.log_level.filter((log_type) => {
            return ['log', 'warn', 'info', 'debug', 'error'].includes(log_type);
        });
    },

    setTags: function(tags) {
        if (Array.isArray(tags)) {
            if (tags.length > 5) {
                console.warn('Maximum 5 tags are allowed');
            } else {
                this.tags = tags;
            }
        }
    },

    addCustomEvent: function(event_type, payload) {
        if (!this.recordFn) {
            return;
        }

        if (!this.isRecording()) {
            return;
        }

        this.recordFn.addCustomEvent(event_type, payload);
    },

    // dispatch a mousemove event to trigger the checkout
    checkoutEvent: function() {
        try {
            var event = new MouseEvent('mousemove');
            document.body.dispatchEvent(event);
        } catch(e) {
        }
    },

    // periodically sync events with the server side
    sync: async function() {
        if (!this.full_session_replay) {
            return;
        }

        clearTimeout(this.timer);

        var restart = false;
        var now     = (new Date()).getTime();

        // mark events as pending for send
        var events = this.events.map((event) => {
            event.pending = true;
            return event.data;
        });

        // inactivity
        if (!events.length) {
            this.inactivity_length += this.sync_interval;

            if (this.inactivity_length >= this.inactivity_limit) {
                restart = true;
            }
        } else {
            this.inactivity_length = 0;
        }

        if ((now - this.start_time) >= this.total_limit) {
            restart = true;
        }

        if (restart && !this.pending_restart) {
            this.debug('sync: pending restart');
            this.pending_restart = true;
        }

        // 10 consecutive errors, stop recording
        if (this.error_count > 10) {
            this.stopRecording();
            return;
        }

        if (!this.checkEventSize(events)) {
            console.warn('Session recording disabled (event size limit exceeded)');
            this.stopRecording();
            return;
        }

        // don't send the inactive reload events
        // it will result in a 00:00 session replay
        var inactive_reload = false;
        if (events.length === 2 && events[0].type === 4 && events[1].type === 2) {
            inactive_reload = true;
        }

        // User id hasn't returned from the server yet
        // or, events is empty (user has not interacted with the page)
        if (events.length && !inactive_reload && !User_Identification.sync_pending) {
            if (this.pending_restart) {
                this.debug('sync: restart');

                this.pending_restart = false;

                this.stopRecording();
                this.init(this.init_options);
            }

            this.debug('sync: ' + this.sequence);

            var user_identification = User_Identification.getIdentification();
            var user_identification_changed = user_identification !== this.user_identification;
            this.user_identification = user_identification;

            var response = await fetch(Config.track_url + '/events', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    access_token        : Config.access_token,
                    user_agent          : this.sequence === 0 ? navigator.userAgent : undefined,
                    ua_data             : this.sequence === 0 ? Lib.getUAData() : undefined,
                    user_identification : user_identification_changed ? this.user_identification : undefined,
                    tags                : this.sequence === 0 ? this.tags : undefined,
                    session_id          : this.session_id,
                    sequence            : this.sequence,
                    events              : events
                })
            });

            response = await response.json();

            if (response.success) {
                this.debug('sync: success');

                // delete the events that have been sent
                this.events = this.events.filter((event) => {
                    return event.pending !== true;
                });

                this.sequence++; // TODO: what if page unloads before response is resolved?

                this.error_count = 0;
            } else {
                this.debug('sync: error');

                this.events.forEach((event) => {
                    delete event.pending;
                });

                this.error_count++;
            }
        } else {
            this.debug('sync: skip empty events');
        }

        this.timer = setTimeout(this.sync.bind(this), this.sync_interval);
    },

    getSessionId: function() {
        return this.session_id;
    },

    getAll: function() {
        return this.events.map((event) => {
            return event.data;
        });
    }
};

export default Session_Recorder;